import clsx from 'clsx'
import type { FormEventHandler, FunctionComponent } from 'react'
import style from './Card.module.sass'

export type CardProps = {
	children?: React.ReactNode
	size?: 'normal' | 'small' | 'full'
	topLeft?: React.ReactNode
	topRight?: React.ReactNode
	bottomRight?: React.ReactNode
	bottomLeft?: React.ReactNode
	headerStart?: React.ReactNode
	headerEnd?: React.ReactNode
	footerStart?: React.ReactNode
	footerEnd?: React.ReactNode
	unwrapped?: boolean
	table?: React.ReactNode
	disabled?: boolean
	onSubmit?: FormEventHandler<HTMLFormElement>
}

export const Card: FunctionComponent<CardProps> = props => {
	const content = (
		<div
			className={clsx(
				style.wrapper,
				props.unwrapped && style.view_unwrapped,
				props.size && style[`view_size_${props.size}`],
				(props.topLeft || props.topRight) && style.view_hasTop,
				(props.bottomLeft || props.bottomRight) && style.view_hasBottom,
			)}
		>
			<div className={style.card}>
				<div className={style.parts}>
					{!!(props.headerStart || props.headerEnd) && (
						<div className={style.header}>
							{props.headerStart && <div className={style.headerStart}>{props.headerStart}</div>}
							{props.headerEnd && <div className={style.headerEnd}>{props.headerEnd}</div>}
						</div>
					)}
					{props.table && <div className={style.table}>{props.table}</div>}
					{props.children && <div className={style.main}>{props.children}</div>}
					{!!(props.footerStart || props.footerEnd) && (
						<div className={style.footer}>
							{props.footerStart && <div className={style.footerStart}>{props.footerStart}</div>}
							{props.footerEnd && <div className={style.footerEnd}>{props.footerEnd}</div>}
						</div>
					)}
				</div>
				{props.topLeft && (
					<div className={style.topLeft}>
						<span className={style.topLeftContent}>{props.topLeft}</span>
					</div>
				)}
				{props.topRight && (
					<div className={style.topRight}>
						<span className={style.topRightContent}>{props.topRight}</span>
					</div>
				)}
				{props.bottomLeft && (
					<div className={style.bottomLeft}>
						<span className={style.bottomLeftContent}>{props.bottomLeft}</span>
					</div>
				)}
				{props.bottomRight && (
					<div className={style.bottomRight}>
						<span className={style.bottomRightContent}>{props.bottomRight}</span>
					</div>
				)}
			</div>
		</div>
	)

	if (props.onSubmit || typeof props.disabled === 'boolean') {
		return (
			<form onSubmit={props.onSubmit} className={style.form}>
				<fieldset disabled={props.disabled} className={style.fieldset}>
					{content}
				</fieldset>
			</form>
		)
	}

	return content
}
