import { useQuery } from '@tanstack/react-query'
import { useGoodlokAuth } from '../auth'

export function useStaffInfo() {
	const g = useGoodlokAuth()

	const result = useQuery(
		['staffInfo', g.staffId as string] as const,
		async q => {
			const id = q.queryKey[1]
			return g.zeus
				.roleStaff('query')({
					getStaff: [
						{ by: { id } },
						{
							id: true,
						},
					],
				})
				.then(data => data.getStaff)
		},
		{ enabled: Boolean(g.staffId) },
	)

	return result
}
