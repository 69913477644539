export const getStage = () => {
	const stage = process.env.NEXT_PUBLIC_STAGE

	if (stage === 'beta') {
		return 'beta'
	}
	if (stage === 'prod') {
		return 'production'
	}

	return 'development'
}
