import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import styles from './Badge.module.sass'

export type BadgeProps = {
	count?: ReactNode
	variant?: 'plain' | 'primary' | 'danger'
	parentShape?: 'unspecified' | 'rectangular' | 'circular' // @TODO: get rid of unspecified shape - it is here for legacy reasons only
	inverted?: boolean
}

export const Badge: FunctionComponent<BadgeProps> = ({
	count,
	variant = 'plain',
	parentShape = 'unspecified',
	inverted,
}) => {
	return (
		<span
			className={clsx(
				styles.wrapper,
				styles[`is_variant_${variant}`],
				styles[`is_parentShape_${parentShape}`],
				inverted && styles.is_inverted,
			)}
		>
			{count}
		</span>
	)
}
