import clsx from 'clsx'
import React from 'react'
import style from './LoadingSpinner.module.sass'

export type LoadingSpinnerProps = {
	visible?: boolean
}

export const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = props => {
	return (
		<div className={clsx(style.wrapper, (props.visible ?? true) && style.visible)}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	)
}
