import type { FunctionComponent } from 'react'
import { AppSwitcherIcon, AppSwitcherIconProps } from './AppSwitcherIcon'
import styles from './AppSwitcherIconList.module.sass'

export type AppSwitcherIconListProps = {
	items: AppSwitcherIconProps[]
}

export const AppSwitcherIconList: FunctionComponent<AppSwitcherIconListProps> = ({ items }) => {
	return (
		<div className={styles.list}>
			{items.map((icon, i) => (
				<div key={i} className={styles.item}>
					<AppSwitcherIcon {...icon} />
				</div>
			))}
		</div>
	)
}
