import clsx from 'clsx'
import { FunctionComponent, useMemo, useState } from 'react'
import { Dialog, I18nProvider } from 'react-aria-components'
import { Icon } from '../icons/Icon'
import { Button } from './Button'
import { DatePicker, DatePickerProps, DateRangeValue } from './DatePicker'
import styles from './DatePickerInput.module.sass'
import { DropdownWrapper } from './DropdownWrapper'

const format = (date: Date) => {
	return Intl.DateTimeFormat('cs', { day: '2-digit', month: '2-digit' }).format(date)
}

export const DatePickerInput: FunctionComponent<DatePickerProps> = props => {
	const selectedDate =
		props.mode === 'single' ? (
			props.value ? (
				<div>{format(props.value)}</div>
			) : (
				'Vyberte datum'
			)
		) : props.mode === 'range' ? (
			props.value?.start && props.value.end ? (
				<>
					<div>{format(props.value.start)}</div>-<div>{format(props.value.end)}</div>
				</>
			) : null
		) : null

	const [rangeValue, setRangeValue] = useState<DateRangeValue | null>(props.mode === 'range' ? props.value : null)
	const [singleValue, setSingleValue] = useState<Date | null>(props.mode === 'single' ? props.value : null)

	const datePickerHead = useMemo(() => {
		if (props.mode === 'range') {
			return (
				<div className={clsx(styles.head, styles.rangeHead)}>
					<div>{rangeValue?.start && format(rangeValue.start)}</div>
					<div>{rangeValue?.end && format(rangeValue.end)}</div>
				</div>
			)
		} else if (props.mode === 'single') {
			return (
				<div className={clsx(styles.head, styles.singleHead)}>
					<div>{singleValue && format(singleValue)}</div>
				</div>
			)
		} else {
			return 'Vyberte datum'
		}
	}, [props.mode, rangeValue?.end, rangeValue?.start, singleValue])

	const [isOpen, setIsOpen] = useState(false)

	return (
		<I18nProvider locale="cs-CZ">
			<DropdownWrapper
				button={
					<div className={styles.editButton}>
						{selectedDate}
						<div className={styles.calendarIcon}>
							<Icon name="calendar" />
						</div>
					</div>
				}
				isOpen={isOpen}
				onRequestClose={() => {
					if (props.mode === 'range') {
						setRangeValue(props.value)
					} else {
						setSingleValue(props.value)
					}
				}}
				setIsOpen={setIsOpen}
				mobilePlacement="top"
			>
				<Dialog className={styles.wrapper}>
					<div className={styles.content}>
						<div className={styles.head}>{datePickerHead}</div>
						{props.mode === 'range' ? (
							<DatePicker {...props} onChange={setRangeValue} value={rangeValue} />
						) : (
							<DatePicker {...props} onChange={setSingleValue} value={singleValue} />
						)}
						<div className={styles.buttons}>
							<Button
								type="button"
								variant="seamless"
								onClick={() => {
									if (props.mode === 'range') {
										setRangeValue(null)
									} else {
										setSingleValue(null)
									}
									props.onChange(null)
									setIsOpen(false)
								}}
								size="small"
							>
								Zrušit výběr
							</Button>
							<Button
								type="button"
								variant="primary"
								onClick={() => {
									setIsOpen(false)
									if (props.mode === 'range') {
										props.onChange(rangeValue)
									} else {
										props.onChange(singleValue)
									}
								}}
								size="small"
							>
								Potvrdit
							</Button>
						</div>
					</div>
				</Dialog>
			</DropdownWrapper>
		</I18nProvider>
	)
}
