import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useEffect, type FunctionComponent } from 'react'
import {
	SharedLoadingIndicatorContextProvider as SharedLoadingIndicatorContextProviderExternal,
	useLocalLoading,
	useSharedLoading,
} from 'shared-loading-indicator'
import { LoadingBar } from './LoadingBar'
import styles from './SharedLoadingIndicator.module.sass'

export { Loading, LoadingSuspense, useLocalLoading, useMirrorLoading } from 'shared-loading-indicator'

export const SharedLoadingIndicator: FunctionComponent<{ nested?: boolean }> = ({ nested = false }) => {
	const isLoading = useSharedLoading()

	return (
		<div className={clsx(styles.wrapper, isLoading && styles.is_loading, nested && styles.is_nested)}>
			<div className={styles.in}>
				<LoadingBar />
			</div>
		</div>
	)
}

export const SharedLoadingIndicatorContextProvider: FunctionComponent<
	PropsWithChildren<{
		ignorePageNavigation?: boolean
	}>
> = ({ children, ignorePageNavigation = false }) => {
	return (
		<SharedLoadingIndicatorContextProviderExternal>
			{!ignorePageNavigation && <PageNavigationLoadingTracker />}
			{children}
		</SharedLoadingIndicatorContextProviderExternal>
	)
}

const PageNavigationLoadingTracker = () => {
	const router = useRouter()
	const [_, setIsLoading] = useLocalLoading()

	useEffect(() => {
		const handleStart = (url: string) => {
			url !== router.pathname ? setIsLoading(true) : setIsLoading(false)
		}
		const handleComplete = () => setIsLoading(false)
		router.events.on('routeChangeStart', handleStart)
		router.events.on('routeChangeComplete', handleComplete)
		router.events.on('routeChangeError', handleComplete)
		return () => {
			router.events.off('routeChangeStart', handleStart)
			router.events.off('routeChangeComplete', handleComplete)
			router.events.off('routeChangeError', handleComplete)
		}
	}, [router, setIsLoading])

	return null
}
