import { useQuery } from '@tanstack/react-query'
import { useGoodlokAuth, useGoodlokShopping } from '..'

export function useSoonestDelivery() {
	const c = useGoodlokShopping()
	const g = useGoodlokAuth()

	return useQuery([c.customerId, c.channel], async () => {
		if (c.customerId) {
			const data = await g.zeus.orders('query')({
				getInfo: [
					{ customerId: c.customerId, channel: c.channel },
					{
						soonestDeliveryOption: {
							timelineDescription: true,
							priceCents: true,
							address: {
								name: true,
								city: true,
								raw: true,
							},
							delivery: {
								method: [
									{},
									{
										name: true,
									},
								],
							},
						},
					},
				],
			})

			if (data.getInfo?.soonestDeliveryOption) {
				const {
					timelineDescription,
					priceCents,
					address,
					delivery: { method },
				} = data.getInfo.soonestDeliveryOption

				return {
					timeDescription: timelineDescription,
					priceCents,
					deliveryMethodName: method?.name,
					addressName: address?.name,
					addressRaw: address?.raw,
					addressCity: address?.city,
				}
			}
		}
	})
}
