import { workspaceName } from '@goodlok/meta/workspaceName'
import dynamic from 'next/dynamic'
import type { ComponentType, FunctionComponent } from 'react'
import styles from './Logo.module.sass'

// @TODO: investigate if noSsr is needed
const dynamicLogos = {
	goodlok: dynamic(() => import('./goodlok/Logo').then(module => module.Logo)),
	doubleshot: dynamic(() => import('./doubleshot/Logo').then(module => module.Logo)),
	sliceslicebaby: dynamic(() => import('./sliceslicebaby/Logo').then(module => module.Logo)),
	kilomayo: dynamic(() => import('./kilomayo/Logo').then(module => module.Logo)),
} satisfies {
	[key in typeof workspaceName]: ComponentType<LogoProps>
}

export type LogoProps = {
	variant: 'small' | 'large'
}

export const Logo: FunctionComponent<LogoProps> = props => {
	const DynamicLogo = dynamicLogos[workspaceName]

	return (
		<div className={styles.wrapper}>
			<DynamicLogo {...props} />
		</div>
	)
}
