import { FunctionComponent, ReactNode, useMemo, useState } from 'react'
import { BoxTitle } from './BoxTitle'
import { ScrollBox } from './ScrollBox'
import { SearchBar } from './SearchBar'
import styles from './SingleChoiceSelectEdit.module.sass'
import { SingleChoiceSelectOption } from './SingleChoiceSelectOption'

export type RadioSelectOption = {
	id: string
	label: string
}

export type CommonSingleChoiceSelectProps = {
	title?: string
	description?: string
	placeholder?: string
	options: RadioSelectOption[]
	defaultValue?: string
	value?: string | null
	onChange?: (value: string | null) => void
	topRightSlot?: ReactNode
	name: string
	errorMessage?: string
	uncheckable?: boolean
	disableGutters?: boolean
	hideBorder?: boolean
	onRequestClose: () => void
	onRequestOpen: () => void
	onChoiceSelect: () => void
	isEdited?: boolean
}

export type SingleChoiceSelectEditProps = CommonSingleChoiceSelectProps & {
	setIsEdited: () => void
	onChange: (option: string | null) => void
	value: string | undefined | null
	button?: React.ReactNode
	name: string
	searchBarPlaceholder?: string
}

export const SingleChoiceSelectEdit: FunctionComponent<SingleChoiceSelectEditProps> = ({
	title,
	options,
	value,
	onChange,
	topRightSlot,
	name,
	uncheckable = true,
	searchBarPlaceholder,
}) => {
	const [searchValue, setSearchValue] = useState<string | undefined>(undefined)

	const filteredOptions = useMemo(() => {
		if (!searchValue) return options
		return options.filter(option => option.label.toLowerCase().includes(searchValue.toLowerCase()))
	}, [options, searchValue])

	return (
		<div className={styles.wrapper}>
			{title && <BoxTitle title={title} button={topRightSlot} />}
			<div className={styles.searchBar}>
				<SearchBar label={searchBarPlaceholder} onChange={setSearchValue} value={searchValue} />
			</div>
			<div className={styles.content}>
				<ScrollBox>
					<div className={styles.options}>
						{filteredOptions.map(option => {
							const isChecked = value === option.id
							return (
								<SingleChoiceSelectOption
									type="radio"
									key={option.id}
									isChecked={isChecked}
									option={option}
									onChange={() => (isChecked ? onChange(null) : onChange(option.id))}
									value={option.id}
									name={name}
									uncheckable={uncheckable}
								/>
							)
						})}
					</div>
				</ScrollBox>
			</div>
		</div>
	)
}
