import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import type { FunctionComponent } from 'react'
import { useEffect, useRef } from 'react'
import styles from './EditBox.module.sass'
import { InputError } from './InputError'

export type EditBoxProps = {
	content: React.ReactNode
	editedContent?: React.ReactNode
	open?: boolean
	onRequestClose?: () => void
	onRequestOpen?: () => void
	name: string
	errorMessage?: string
	hasError?: boolean
	disableGutters?: boolean
	hideBorder?: boolean
}

export const EditBox: FunctionComponent<EditBoxProps> = ({
	content,
	editedContent,
	open = false,
	onRequestClose,
	onRequestOpen,
	name,
	errorMessage,
	hasError,
	disableGutters = false,
	hideBorder,
}) => {
	const editedContentRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!open || !editedContentRef.current) {
			return
		}
		const input = editedContentRef.current.querySelector('input')
		if (input) {
			// waiting for collapsible animation
			setTimeout(() => {
				input.focus()
			}, 200)
		}
	}, [open])

	return (
		<>
			{open && <div className={styles.overlay} onClick={onRequestClose} />}
			<div
				className={clsx(
					styles.wrapper,
					hasError && styles.is_error,
					open && styles.is_edited,
					disableGutters && styles.disable_gutters,
					hideBorder && styles.hide_border,
				)}
				onClick={!open ? onRequestOpen : undefined}
			>
				<Collapsible open={!open}>
					<div className={styles.contentIn}>
						{content}
						{hasError && <InputError name={name} errorMessage={errorMessage} />}
					</div>
				</Collapsible>

				<div className={styles.editedContent} ref={editedContentRef}>
					<Collapsible open={open}>
						<div className={styles.editedContentIn}>{editedContent}</div>
					</Collapsible>
				</div>
			</div>
		</>
	)
}
