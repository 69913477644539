import { z } from 'zod'

export const SessionData = z.object({
	nonce: z.string(),
	state: z.string(),
})

export const OnSuccessAuthAction = z.object({
	type: z.literal('cloneAndCheckoutAnonymousCart'),
	cloneAndCheckoutAnonymousCartId: z.string().uuid(),
})

export type OnSuccessAuthAction = z.infer<typeof OnSuccessAuthAction>

export const StoredSession = z.object({
	redirectUrl: z.string(),
	identityProvider: z.string(),
	authUrl: z.string(),
	sessionData: SessionData,
	successUrl: z.string().optional().nullable(),
	onSuccessAction: OnSuccessAuthAction.optional().nullable(),
})

export type StoredSession = z.infer<typeof StoredSession>

export function storeIDPSession(session: StoredSession) {
	localStorage.setItem(`IDPSession:${session.sessionData.state}`, JSON.stringify(session))
	return session.sessionData.state
}

export function restoreIDPsession(state: string, clear = true) {
	const raw = localStorage.getItem(`IDPSession:${state}`)

	if (clear) {
		localStorage.removeItem(`IDPSession:${state}`)
	}

	if (raw) {
		const parsed = StoredSession.safeParse(JSON.parse(raw))

		if (parsed.success) {
			return parsed.data
		}
	}

	return null
}
