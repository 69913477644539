import type { FunctionComponent } from 'react'
import { IconName } from '../icons/Icon'
import { ImageSelect, ImageSelectOption, ImageSelectProps } from './ImageSelect'

type ImageFilterWrapperProps = {
	name: ImageSelectProps['name']
	options: ImageSelectOption[]
	generalOption: ImageSelectOption
	selectedOptions: Array<string>
	setSelectedOptions: (values: string[]) => void
}

const GENERAL_OPTION_VALUE = 'all'
export const generalOption = (label?: string, icon?: IconName) => {
	return {
		label: label || 'Všechny',
		value: GENERAL_OPTION_VALUE,
		icon: icon,
	}
}

export const ImageFilterWrapper: FunctionComponent<ImageFilterWrapperProps> = ({
	name,
	options,
	generalOption,
	selectedOptions,
	setSelectedOptions,
}) => {
	return (
		<ImageSelect
			menuAlignment="left"
			name={name}
			isMulti
			options={[generalOption, ...options]}
			onChange={(options, actionMeta) => {
				if (actionMeta.action === 'select-option' && actionMeta.option?.value === generalOption.value) {
					setSelectedOptions([])
				} else if (options && options.length > 0) {
					setSelectedOptions(options.filter(option => option.value !== generalOption.value).map(option => option.value))
				} else {
					setSelectedOptions([])
				}
			}}
			closeMenuOnSelect={false}
			value={selectedOptions.length > 0 ? selectedOptions : [generalOption.value]}
		/>
	)
}
