import { GoodlokAuthRoot, GoodlokShoppingRoot } from '@goodlok/react-sdk'
import '@goodlok/react-sdk/src/theme.sass'
import { Notifications, SharedLoadingIndicatorContextProvider } from '@goodlok/ui'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Analytics } from '@vercel/analytics/react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { RecoilRoot } from 'recoil'
import '../app/styles/globals.sass'

const queryClient = new QueryClient()

let stripePromise: null | ReturnType<typeof loadStripe> = null

export const getStripePromise = () => {
	if (typeof window === 'undefined') {
		return null
	}
	stripePromise =
		stripePromise ??
		loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string).catch(() => null)
	return stripePromise
}

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<>
			<QueryClientProvider client={queryClient}>
				<SharedLoadingIndicatorContextProvider>
					<GoodlokAuthRoot
						options={{ backendUrl: process.env.BACKEND_URL as string, context: 'b2b' }}>
						<GoodlokShoppingRoot channel="b2b">
							<RecoilRoot>
								<Elements stripe={getStripePromise()}>
									<Notifications />
									<Head>
										<title>Goodlok B2B</title>
									</Head>
									<Component {...pageProps} />
								</Elements>
							</RecoilRoot>
						</GoodlokShoppingRoot>
					</GoodlokAuthRoot>
				</SharedLoadingIndicatorContextProvider>
			</QueryClientProvider>
			<Analytics />
		</>
	)
}
export default MyApp
