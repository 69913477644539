import React, { FunctionComponent } from 'react'
import { Slide, toast, ToastContainer, ToastPosition } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { assertNever } from '../utilities/assertNever'

export const notify = (message: string, type: 'info' | 'success' | 'warning' | 'error' = 'info') => {
	if (type === 'info') {
		toast.info(message)
	} else if (type === 'success') {
		toast.success(message)
	} else if (type === 'warning') {
		toast.warning(message)
	} else if (type === 'error') {
		toast.error(message)
	} else {
		assertNever(type)
	}
}

export type NotificationsProps = {
	position?: ToastPosition
}

export const Notifications: FunctionComponent<NotificationsProps> = ({ position = 'bottom-right' }) => (
	<ToastContainer position={position} transition={Slide} />
)
