import clsx from 'clsx'
import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import { ImageResult } from '../fragments/ImageFragment'
import { Icon } from '../icons/Icon'
import styles from './PeopleListStaffPhoto.module.sass'

type PeopleListStaffPhotoProps = {
	photo: ImageResult | null
	isSelected?: boolean
}

export const PeopleListStaffPhoto: FunctionComponent<PeopleListStaffPhotoProps> = ({ photo, isSelected }) => {
	return (
		<div className={clsx(styles.image, isSelected && styles.is_selected)}>
			{photo ? (
				<Image src={photo.url} alt={photo.alt} layout="fill" objectFit="cover" />
			) : (
				<div className={styles.icon}>
					<Icon name="friendsOff" />
				</div>
			)}
			<div className={styles.imageOverlay}>
				<div className={styles.check} />
			</div>
		</div>
	)
}
