/*
	⚠️⚠️⚠️ DO NOT EDIT! ⚠️⚠️⚠️
	COMPONENT HAS BEEN MODIFIED IN NEW MONOREPO AND CHANGES HERE WILL BE LOST
*/

import React, { FunctionComponent, useMemo } from 'react'
import styles from './FormattedCents.module.sass'

export type FormattedCentsProps = {
	cents: number
	preferDecimals?: boolean
	currency?: string
	currencySign?: React.ReactNode
}

const locale = 'cs-CZ'

export const creditsCurrencySign = <span className={styles.creditsSign}>G</span>

export const FormattedCents: FunctionComponent<FormattedCentsProps> = props => {
	const { cents, preferDecimals, currencySign } = props
	const currency = props.currency ?? 'CZK'

	const showDecimals = preferDecimals || cents % 100 !== 0

	const formatter = useMemo(() => {
		return Intl.NumberFormat(locale, {
			style: 'currency',
			currency,
			maximumFractionDigits: 2,
			minimumFractionDigits: showDecimals ? 2 : 0,
		})
	}, [currency, showDecimals])

	const parts = useMemo(() => {
		return formatter.formatToParts(cents / 100).map((part, i) => {
			const value = part.type === 'currency' && currencySign ? currencySign : part.value
			return (
				<span key={i} className={styles[`type_${part.type}`]}>
					{value}
				</span>
			)
		})
	}, [cents, currencySign, formatter])

	return (
		<span className={styles.wrapper}>
			<span className={styles.amount}>{parts}</span>
		</span>
	)
}

export const FormattedCreditCents: FunctionComponent<FormattedCentsProps> = props => {
	return <FormattedCents currencySign={creditsCurrencySign} {...props} />
}
