import { FragmentResult, ValueTypes, ZeusSelect } from '@goodlok/sdk/generated/content_role_public'
import { TranslationValueFragment } from './TranslationValueFragment'

export function TranslationCatalogueFragment() {
	return ZeusSelect<ValueTypes['TranslationCatalogue']>()({
		values: [{}, TranslationValueFragment()],
		fallback: [
			{},
			{
				values: [{}, TranslationValueFragment()],
			},
		],
	})
}

export type TranslationCatalogueResult = FragmentResult<'TranslationCatalogue', typeof TranslationCatalogueFragment>
