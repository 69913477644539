import { useCallback, useEffect, useState } from 'react'
import { GoodlokPreferences } from './GoodlokPreferences'

export function useGoodlokPreferences() {
	const [preferences, setPreferencesState] = useState<null | GoodlokPreferences>(null)

	const setPreferences = useCallback((value: null | GoodlokPreferences) => {
		if (value) {
			const parsed = GoodlokPreferences.parse(value)
			document.cookie = `GOODLOK_PREFERENCE=${encodeURIComponent(JSON.stringify(parsed))}`
			return setPreferencesState(parsed)
		}
		return setPreferencesState(null)
	}, [])

	useEffect(() => {
		const cookieValue = Object.fromEntries(
			document.cookie.split(';').map(pair => pair.trim().split('=') as [string, string]),
		)
		try {
			const p = JSON.parse(decodeURIComponent(cookieValue['GOODLOK_PREFERENCE'] ?? 'null'))
			setPreferencesState(GoodlokPreferences.parse(p))
		} catch (e) {}
	}, [])

	return [preferences, setPreferences] as const
}

export function useGoodlokPreferenceLevel() {
	const [preferences] = useGoodlokPreferences()
	return preferences?.level ?? 0
}

export function useGoodlokPreferenceLevelGt(value: number) {
	const [preferences] = useGoodlokPreferences()
	return (preferences?.level ?? 0) > value
}

export function useGoodlokPreferenceLevelGte(value: number) {
	const [preferences] = useGoodlokPreferences()
	return (preferences?.level ?? 0) >= value
}
