import { Input } from '@goodlok/ui'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, useState } from 'react'
import { Button } from '../../components'
import { SuccessCard } from '../../components/SuccessCard'
import { GoodlokFormError } from '../GoodlokFormError'
import { useGoodlokAuth } from '../hooks'
import styles from './ForgottenPassword.module.sass'

export interface ForgottenPasswordProps {
	username?: string
	protectedActionPublicKey?: string
	protectedActionSecretKey?: string
	onSuccess?: (newPassword: string) => void
}

export const ForgottenPassword: FunctionComponent<ForgottenPasswordProps> = ({
	username,
	protectedActionPublicKey,
	protectedActionSecretKey,
	onSuccess,
}) => {
	const { zeus } = useGoodlokAuth()

	const [secretKey, setSecretKey] = useState('')

	const rawSecretKey = protectedActionSecretKey || secretKey
	const secretKeyParts = rawSecretKey.split('-')
	const usedSecretKey = secretKeyParts.pop()
	const usedPublicKey = secretKeyParts.pop() || protectedActionPublicKey
	const queryClient = useQueryClient()

	const protectedAction = useQuery(
		['protectedAction', usedPublicKey],
		async () => {
			if (usedPublicKey) {
				return zeus.auth('query')({
					getProtectedAction: [
						{ publicKey: usedPublicKey },
						{
							expired: true,
							type: true,
							used: true,
							status: true,
						},
					],
				})
			}
		},
		{ enabled: !!usedPublicKey },
	)

	const resetPassword = useMutation(
		async (newPassword: string) => {
			if (usedPublicKey && usedSecretKey) {
				return zeus
					.auth('mutation')({
						finishResetPassword: [
							{
								publicKey: usedPublicKey,
								secretKey: usedSecretKey,
								newPassword: newPassword,
							},
							{ ok: true, errorMessage: true },
						],
					})
					.then(data => {
						if (data.finishResetPassword.ok) {
							if (newPassword) {
								return newPassword
							}
						}
						throw new Error(data.finishResetPassword.errorMessage ?? 'Něco se nezdařilo')
					})
			}
			throw new Error('Vyplňte prosím nové heslo')
		},
		{
			onSuccess: data => {
				queryClient.invalidateQueries(['protectedAction', usedPublicKey])
				onSuccess?.(data)
			},
		},
	)

	if (protectedAction.data?.getProtectedAction.status === 'used') {
		return (
			<>
				<SuccessCard title={<h1>Heslo bylo změněno</h1>} />
			</>
		)
	}

	return (
		<>
			{username ? (
				<>
					<h2 className={styles.title}>Poslali jsme vám e-mail pro resetování hesla.</h2>
				</>
			) : (
				<h2 className={styles.title}>Zvol si nové heslo.</h2>
			)}
			{username && <div className={styles.text}>Měl by vám dorazit na {username}</div>}
			<form
				className={styles.form}
				action=""
				method="POST"
				onSubmit={e => {
					e.preventDefault()
					resetPassword.mutate(new FormData(e.currentTarget).get('password') as string)
				}}
			>
				<fieldset disabled={resetPassword.isLoading}>
					<GoodlokFormError error={resetPassword.error} />
					{!protectedActionSecretKey && (
						<div className={styles.formRow}>
							<Input
								type="text"
								name="verificationCode"
								label="Autorizační kód"
								labelPosition="inside"
								required
								enterKeyHint="next"
								value={secretKey}
								onChange={e => setSecretKey(e.currentTarget.value)}
							/>
						</div>
					)}
					<div className={styles.formRow}>
						<Input
							type="password"
							name="password"
							label="Nové heslo"
							labelPosition="inside"
							required
							autoFocus
							enterKeyHint="done"
							autoComplete="new-password"
							error={resetPassword.error ? 'Něco se nezdařilo' : null}
							minLength={6}
						/>
					</div>
					<div className={styles.buttons}>
						<Button variant="dark" size="large" round uppercase={false} bold={false} fullWidth type="submit">
							Uložit nové heslo
						</Button>
					</div>
				</fieldset>
			</form>
		</>
	)
}
