import { Collapsible } from 'collapsible-react-component'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { BoxTitle } from './BoxTitle'
import { Button } from './Button'
import { EditBox } from './EditBox'
import { ImageInput } from './ImageInput'
import styles from './PhotoUpload.module.sass'

export type PhotoUploadProps = {
	title?: string
	description?: string
	name: string
	removeButtonTitle?: string
	defalutValue?: string
	hideBorder?: boolean
}

export const PhotoUpload: FunctionComponent<PhotoUploadProps> = ({ ...props }) => {
	return <EditBox name={props.name} content={<PhotoUploadIn {...props} />} hideBorder={props.hideBorder} />
}

export const PhotoUploadIn: FunctionComponent<PhotoUploadProps> = ({
	title,
	description,
	name,
	removeButtonTitle,
	defalutValue,
}) => {
	const [imageUrl, setImageUrl] = useState<string | undefined>(defalutValue)

	return (
		<div>
			{title && (
				<div className={styles.title}>
					<BoxTitle title={title} description={description} />
				</div>
			)}
			<ImageInput
				imageUrl={imageUrl}
				onChange={file => {
					setImageUrl(URL.createObjectURL(file))
				}}
				onRemove={() => setImageUrl(undefined)}
				name={name}
			/>
			{removeButtonTitle && (
				<Collapsible open={Boolean(imageUrl)}>
					<div className={styles.secondaryActions}>
						<Button type="button" variant="seamless" onClick={() => setImageUrl(undefined)} disableGutters>
							<span className={styles.removeButton}>{removeButtonTitle}</span>
						</Button>
					</div>
				</Collapsible>
			)}
		</div>
	)
}
