import { content_role_adminZeus } from '@goodlok/sdk/browser/content_role_admin'
import { ValueTypes } from '@goodlok/sdk/generated/content_role_admin'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { BusinessCustomerFragment } from '../fragments/BusinessCustomerFragment'

export function useBusinessCustomers(
	queryString: string,
	selectedCountryIds: string[] | null,
	selectedCustomerGroupIds: string[] | null,
	selectedAccountManagerIds: string[] | null,
	page: number,
	additionalBusinessCustomerId: string | null,
	date?: { start: string; end: string },
) {
	const perPage = 20
	const { locale } = useRouter()

	const searchQueryString = queryString.length > 2 ? queryString : ''

	return useQuery(
		[
			'businessCustomers',
			searchQueryString,
			selectedCountryIds,
			selectedCustomerGroupIds,
			selectedAccountManagerIds,
			page,
			additionalBusinessCustomerId,
			date,
		] as const,
		async () => {
			const commonData = [
				{
					orderBy: [{ customer: { meta: { fallbackName: 'asc' } } }],
					first: perPage,
					skip: (page - 1) * perPage,
					filter: {
						and: [
							searchQueryString
								? {
										or: [
											{ name: { containsCI: searchQueryString } },
											{ customer: { email: { containsCI: searchQueryString } } },
											{ customer: { user: { email: { containsCI: searchQueryString } } } },
											{ customer: { name: { vocative: { containsCI: searchQueryString } } } },
											{ customer: { name: { nominative: { containsCI: searchQueryString } } } },
											{ customer: { user: { name: { containsCI: searchQueryString } } } },
											{ customer: { user: { meta: { tenantEmail: { containsCI: searchQueryString } } } } },
											{ customer: { user: { meta: { projectRolesString: { containsCI: searchQueryString } } } } },
											{ customer: { defaultBillingSubject: { email: { containsCI: searchQueryString } } } },
											{ customer: { defaultBillingSubject: { companyName: { containsCI: searchQueryString } } } },
										],
								  }
								: {},
							selectedCountryIds && selectedCountryIds.length > 0
								? { customer: { defaultBillingSubject: { country: { code: { in: selectedCountryIds } } } } }
								: {},
							selectedCustomerGroupIds && selectedCustomerGroupIds.length > 0
								? { customer: { group: { id: { in: selectedCustomerGroupIds } } } }
								: {},
							selectedAccountManagerIds && selectedAccountManagerIds.length > 0
								? { accountManager: { staff: { salesManager: { id: { in: selectedAccountManagerIds } } } } }
								: {},
							date ? { customer: { orders: { fulfilledAt: { and: [{ gte: date.start }, { lte: date.end }] } } } } : {},
						],
					},
				},
				{
					pageInfo: { totalCount: true },
					edges: { node: BusinessCustomerFragment(locale, date) },
				},
			] satisfies ValueTypes['Query']['paginateBusinessCustomer']

			const data = await (async () => {
				if (additionalBusinessCustomerId) {
					const { paginateBusinessCustomer, getBusinessCustomer } = await content_role_adminZeus('query')({
						getBusinessCustomer: [{ by: { id: additionalBusinessCustomerId } }, BusinessCustomerFragment(locale, date)],
						paginateBusinessCustomer: commonData,
					})
					return { paginateBusinessCustomer, getBusinessCustomer }
				} else {
					const { paginateBusinessCustomer } = await content_role_adminZeus('query')({
						paginateBusinessCustomer: commonData,
					})
					return { paginateBusinessCustomer }
				}
			})()
			const businessCustomers = {
				perPage,
				page,
				totalCount: data.paginateBusinessCustomer.pageInfo.totalCount,
				items: data.paginateBusinessCustomer.edges.map(edge => edge.node),
				additionalBusinessCustomer: data.getBusinessCustomer,
			}
			return businessCustomers
		},
		{ refetchOnWindowFocus: false, keepPreviousData: true },
	)
}
