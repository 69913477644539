/*
	⚠️⚠️⚠️ DO NOT EDIT! ⚠️⚠️⚠️
	COMPONENT HAS BEEN MODIFIED IN NEW MONOREPO AND CHANGES HERE WILL BE LOST
*/

import clsx from 'clsx'
import type { LinkProps } from 'next/link'
import { useMemo, type FunctionComponent } from 'react'
import { Chip, type ChipProps } from './Chip'
import styles from './ChipNavigation.module.sass'
import { HorizontallyScrollableContent } from './HorizontallyScrollableContent'

type Item = {
	readonly label: string
	readonly value: string
	readonly icon?: ChipProps['icon']
	readonly link?: LinkProps
}

export type ChipNavigationProps = {
	items: readonly Item[]
	value: string | undefined
	onChange?: (value: string) => void
	horizontalScroll?: boolean
}

export const ChipNavigation: FunctionComponent<ChipNavigationProps> = ({
	items,
	value,
	onChange,
	horizontalScroll = false,
}) => {
	const content = useMemo(
		() => (
			<div className={styles.items}>
				{items.map((item, i) => {
					const commonProps = { active: value === item.value, icon: item.icon }
					const props: ChipProps =
						item.link === undefined
							? {
									...commonProps,
									type: 'button',
									onClick:
										onChange === undefined
											? undefined
											: () => {
													onChange(item.value)
											  },
							  }
							: {
									...commonProps,
									...item.link,
									type: 'link',
							  }
					return (
						<Chip key={i} {...props}>
							{item.label}
						</Chip>
					)
				})}
			</div>
		),
		[items, onChange, value],
	)
	return (
		<div className={clsx(styles.wrapper, horizontalScroll && styles.is_scrollable)}>
			{horizontalScroll ? (
				<HorizontallyScrollableContent hideArrowsOnMobile>{content}</HorizontallyScrollableContent>
			) : (
				content
			)}
		</div>
	)
}
