import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import styles from './AppBar.module.sass'
import { useAppFrameContext } from './AppFrame'

export type AppBarProps = {
	name: string
	icon?: ReactNode
	children?: ReactNode
}

export const AppBar: FunctionComponent<AppBarProps> = ({ name, icon, children }) => {
	const appFrame = useAppFrameContext()
	return (
		<div className={clsx(styles.wrapper, children === undefined && styles.is_simple)}>
			<div className={styles.primary}>
				<button
					className={styles.appName}
					type="button"
					onClick={() => {
						appFrame?.toggle()
					}}
				>
					{icon !== undefined && <div className={styles.icon}>{icon}</div>}
					<h1 className={styles.name}>{name}</h1>
				</button>
			</div>
			{children !== undefined && <div className={styles.secondary}>{children}</div>}
		</div>
	)
}
