import type { FunctionComponent } from 'react'
import type { CustomerOrderResult } from '../fragments/CustomerOrderFragment'
import { Address } from './Address'
import { CardColumns } from './CardColumns'
import styles from './OrderDetails.module.sass'
import { OrderItemsOverview } from './OrderItemsOverview'
import { StateLabel } from './StateLabel'

export type OrderDetailsProps = {
	order: CustomerOrderResult
}

export const OrderDetails: FunctionComponent<OrderDetailsProps> = ({ order }) => {
	let totalItemsCount = 0
	order.items.forEach(item => {
		totalItemsCount += item.quantity
	})

	const completedAt = order.fulfilledAt || order.delivery?.deliveredAt

	return (
		<CardColumns
			columns={[
				{
					id: `${order.id}-products`,
					title: `Produkty, ${totalItemsCount} kusů`,
					content: (
						<>
							<OrderItemsOverview items={order.items} />
						</>
					),
				},
				{
					id: `${order.id}-delivery`,
					title: `Doručení`,
					content: (
						<div className={styles.deliveryDetails}>
							{order.delivery?.address && order.delivery?.method?.requireDeliveryAddress && (
								<Address address={order.delivery.address} />
							)}
							<StateLabel variant="neutral" highlighted>
								{order.delivery?.method?.name}
							</StateLabel>
							{completedAt && (
								<StateLabel variant="neutral" highlighted>
									{new Intl.DateTimeFormat('cs-CZ', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
									}).format(new Date(completedAt))}
								</StateLabel>
							)}
						</div>
					),
				},
			]}
		/>
	)
}
