import clsx from 'clsx'
import { FunctionComponent, ReactNode, useCallback, useRef } from 'react'
import { useIntersection } from 'react-use'
import { Icon } from '../icons/Icon'
import styles from './HorizontallyScrollableContent.module.sass'

export type HorizontallyScrollableContentProps = {
	children: ReactNode
	hideArrowsOnMobile?: boolean
}

export const HorizontallyScrollableContent: FunctionComponent<HorizontallyScrollableContentProps> = ({
	children,
	hideArrowsOnMobile,
}) => {
	const itemsInRef = useRef<HTMLDivElement>(null)
	const detectorStartRef = useRef<HTMLDivElement>(null)
	const detectorEndRef = useRef<HTMLDivElement>(null)

	const detectorStartIntersection = useIntersection(detectorStartRef, { threshold: 1 })
	const detectorEndIntersection = useIntersection(detectorEndRef, { threshold: 1 })

	const itemsCanScrollLeft = !(detectorStartIntersection?.isIntersecting ?? true)
	const itemsCanScrollRight = !(detectorEndIntersection?.isIntersecting ?? true)

	const scrollItems = useCallback((direction: 'left' | 'right') => {
		const items = itemsInRef.current
		if (items === null) {
			return
		}
		const directionMultiplier = direction === 'left' ? -1 : 1
		items.scrollBy({
			left: (directionMultiplier * items.clientWidth) / 2,
			behavior: 'smooth',
		})
	}, [])

	return (
		<div
			className={clsx(
				styles.wrapper,
				itemsCanScrollLeft && styles.is_canScroll_left,
				itemsCanScrollRight && styles.is_canScroll_right,
			)}
		>
			<Arrow
				label="předchozí"
				onClick={() => {
					scrollItems('left')
				}}
				visible={itemsCanScrollLeft}
				hideArrowsOnMobile={hideArrowsOnMobile}
			/>
			<div className={styles.in} ref={itemsInRef}>
				<div className={styles.detector} ref={detectorStartRef} />
				<div className={styles.content}>{children}</div>
				<div className={styles.detector} ref={detectorEndRef} />
			</div>
			<Arrow
				label="další"
				onClick={() => {
					scrollItems('right')
				}}
				visible={itemsCanScrollRight}
				hideArrowsOnMobile={hideArrowsOnMobile}
			/>
		</div>
	)
}

const Arrow: FunctionComponent<{
	label: string
	onClick: () => void
	visible: boolean
	hideArrowsOnMobile?: boolean
}> = ({ label, onClick, visible, hideArrowsOnMobile = false }) => {
	return (
		<div className={clsx(styles.arrow, visible && styles.is_visible)}>
			<button
				type="button"
				aria-label={label}
				className={clsx(styles.arrow_in, hideArrowsOnMobile && styles.is_hidden)}
				onClick={onClick}
			>
				<Icon name="arrowRight" />
			</button>
		</div>
	)
}
