import { useEffect } from 'react'
import { ZodError } from 'zod'

export function useFirstErrorFocus(ref: React.RefObject<HTMLFormElement | null>, error: unknown) {
	useEffect(() => {
		if (error instanceof ZodError) {
			const flatten = error.flatten()
			const firstName = Object.keys(flatten.fieldErrors)[0]
			const firstError = flatten.fieldErrors[firstName]?.[0]

			if (firstError) {
				const input = ref.current?.querySelector<HTMLInputElement>(`[name="${firstName}"]`)
				if (input) {
					input.focus()
				}
			}
		}
	}, [error, ref])
}
