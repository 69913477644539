import type { FunctionComponent } from 'react'
import type { ActionMeta, SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import style from './LazySelect.module.sass'

export type LazySelectValue = { value: number | string; label: React.ReactNode }

export type LazySelectProps = {
	value: LazySelectValue | null
	onChange: (newValue: SingleValue<LazySelectValue>, actionMeta: ActionMeta<LazySelectValue>) => void
	loader: (value: string) => Promise<LazySelectValue[]>
}

export const LazySelect: FunctionComponent<LazySelectProps> = props => {
	return (
		<span className={style.ReactSelect}>
			<AsyncSelect
				cacheOptions
				defaultOptions
				value={props.value}
				loadOptions={props.loader}
				onChange={props.onChange}
				classNamePrefix="ReactSelect"
			/>
		</span>
	)
}
