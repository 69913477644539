import { useQuery } from '@tanstack/react-query'
import { useGoodlokAuth } from '../auth'

export function useCustomerInfo(customerId?: string) {
	const g = useGoodlokAuth()

	const id = customerId ?? g.customerId

	return useQuery(
		['customerInfo', id as string] as const,
		async q => {
			const id = q.queryKey[1]
			return g.zeus
				.roleCustomer('query')({
					getCustomer: [
						{ by: { id } },
						{
							id: true,
							email: true,
							familiarity: true,
							welcomeMessage: true,
							meta: [
								{},
								{
									name: true,
									fallbackName: true,
									remainingCreditsCents: true,
								},
							],
							photo: [
								{},
								{
									id: true,
									url: true,
									width: true,
									height: true,
									alt: true,
									size: true,
									type: true,
								},
							],
						},
					],
				})
				.then(data => data.getCustomer)
		},
		{
			enabled: Boolean(id),
		},
	)
}
