import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import styles from './AppDownloadButtons.module.sass'
import downloadOnTheAppStoreBadge from './downloadOnTheAppStoreBadge.png'
import getItOnGooglePlay from './getItOnGooglePlay.png'

export type AppDownloadButtonsProps = { appStoreId?: string | number; appStoreUrl?: string; googlePlayUrl?: string }

export function AppDownloadButtons(props: AppDownloadButtonsProps) {
	const appStoreId = props.appStoreId ?? 1359774702

	const appStoreUrl = props.appStoreUrl ?? `https://itunes.apple.com/app/goodlok/id${appStoreId}`
	const googlePlayUrl = props.googlePlayUrl ?? 'https://play.google.com/store/apps/details?id=cz.mangoweb.goodlok'

	return (
		<div className={styles.wrapper}>
			<Head>
				<meta id="apple-itunes-app" name="apple-itunes-app" content={`app-id=${appStoreId}`} />
			</Head>
			<Link href={appStoreUrl} legacyBehavior>
				<a className={styles.badge}>
					<Image src={downloadOnTheAppStoreBadge} alt="Download on the App Store" sizes="157px" layout="responsive" />
				</a>
			</Link>{' '}
			<Link href={googlePlayUrl} legacyBehavior>
				<a className={styles.badge}>
					<Image src={getItOnGooglePlay} alt="Get it on Google Play" sizes="157px" layout="responsive" />
				</a>
			</Link>
		</div>
	)
}
