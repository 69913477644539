import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import { useTranslate } from '../translations'
import { Button } from './Button'
import styles from './FormNavigationWrapper.module.sass'

export type FormNavigationWrapperProps = {
	submitButtonTitle: string
	isFirstStep: boolean
	isLastStep: boolean
	showPreviousStep: () => void
	showNextStep: () => void
	children: ReactNode
	isInsideModal?: boolean
}

export const FormNavigationWrapper: FunctionComponent<FormNavigationWrapperProps> = ({
	submitButtonTitle,
	isFirstStep,
	isLastStep,
	showNextStep,
	showPreviousStep,
	children,
	isInsideModal,
}) => {
	const translate = useTranslate()
	return (
		<div className={clsx(styles.wrapper, isInsideModal && styles.is_insideModal)}>
			{!isFirstStep && (
				<div className={styles.previousButton}>
					<div className={styles.arrow} />
					<Button type="button" variant="seamless" onClick={showPreviousStep}>
						{translate('buttons.previousStepButton.title')}
					</Button>
				</div>
			)}
			<div className={styles.content}>{children}</div>
			<div className={styles.nextStepButton}>
				{isLastStep ? (
					<Button type="submit" variant="primary" size="medium">
						{submitButtonTitle}
					</Button>
				) : (
					<Button
						type="button"
						variant="primary"
						onClick={event => {
							// prevent form submit caused by unexpected behaviour of ternary operator
							event.preventDefault()
							showNextStep()
						}}
						size="medium"
					>
						{translate('buttons.nextStepButton.title')}
					</Button>
				)}
			</div>
		</div>
	)
}
