/*
	⚠️⚠️⚠️ DO NOT EDIT! ⚠️⚠️⚠️
	COMPONENT HAS BEEN MODIFIED IN NEW MONOREPO AND CHANGES HERE WILL BE LOST
*/

import clsx from 'clsx'
import { ButtonBase, DistinctBaseButtonProps, SharedBaseButtonProps } from 'nextjs-button-base'
import { ReactNode, forwardRef } from 'react'
import styles from './Button.module.sass'
import { Ripple } from './Ripple'

export type ButtonProps = {
	variant: 'primary' | 'secondary' | 'danger' | 'success' | 'dark' | 'light' | 'seamless'
	size?: 'medium' | 'large' | 'small'
	outline?: boolean | number
	fullWidth?: boolean
	round?: boolean
	uppercase?: boolean
	bold?: boolean
	startIcon?: ReactNode
	endIcon?: ReactNode
	visuallydisabled?: boolean
	disableGutters?: boolean
} & Omit<SharedBaseButtonProps, 'className'> &
	DistinctBaseButtonProps

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
	(
		{
			variant,
			size = 'medium',
			outline = false,
			fullWidth = false,
			round = false,
			uppercase = true,
			bold = true,
			startIcon,
			endIcon,
			children,
			visuallydisabled = false,
			disableGutters = false,
			...otherProps
		},
		ref,
	) => {
		return (
			<ButtonBase
				{...otherProps}
				ref={ref}
				className={clsx(
					styles.wrapper,
					styles[`is_variant_${variant}`],
					styles[`is_size_${size}`],
					outline !== false && styles.is_outline,
					fullWidth && styles.is_fullWidth,
					round && styles.is_round,
					!uppercase && styles.is_caseNormal,
					!bold && styles.is_fontWeightNormal,
					visuallydisabled && styles.is_visuallyDisabled,
					disableGutters && styles.is_disableGutters,
				)}
				style={
					typeof outline === 'number'
						? {
								'--Button-progress': outline,
						  }
						: undefined
				}
			>
				<Ripple />
				{startIcon && <span className={styles.icon}>{startIcon}</span>}
				<span className={styles.content}>{children}</span>
				{endIcon && <span className={styles.icon}>{endIcon}</span>}
			</ButtonBase>
		)
	},
)
Button.displayName = 'Button'
