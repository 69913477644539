import React from 'react'

export function Join(props: { children: React.ReactNode; glue: React.ReactNode; last?: React.ReactNode }) {
	const list = React.Children.toArray(props.children)

	const glue = props.glue
	const last = props.last ?? glue

	return (
		<>
			{list.map((item, i) => (
				<React.Fragment key={i}>
					{i > 0 && (i === list.length - 1 ? last : glue)}
					{item}
				</React.Fragment>
			))}
		</>
	)
}
