import type { FunctionComponent } from 'react'
import { Fragment, createElement } from 'react'

export type BreaklinesProps = {
	text: string | null | undefined
	element?: 'div' | 'p' | 'li' | 'br'
}

export const Breaklines: FunctionComponent<BreaklinesProps> = props => {
	const element = props.element ?? 'div'

	const lines = (props.text ?? '').split('\n')

	switch (element) {
		case 'br':
			return (
				<>
					{lines.map((line, i) => (
						<Fragment key={i}>
							{i > 0 && <br />}
							{line}
						</Fragment>
					))}
				</>
			)
	}

	return <>{lines.map((line, i) => createElement(element, { key: i }, line))}</>
}
