import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { useGoodlokAuth } from '../auth'
import { LazySelect } from './LazySelect'

const queryKey = (value: string | null) => ['CustomerSelect', 'customerInfo', value]

export const CustomerSelect: FunctionComponent<{ value: string | null; onChange: (value: string | null) => void }> = ({
	value,
	onChange,
}) => {
	const g = useGoodlokAuth()
	const queryClient = useQueryClient()
	const customerInfo = useQuery(queryKey(value), async () => {
		if (value) {
			return g.zeus
				.roleCustomer('query')({
					getCustomer: [{ by: { id: value } }, { id: true, meta: [{}, { fallbackName: true }] }],
				})
				.then(data => {
					if (data.getCustomer) {
						return {
							value: data.getCustomer.id,
							label: data.getCustomer.meta?.fallbackName,
						}
					}
					return null
				})
		}
		return null
	})
	const fallback = useMemo(() => (value ? { value, label: value } : null), [value])
	return (
		<LazySelect
			value={customerInfo.data ?? fallback}
			onChange={useCallback(
				value => {
					if (value) {
						queryClient.setQueryData(queryKey(String(value.value)), value)
						onChange(String(value.value))
					} else {
						onChange(null)
					}
				},
				[onChange, queryClient],
			)}
			loader={useCallback(
				async value => {
					value = String(value)
					const data = await g.zeus.roleCustomer('query')({
						paginateCustomer: [
							{
								first: 20,
								skip: 0,
								orderBy: [{ meta: { fallbackName: 'asc' } }],
								filter: {
									or: [
										{
											email: { containsCI: value },
										},
										{
											name: { nominative: { containsCI: value } },
										},
										{
											meta: { fallbackName: { containsCI: value } },
										},
									],
								},
							},
							{
								pageInfo: { totalCount: true },
								edges: { node: { id: true, email: true, meta: [{}, { fallbackName: true }] } },
							},
						],
					})
					return data.paginateCustomer.edges.map(({ node }) => ({
						value: node.id,
						label: node.meta?.fallbackName,
					}))
				},
				[g.zeus],
			)}
		/>
	)
}
