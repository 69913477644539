import clsx from 'clsx'
import React from 'react'
import styles from './RadioMark.module.sass'

export type RadioMarkProps = {
	forceChecked?: boolean
	className?: string
}

export const RadioMark: React.FunctionComponent<RadioMarkProps> = ({ forceChecked = false, className }) => {
	return <span className={clsx(styles.wrapper, forceChecked && styles.view_forceChecked, className)} />
}
