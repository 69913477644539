import type { FunctionComponent } from 'react'
import { AddButton } from './AddButton'
import { BoxTitle } from './BoxTitle'
import styles from './TagSelectIn.module.sass'
import { TagWrapper } from './TagWrapper'

export type TagSelectInProps = {
	title?: string
	description?: string
	setOpen: () => void
	selectedItems: Array<{ id: string; title: string }>
	onChange: (tags: string[]) => void
	value: string[]
}

export const TagSelectIn: FunctionComponent<TagSelectInProps> = ({
	title,
	description,
	setOpen,
	selectedItems,
	onChange,
	value,
}) => {
	return (
		<div>
			{title && <BoxTitle title={title} description={description} />}
			<div className={styles.tagList}>
				<AddButton onClick={setOpen} />
				{selectedItems.map(tag => {
					return (
						<TagWrapper key={tag.id} onClick={() => onChange(value.filter(tagId => tagId !== tag.id))}>
							{tag.title}
						</TagWrapper>
					)
				})}
			</div>
		</div>
	)
}
