import { RadioMark } from '@goodlok/ui'
import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import styles from './Radio.module.sass'

export type RadioProps = {
	label: ReactNode
	onChange?: (checked: boolean) => void
	name?: string
	checked?: boolean
	disabled?: boolean
}

export const Radio: FunctionComponent<RadioProps> = ({ label, onChange, name, checked, disabled }) => {
	return (
		<label className={clsx(styles.wrapper, disabled && styles.is_disabled)}>
			<input
				type="radio"
				className={styles.input}
				onChange={event => {
					if (onChange) {
						onChange(event.currentTarget.checked)
					}
				}}
				name={name}
				checked={checked}
				disabled={disabled}
			/>
			<RadioMark />
			<div className={styles.label}>{label}</div>
		</label>
	)
}
