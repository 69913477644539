import type { FunctionComponent } from 'react'
import { Icon } from '../icons/Icon'
import style from './PaymentButtonLogo.module.sass'

export type PaymentButtonLogoProps = {
	canMakePayment: { applePay?: boolean; googlePay?: boolean }
}

export const PaymentButtonLogo: FunctionComponent<PaymentButtonLogoProps> = ({ canMakePayment }) => {
	// &nbsp; to enforce same height as regular text
	const content = canMakePayment?.applePay ? (
		<>
			&nbsp;
			<Icon name="applePayLogo" />
			&nbsp;
		</>
	) : canMakePayment?.googlePay ? (
		<>
			&nbsp;
			<Icon name="googlePayLogo" />
			&nbsp;
		</>
	) : (
		<>zapamatovanou kartou</>
	)

	return <span className={style.wrapper}>{content}</span>
}
