import { getStage } from './getStage'
import type { WorkspaceName } from './workspaceName'
import { workspaceName } from './workspaceName'

type Workspaces = Readonly<{
	[key in WorkspaceName]: Readonly<{
		websiteUrl: string
		name: string
		themeColor: `#${string}`
	}>
}>

const getWorkspaceConfig = () => {
	const stage = getStage()

	const workspaces: Workspaces = {
		goodlok: {
			websiteUrl: stage === 'beta' ? 'https://blog.gdlk.cz/' : 'https://goodlok.cz',
			name: 'Goodlok',
			themeColor: '#5C75F9',
		},
		doubleshot: {
			websiteUrl: 'https://www.doubleshot.cz/',
			name: 'Doubleshot',
			themeColor: '#683539',
		},
		sliceslicebaby: {
			websiteUrl: 'https://www.sliceslicebaby.cz/',
			name: 'Slice Slice Baby',
			themeColor: '#3a88ea',
		},
		kilomayo: {
			websiteUrl: 'https://www.kilomayo-pieces.cz/', // @TODO likely something else
			name: 'KiloMayo',
			themeColor: '#53e59f',
		},
	} as const

	return workspaces[workspaceName]
}

export const workspaceConfig = getWorkspaceConfig()
