import { createZeusVariables } from '@goodlok/sdk/generated/auth'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useState } from 'react'
import { Button } from '../components'
import { useGoodlokAuth } from './hooks'
import { restoreIDPsession } from './utils/idpSession'
import { replaceHostInUrl } from './utils/replaceHostInUrl'

export const FinishIDP: FunctionComponent = () => {
	const [status, setStatus] = useState<'waiting' | 'ok' | 'error' | 'missingSession'>('waiting')
	const g = useGoodlokAuth()
	const router = useRouter()

	const state = router.query.state as string | null

	useEffect(() => {
		if (typeof state === 'string' && state) {
			const restored = restoreIDPsession(state, false)
			if (restored) {
				const variables = createZeusVariables({ sessionData: 'Json!' })({ sessionData: restored.sessionData })
				g.zeus
					.tenant('mutation')(
						{
							signInIDP: [
								{
									identityProvider: restored.identityProvider,
									idpResponse: {
										url: replaceHostInUrl(window.location.href, restored.redirectUrl),
									},
									redirectUrl: restored.redirectUrl,
									sessionData: variables.$('sessionData'),
									expiration: 365 * 24 * 60 * 60,
								},
								{
									ok: true,
									result: {
										token: true,
									},
								},
							],
						},
						{
							variables,
						},
					)
					.then(data => {
						const token = data.signInIDP?.result?.token
						if (token) {
							g.actions.signInWithToken(token).then(async data => {
								if (data.signInWithToken.ok) {
									setStatus('ok')
									if (restored.onSuccessAction) {
										switch (restored.onSuccessAction.type) {
											case 'cloneAndCheckoutAnonymousCart':
												const { copyCartItems } = await g.zeus.orders('mutation')({
													copyCartItems: [
														{
															sourceCartId: restored.onSuccessAction.cloneAndCheckoutAnonymousCartId,
															customerId: data.signInWithToken.customerId,
														},
														{
															ok: true,
															errorCode: true,
															cart: {
																id: true,
																checkoutUrl: true,
															},
														},
													],
												})
												if (!copyCartItems.ok) {
													alert('Něco se nezdařilo')
													return
												}
												if (copyCartItems.cart?.checkoutUrl) {
													window.location.href = copyCartItems.cart.checkoutUrl
													return
												}
										}
									}
									if (restored.successUrl) {
										router.push(restored.successUrl)
									}
								} else {
									setStatus('error')
								}
							})
						} else {
							setStatus('error')
						}
					})
			} else {
				setStatus('missingSession')
			}
		}
	}, [g.actions, g.zeus, router, state])

	return (
		<div style={{ textAlign: 'center' }}>
			<div>
				{status === 'waiting' && <div>Dokončujeme přihlášení…</div>}
				{status === 'ok' && <div>OK</div>}
				{status === 'error' && <div>Něco se nezdařilo</div>}
				{status === 'missingSession' && (
					<Button
						type="button"
						size="medium"
						variant="dark"
						round
						uppercase={false}
						bold={false}
						onClick={() => {
							window.location.href = replaceHostInUrl(window.location.href, 'http://localhost:3112')
						}}
					>
						Dokončit na <code>http://localhost:3112</code>
					</Button>
				)}
			</div>
		</div>
	)
}
