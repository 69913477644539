import type { FunctionComponent, ReactNode } from 'react'
import styles from './Note.module.sass'

export type NoteProps = {
	title?: string
	children: ReactNode
}

export const Note: FunctionComponent<NoteProps> = ({ title, children }) => {
	return (
		<div className={styles.wrapper} title={title}>
			{children}
		</div>
	)
}
