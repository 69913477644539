import clsx from 'clsx'
import { FunctionComponent, ReactNode, useMemo, useState } from 'react'
import styles from './PieChart.module.sass'

const palletePresets = {
	default: ['#2039B9', '#5C75F9', '#5CAEF9', '#0080A8', '#F782D6', '#B682F7', '#874FFF', '#5B0E9B'],
	green: ['#257654', '#007E6B', '#008586', '#008CA2', '#0090BE', '#0093D6'],
	red: ['#D43C3C', '#BE3668', '#934383', '#614D86', '#394E74', '#2F4858'],
} as const

export type PieChartProps = {
	title?: string
	values: Array<{ amount: number; formattedAmount?: ReactNode; unit?: string; label: string }>
	additionalContent?: ReactNode
	pallete?: keyof typeof palletePresets
}

export const PieChart: FunctionComponent<PieChartProps> = ({
	title,
	values,
	additionalContent,
	pallete = 'default',
}) => {
	const total = useMemo(() => values.reduce((previousValue, { amount }) => previousValue + amount, 0), [values])
	const centerX = 100
	const centerY = 100
	const radius = 99
	const pathData = useMemo(() => {
		const data: Array<{ d: string; fill: string }> = []
		let cumulativeAngle = -90
		let index = 0
		for (const value of values) {
			const percentage = (value.amount / total) * 100
			const endAngle = cumulativeAngle + percentage * 3.6
			const startX = centerX + radius * Math.cos((cumulativeAngle * Math.PI) / 180)
			const startY = centerY + radius * Math.sin((cumulativeAngle * Math.PI) / 180)
			const endX = centerX + radius * Math.cos((endAngle * Math.PI) / 180)
			const endY = centerY + radius * Math.sin((endAngle * Math.PI) / 180)

			data.push({
				d: `M ${centerX} ${centerY} L ${startX} ${startY} A ${radius} ${radius} 0 ${
					percentage > 50 ? 1 : 0
				} 1 ${endX} ${endY} Z`,
				fill: palletePresets[pallete][index % palletePresets[pallete].length] || '#000000',
			})
			cumulativeAngle = endAngle
			index++
		}
		return data
	}, [pallete, total, values])

	const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

	return (
		<div
			className={styles.wrapper}
			onMouseLeave={() => {
				setHoveredIndex(null)
			}}
		>
			<div className={styles.chartWrapper}>
				{title && <h3 className={styles.title}>{title}</h3>}
				<svg className={styles.chart} viewBox="0 0 200 200">
					{pathData.length > 1 ? (
						pathData.map((path, index) => (
							<path
								className={clsx(
									styles.path,
									(index === hoveredIndex || hoveredIndex === null) && styles.is_highlighted,
								)}
								key={index}
								d={path.d}
								fill={path.fill}
								strokeWidth={1}
								onMouseEnter={() => {
									setHoveredIndex(index)
								}}
							/>
						))
					) : (
						<circle
							className={clsx(styles.path, styles.is_highlighted)}
							cx={centerX}
							cy={centerY}
							r={radius}
							fill={pathData.at(0)?.fill ?? '#E6E5E3'}
							strokeWidth={1}
							onMouseEnter={() => {
								setHoveredIndex(0)
							}}
						/>
					)}
					<circle className={styles.innerCircle} cx={centerX} cy={centerY} r={radius - 18} />
				</svg>
			</div>
			<div className={styles.content}>
				{values.length > 0 && (
					<ul className={styles.values}>
						{values.map(({ amount, formattedAmount, label }, index) => {
							const percentage = `${new Intl.NumberFormat('cs-CZ').format(
								Math.round((amount / total) * 100 * 10) / 10,
							)}%`
							return (
								<li
									style={{ '--PieChart-value-color': pathData[index].fill }}
									key={label}
									className={clsx(
										styles.value,
										index === hoveredIndex && styles.is_hovered,
										(index === hoveredIndex || hoveredIndex === null) && styles.is_highlighted,
									)}
									onMouseEnter={() => {
										setHoveredIndex(index)
									}}
								>
									<div className={styles.valueIn}>
										<div className={styles.label}>{label}</div>
										<div className={styles.percentage}>{percentage}</div>
										<div className={styles.amount}>{formattedAmount || amount}</div>
									</div>
								</li>
							)
						})}
					</ul>
				)}
				{additionalContent}
			</div>
		</div>
	)
}
