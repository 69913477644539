import { FragmentResult, ValueTypes, ZeusSelect } from '@goodlok/sdk/generated/auth'

export function SignInFragment() {
	return ZeusSelect<ValueTypes['SignInResponse']>()({
		ok: true,
		email: true,
		customerId: true,
		staffId: true,
		personId: true,
		roles: true,
		error: {
			code: true,
			developerMessage: true,
			endUserMessage: true,
		},
	})
}

export type SignInResult = FragmentResult<'SignInResponse', typeof SignInFragment>
