import { useCallback } from 'react'
import { z } from 'zod'
import { PaymentService } from './const'

export const MobileAppSignalPrefix = 'gs:'

export const CloseSignal = z.object({ type: z.literal('close') })

export const AlertSignal = z.object({ type: z.literal('alert'), payload: z.object({ message: z.string() }) })

export const ServiceAuth = z.object({
	type: z.literal('serviceAuth'),
	payload: z.object({ service: z.enum(['facebook', 'appleid']) }),
})

export const AuthorizedSignal = z.object({
	type: z.literal('authorized'),
	payload: z.object({ publicKey: z.string(), confirmKey: z.string() }),
})

export const AuthorizationFailedSignal = z.object({
	type: z.literal('authorizationFailed'),
})

export const OrderCreatedSignal = z.object({
	type: z.literal('orderCreated'),
	payload: z.object({ orderId: z.string().uuid(), paid: z.boolean() }),
})

export const CheckoutWithPaymentServiceSignal = z.object({
	type: z.literal('checkoutWithPaymentService'),
	payload: z.object({
		cartId: z.string().uuid(),
		amountCents: z.number().int(),
		paymentService: PaymentService,
	}),
})

export const MobileAppSignal = z.union([
	CloseSignal,
	AlertSignal,
	OrderCreatedSignal,
	AuthorizedSignal,
	AuthorizationFailedSignal,
	ServiceAuth,
	CheckoutWithPaymentServiceSignal,
])

export type MobileAppSignal = z.infer<typeof MobileAppSignal>

export const MobileAppSignalProtocol = {
	serialize(signal: MobileAppSignal) {
		return `${MobileAppSignalPrefix}${encodeURIComponent(JSON.stringify(signal))}`
	},
	unserialize(value: string) {
		if (value.startsWith(MobileAppSignalPrefix)) {
			const [_, serialized] = value.split(MobileAppSignalPrefix)
			return MobileAppSignal.parse(JSON.parse(decodeURIComponent(serialized)))
		}
		throw new Error('Invalid signal string')
	},
}

export function useMobileAppSignal() {
	// @TODO: detect webview

	const clear = useCallback(() => {
		window.history.replaceState('', document.title, window.location.pathname + window.location.search)
	}, [])

	const send = useCallback((signal: MobileAppSignal, autoClear = true) => {
		const valid = MobileAppSignal.parse(signal)
		const hash = `#${MobileAppSignalProtocol.serialize(valid)}`
		console.log('MobileAppSignal.send', hash, valid)
		window.location.hash = hash
		return new Promise<void>(res => {
			if (autoClear) {
				setTimeout(() => {
					if (window.location.hash === hash) {
						window.history.replaceState('', document.title, window.location.pathname + window.location.search)
					}
					res()
				}, 1000)
			} else {
				res()
			}
		})
	}, [])

	return { send, clear }
}
