import { Icon } from '../icons/Icon'
import { useIsOnline } from '../misc/useIsOnline'
import { Card } from './Card'
import styles from './OfflineIndicator.module.sass'

export function OfflineIndicator() {
	const online = useIsOnline()

	if (online) {
		return null
	}

	return (
		<div className={styles.wrapper}>
			<Card>
				<div className={styles.content}>
					<Icon name="offline" /> offline
				</div>
			</Card>
		</div>
	)
}
