export function UlOrP(props: { items: string[] }) {
	return (
		<>
			{props.items.length > 1 ? (
				<ul>
					{props.items.map((e, i) => (
						<li key={i}>{e}</li>
					))}
				</ul>
			) : (
				<>
					{props.items.map((e, i) => (
						<p key={i}>{e}</p>
					))}
				</>
			)}
		</>
	)
}
