import { ErrorMessage } from '@goodlok/ui'
import type { FunctionComponent } from 'react'
import { useZodFieldErrors } from '../zod/ZodFieldsError'

export type InputErrorProps = {
	name: string
	errorMessage?: string
}

export const InputError: FunctionComponent<InputErrorProps> = ({ name, errorMessage }) => {
	const fieldErrors = useZodFieldErrors()
	const errors = fieldErrors(name)

	if (errors?.length) {
		return <ErrorMessage error={errorMessage} />
	}

	return null
}
