import React from 'react'
import { RadioMark } from './RadioMark'
import styles from './RadioSelect.module.sass'

export type RadioSelectOption = {
	value: string
	label: React.ReactNode
}

export type RadioSelectProps<Value extends string> = {
	options: RadioSelectOption[]
	name: string
	radioGroup: string
	value: Value
	onChange: (value: Value) => void
}

export function RadioSelect<Value extends string>({
	options,
	value,
	onChange,
	name,
	radioGroup,
}: RadioSelectProps<Value>) {
	return (
		<div className={styles.wrapper}>
			{options.flatMap(option => [
				' ',
				<label key={option.value} className={styles.option}>
					<input
						className={styles.input}
						type="radio"
						name={name}
						radioGroup={radioGroup}
						checked={option.value === value}
						value={option.value}
						onChange={e => onChange(e.currentTarget.value as Value)}
					/>
					<RadioMark className={styles.radioMark} /> {option.label}
				</label>,
			])}
		</div>
	)
}
