import clsx from 'clsx'
import Image from 'next/image'
import { forwardRef } from 'react'
import ReactSelect, {
	ActionMeta,
	components,
	MultiValue,
	MultiValueProps,
	Options,
	SingleValue,
	SingleValueProps,
} from 'react-select'
import { Icon, IconName } from '../icons/Icon'
import styles from './ImageSelect.module.sass'

export type ImageSelectOption = {
	readonly label: string
	readonly value: string
	readonly icon?: IconName
	readonly color?: string
	readonly imageUrl?: string
	readonly imageObjectFit?: 'cover' | 'contain'
	readonly disabled?: boolean
}

type SelectProps = {
	isMulti: false
	onChange: (value: SingleValue<ImageSelectOption>, actionMeta: ActionMeta<ImageSelectOption>) => void
	value?: string
}

type MultiSelectProps = {
	isMulti: true
	onChange: (value: MultiValue<ImageSelectOption>, actionMeta: ActionMeta<ImageSelectOption>) => void
	value?: Array<string>
}

export type ImageSelectProps = {
	name: string
	label?: string
	options: ImageSelectOption[]
	disabled?: boolean
	closeMenuOnSelect?: boolean
	menuAlignment?: 'left' | 'center' | 'right'
	isOptionSelected?: (option: ImageSelectOption, selectValue: Options<ImageSelectOption>) => boolean
} & (SelectProps | MultiSelectProps)

const formatOptionLabel = (option: ImageSelectOption, selected: boolean) => {
	return (
		<div className={styles.option}>
			{option.icon && (
				<div className={styles.optionIcon}>
					<Icon name={option.icon} />
				</div>
			)}
			{option.color && (
				<span className={styles.optionColor} style={{ '--ImageSelect-option-background': option.color }} />
			)}
			{option.imageUrl && (
				<div className={styles.optionImage}>
					<Image
						src={option.imageUrl}
						layout="fill"
						objectFit={option.imageObjectFit ?? 'contain'}
						alt=""
						sizes="42px"
					/>
				</div>
			)}
			{selected && !option.icon && !option.color && !option.imageUrl ? (
				<div className={clsx(styles.optionText, styles.isVisible)} title={option.label}>
					{option.label.substring(0, 1)}
				</div>
			) : (
				<div className={styles.optionText}>{option.label}</div>
			)}
		</div>
	)
}

export const ImageSelect = forwardRef<HTMLLabelElement, ImageSelectProps>(
	(
		{ options, name, label, disabled, menuAlignment = 'center', closeMenuOnSelect = true, isOptionSelected, ...props },
		ref,
	) => {
		const onChange = (
			value: SingleValue<ImageSelectOption> | MultiValue<ImageSelectOption>,
			actionMeta: ActionMeta<ImageSelectOption>,
		) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return props.onChange(value as any, actionMeta)
		}

		const defaultOption = options[0]
		const selectedOptionsCount = (props.value ?? []).length

		const isSelectedOptionsCountVisible =
			selectedOptionsCount > 0 && props.value && props.value[0] !== defaultOption.value

		const MultiValue = ({ children, ...props }: MultiValueProps<ImageSelectOption>) => (
			<components.MultiValue {...props}>
				<div className={styles.multiValue}>
					<div className={styles.selectedOptionsPlaceholder}>
						{formatOptionLabel(defaultOption, true)}
						<span className={clsx(styles.selectedOptionsCount, isSelectedOptionsCountVisible && styles.is_visible)} />
					</div>
					{children}
				</div>
			</components.MultiValue>
		)

		const SingleValue = ({ children, ...props }: SingleValueProps<ImageSelectOption>) => (
			<components.SingleValue {...props}>
				<div className={styles.singleValue}>
					<div className={styles.selectedOptionsPlaceholder}>{formatOptionLabel(props.data, true)}</div>
				</div>
			</components.SingleValue>
		)

		return (
			<label
				className={clsx(styles.wrapper, styles[`isMenuAligned_${menuAlignment}`])}
				ref={ref}
				style={{
					'--ImageSelect-selectedOptionsCount': selectedOptionsCount.toString(),
				}}
			>
				{label && <span className={styles.label}>{label}</span>}
				<div>
					<ReactSelect
						name={name}
						value={
							props.isMulti
								? options.filter(option => props.value?.includes(option.value))
								: options.find(option => option.value === props.value)
						}
						isDisabled={disabled}
						defaultValue={options[0]}
						options={options}
						isMulti={props.isMulti}
						hideSelectedOptions={false}
						closeMenuOnSelect={closeMenuOnSelect}
						onChange={onChange}
						isSearchable={false}
						formatOptionLabel={option => formatOptionLabel(option, false)}
						className={styles.select}
						classNamePrefix="imageSelect"
						isOptionSelected={isOptionSelected}
						components={{ MultiValue, SingleValue }}
					/>
				</div>
			</label>
		)
	},
)
ImageSelect.displayName = 'ImageSelect'
