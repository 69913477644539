import type { PaymentMethodType } from '@goodlok/sdk/generated/content_role_admin'
import { FormattedCents, FormattedCreditCents, LoadingSpinner } from '@goodlok/ui'
import { useStripe } from '@stripe/react-stripe-js'
import type {
	CanMakePaymentResult,
	PaymentIntent,
	PaymentRequest,
	PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
	Button,
	PaymentButtonLogo,
	useGoodlokAppSupport,
	useGoodlokAuth,
	useGoodlokCartQueryParams,
	useGoodlokIsAdmin,
	useGoodlokShopping,
	useMobileAppSignal,
} from '..'
import style from './PaymentOptionPicker.module.sass'
import { PillPicker } from './PillPicker'

export type OrderCreatedHandler = (info: { orderId: string }) => void

export function usePaymentRequest(props: {
	amountCents: number
	clientSecretLoader: () => Promise<{ clientSecret: string }>
	onFinish?: (...args: ['fail', unknown] | ['success', PaymentIntent] | ['cancel']) => void
}) {
	const { amountCents } = props
	const [_bump, setBump] = useState(1)

	const clientSecretLoader = useRef(props.clientSecretLoader)
	clientSecretLoader.current = props.clientSecretLoader

	const onFinish = useRef(props.onFinish)
	onFinish.current = props.onFinish

	const label = 'Goodlok'

	const stripe = useStripe()

	const [info, setInfo] = useState<
		| null
		| {
				paymentRequest: PaymentRequest
				canMakePayment: (CanMakePaymentResult & { applePay?: boolean; googlePay?: boolean }) | null
		  }
		| { canMakePayment: false; paymentRequest: undefined }
	>(null)

	const { paymentRequest, canMakePayment } = info ?? {}

	useEffect(() => {
		if (stripe) {
			try {
				const pr = stripe.paymentRequest({
					country: 'CZ',
					currency: 'czk',
					total: {
						label,
						amount: 100,
					},
					requestPayerName: true,
					requestPayerEmail: true,
				})
				pr.canMakePayment().then(result => {
					if (result) {
						setInfo({ paymentRequest: pr, canMakePayment: result })
					} else {
						setInfo({ paymentRequest: undefined, canMakePayment: false })
					}
				})
			} catch (e) {
				// @TODO: e instanceof stripe IntegrationError
				console.error(e)
				setInfo({ paymentRequest: undefined, canMakePayment: false })
			}
		}
	}, [stripe])

	useEffect(() => {
		if (paymentRequest) {
			paymentRequest.update({
				total: {
					amount: amountCents,
					label,
				},
			})
		}
	}, [paymentRequest, amountCents])

	useEffect(() => {
		if (paymentRequest) {
			const onPaymentMethod: (event: PaymentRequestPaymentMethodEvent) => unknown = async event => {
				setBump(b => b + 1)

				if (stripe) {
					try {
						const clientSecret = await clientSecretLoader.current()

						const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
							clientSecret.clientSecret,
							{ payment_method: event.paymentMethod.id },
							{ handleActions: false },
						)

						if (confirmError) {
							console.error(confirmError)
							event.complete('fail')
							onFinish.current?.('fail', confirmError)
						} else {
							console.info(paymentIntent)
							event.complete('success')
							onFinish.current?.('success', paymentIntent)
						}
					} catch (e) {
						console.error(e)
						event.complete('fail')
						onFinish.current?.('fail', e)
					}
				}
			}
			paymentRequest.on('paymentmethod', onPaymentMethod)

			const onCancel: () => unknown = () => {
				setBump(b => b + 1)
				console.log('onCancel', 'cancel')
				onFinish.current?.('cancel')
			}
			paymentRequest.on('cancel', onCancel)

			return () => {
				paymentRequest.off('paymentmethod', onPaymentMethod)
				paymentRequest.off('cancel', onCancel)
			}
		}
	}, [paymentRequest, stripe])

	return { paymentRequest, canMakePayment }
}

export function useCartPaymentOptions() {
	const g = useGoodlokAuth()

	const cartQueryParams = useGoodlokCartQueryParams()

	return useQuery(['cart', 'cartPaymentOptions', cartQueryParams], async () => {
		if (cartQueryParams) {
			return g.zeus
				.orders('query')({
					getCartPaymentOptions: [
						cartQueryParams,
						{
							credits: { remainingCreditsCents: true, expiresAt: true },
							availableCreditsCents: true,
							options: {
								priceCents: true,
								id: true,
								creditsCents: true,
								infoAvailableCredits: true,
								payment: {
									id: true,
									method: [
										{},
										{
											id: true,
											code: true,
											name: true,
											type: true,
										},
									],
								},
							},
						},
					],
				})
				.then(data =>
					data.getCartPaymentOptions
						? {
								...data.getCartPaymentOptions,
								options: data.getCartPaymentOptions.options.sort((a, b) => a.priceCents - b.priceCents),
						  }
						: null,
				)
		}
	})
}

export function useSupportedPaymentButtons() {
	const stripe = useStripe()
	const paymentServices = useGoodlokAppSupport()?.paymentService

	const canMakePayment = useQuery(['canMakePayment', !stripe], async () => {
		try {
			const pr = stripe?.paymentRequest({
				country: 'CZ',
				currency: 'czk',
				total: {
					amount: 100,
					label: '',
				},
			})
			return pr?.canMakePayment() as Promise<null | { applePay?: boolean; googlePay?: boolean }>
		} catch (e) {
			// @TODO: e instanceof stripe IntegrationError
			console.error(e)
			return null
		}
	})

	const paymentButtons = useMemo(() => {
		return paymentServices || canMakePayment.data
			? {
					applePay: (paymentServices?.indexOf('applePay') ?? -1) > -1 || canMakePayment.data?.applePay || false,
					googlePay: (paymentServices?.indexOf('googlePay') ?? -1) > -1 || canMakePayment.data?.googlePay || false,
			  }
			: null
	}, [canMakePayment.data, paymentServices])

	return paymentButtons
}

export function usePaymentMethodName(paymentButtons: ReturnType<typeof useSupportedPaymentButtons>) {
	return useCallback(
		(payment: { method?: { type?: PaymentMethodType; name: string } }) => {
			if (paymentButtons) {
				switch (payment.method?.type) {
					case 'stripe':
						if (paymentButtons) {
							return <PaymentButtonLogo canMakePayment={paymentButtons} />
						}
						return payment.method?.name
				}
			}
			return payment.method?.name
		},
		[paymentButtons],
	)
}

export const PaymentOptionPicker: FunctionComponent<{
	paymentOptionId: string | null
	setPaymentOptionId: (value: string | null) => void
	creditsRestHeader?: React.ReactNode
}> = ({ paymentOptionId, setPaymentOptionId, creditsRestHeader }) => {
	const options = useCartPaymentOptions()

	const queryClient = useQueryClient()

	const g = useGoodlokAuth()
	const c = useGoodlokShopping()
	const cartQueryParams = useGoodlokCartQueryParams()

	const ordersZeus = g.zeus.orders

	const paymentButtons = useSupportedPaymentButtons()

	const paymentMethodName = usePaymentMethodName(paymentButtons)

	const setPaymentOptionMutation = useMutation(async (params: { paymentOptionId: string }) => {
		if (cartQueryParams) {
			return ordersZeus('mutation')({
				setCartPaymentOption: [
					{
						...cartQueryParams,
						paymentOptionId: params.paymentOptionId,
					},
					{ errorCode: true, ok: true },
				],
			}).then(() => {
				queryClient.invalidateQueries(['cart'])
			})
		}
	})

	const basicOptions =
		options.data?.options?.filter(option => !Boolean(option.creditsCents) || !Boolean(option.priceCents)) ?? []

	const creditComboOptions =
		options.data?.options?.filter(option => Boolean(option.creditsCents) && Boolean(option.priceCents)) ?? []

	const showSwitcher = Boolean(creditComboOptions.length)

	const creditsCents = options.data?.availableCreditsCents

	const useCredits = Boolean(creditComboOptions.find(option => option.id === paymentOptionId))

	const line1 = paymentOptionId
	const line2 = useCredits ? paymentOptionId : null

	const firstCreditsPaymentOptionId = useCredits ? paymentOptionId : creditComboOptions[0]?.id

	const creditsOption = showSwitcher
		? {
				label: <>Kredit {!!creditsCents && <FormattedCreditCents cents={creditsCents} />} s doplatkem</>,
				value: firstCreditsPaymentOptionId,
		  }
		: null

	const line1BaseOptions = basicOptions.map(option => ({
		value: option.id,
		label: (
			<strong>
				{paymentMethodName(option.payment)}{' '}
				{typeof option.infoAvailableCredits === 'number' && (
					<small>
						k dispozici <FormattedCreditCents cents={option.infoAvailableCredits} />
					</small>
				)}
			</strong>
		),
	}))
	const line1Options = creditsOption ? [creditsOption, ...line1BaseOptions] : line1BaseOptions

	const line2Options =
		showSwitcher && useCredits
			? creditComboOptions?.map(option => ({
					value: option.id,
					label: (
						<>
							{paymentMethodName(option.payment)}{' '}
							{Boolean(option.priceCents) && <FormattedCents cents={option.priceCents} />}
						</>
					),
			  }))
			: null

	const [line2ref, setLine2Ref] = useState<null | HTMLDivElement>(null)

	useEffect(() => {
		if (line2ref) {
			if (line2ref.parentElement) {
				line2ref.parentElement.style.height = line2Options ? `${line2ref.getBoundingClientRect().height}px` : '0px'
			}
		}
		const t = setTimeout(() => {
			if (line2ref) {
				if (line2Options) {
					line2ref.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
				}
			}
		}, 100)
		return () => clearTimeout(t)
	}, [line2ref, line2Options])

	const handleLine1Change = useCallback(
		(value: string | null) => setPaymentOptionId(value ?? null),
		[setPaymentOptionId],
	)

	const handleLine2Change = useCallback(
		(value: string | null) => setPaymentOptionId(value ?? null),
		[setPaymentOptionId],
	)

	if (!c.cart.data) {
		return null
	}

	return (
		<fieldset className={style.fieldset} disabled={options.isFetching || setPaymentOptionMutation.isLoading}>
			<PillPicker<string | null> autoPick value={line1} options={line1Options} onChange={handleLine1Change} />

			<div className={style.paymentLine2}>
				<div ref={setLine2Ref} className={style.paymentLine2In}>
					{line2Options && (
						<>
							{creditsRestHeader ?? <h3>doplatit zbytek</h3>}
							<PillPicker<string | null> autoPick value={line2} options={line2Options} onChange={handleLine2Change} />
						</>
					)}
				</div>
			</div>
		</fieldset>
	)
}

export const CheckoutSummary: FunctionComponent<{
	creditsCents: number | null
	totalPriceCents: number | null
	itemsCount?: number | null
}> = ({ itemsCount, creditsCents, totalPriceCents }) => {
	const count = itemsCount ?? 0
	return (
		<strong>
			{count === 0 ? 'nic' : count === 1 ? `${count} věc` : count < 5 ? `${count} věci` : `${count} věcí`} za{' '}
			{!!creditsCents && <FormattedCreditCents cents={creditsCents} />}{' '}
			{!!(creditsCents && totalPriceCents) && ' a doplatek '}
			{!!totalPriceCents && <FormattedCents cents={totalPriceCents} />}
		</strong>
	)
}

export const CheckoutActions: FunctionComponent<{
	disabled?: boolean
	loading?: boolean
	paymentOptionId: string | null
	onSubmit?: () => void
	onSuccess?: OrderCreatedHandler
	note?: string
}> = ({ disabled, loading, paymentOptionId, onSuccess, onSubmit, note }) => {
	const options = useCartPaymentOptions()

	const queryClient = useQueryClient()

	const g = useGoodlokAuth()
	const c = useGoodlokShopping()
	const cartQueryParams = useGoodlokCartQueryParams()

	const ordersZeus = g.zeus.orders

	const setPaymentOptionMutation = useMutation(async (params: { paymentOptionId: string }) => {
		if (cartQueryParams) {
			return ordersZeus('mutation')({
				setCartPaymentOption: [
					{
						...cartQueryParams,
						paymentOptionId: params.paymentOptionId,
					},
					{ errorCode: true, ok: true },
				],
			}).then(() => {
				queryClient.invalidateQueries(['cart'])
			})
		}
	})

	const router = useRouter()

	const { send } = useMobileAppSignal()

	const orderFinished = useCallback(
		(order: { id: string; summary?: { orderUrl?: string } }, paid: boolean) => {
			if (onSuccess) {
				onSuccess({ orderId: order.id })
			} else {
				send({ type: 'orderCreated', payload: { orderId: order.id, paid } })
				if (order.summary?.orderUrl) {
					router.push(order.summary.orderUrl)
				}
			}
		},
		[onSuccess, router, send],
	)

	const [latestOrder, setLatestOrder] = useState<null | { id: string; summary?: { orderUrl?: string } }>(null)

	const confirmOrderMutation = useMutation(async () => {
		if (cartQueryParams && !loading) {
			return ordersZeus('mutation')({
				confirmOrder: [
					{ ...cartQueryParams, note },
					{
						errorCode: true,
						ok: true,
						order: {
							id: true,
							summary: [
								{},
								{
									orderUrl: true,
								},
							],
						},
						waitingForPayment: {
							id: true,
							method: [{}, { type: true }],
							data: true,
							meta: [
								{},
								{
									paymentUrl: true,
								},
							],
						},
					},
				],
			}).then(data => {
				setLatestOrder(data.confirmOrder.order ?? null)
				return data
			})
		}
	})

	const paymentButtons = useSupportedPaymentButtons()

	const paymentServices = useGoodlokAppSupport()?.paymentService

	const paymentMethodName = usePaymentMethodName(paymentButtons)

	const activePaymentOption = options.data?.options?.find(option => option.id === paymentOptionId)

	const activePaymentOptionId = activePaymentOption?.id

	const setPayment = setPaymentOptionMutation.mutateAsync

	const confirmOrder = confirmOrderMutation.mutateAsync

	const payment = usePaymentRequest({
		amountCents: activePaymentOption?.priceCents ?? 100,
		clientSecretLoader: useCallback(async () => {
			if (activePaymentOptionId) {
				await setPayment({ paymentOptionId: activePaymentOptionId })
				const order = await confirmOrder()
				if (order?.confirmOrder.waitingForPayment?.data?.clientSecret) {
					return { clientSecret: order.confirmOrder.waitingForPayment.data.clientSecret }
				}
			}
			throw new Error()
		}, [activePaymentOptionId, confirmOrder, setPayment]),
		onFinish(...args) {
			if (args[0] === 'success') {
				if (latestOrder) {
					orderFinished(latestOrder, true)
				}
			}
		},
	})

	const isAdmin = useGoodlokIsAdmin()

	if (!c.cart.data) {
		return null
	}

	const isStripe = activePaymentOption?.payment.method?.type === 'stripe'

	const cartId = c.cart.data?.id

	const paymentService = [
		...(paymentServices ?? []),
		...Object.entries(paymentButtons ?? {})
			.filter(([_key, val]) => val)
			.map(([key]) => key as keyof typeof paymentButtons),
	][0]

	const mobileAppPayment = Boolean(paymentServices?.[0])

	if (activePaymentOption?.payment && isStripe && paymentButtons) {
		return (
			<div>
				<Button
					type="button"
					visuallydisabled={!isAdmin && disabled}
					variant={paymentService ? 'dark' : 'primary'}
					outline={!paymentOptionId}
					round
					uppercase={false}
					onClick={async () => {
						onSubmit?.()
						if (isAdmin || !disabled) {
							if (activePaymentOption) {
								if (mobileAppPayment) {
									// @TODO: počítá s tím, že na jednom zařízení nejde platit víc službama

									await setPaymentOptionMutation.mutateAsync({ paymentOptionId: activePaymentOption.id })
									send({
										type: 'checkoutWithPaymentService',
										payload: {
											amountCents: activePaymentOption?.priceCents ?? 100,
											cartId,
											paymentService,
										},
									})
								} else if (activePaymentOption?.id) {
									payment.paymentRequest?.show()
								}
							} else {
								alert('Vyberte prosím způsob platby')
							}
						}
					}}
				>
					Zaplatit přes {paymentMethodName(activePaymentOption.payment)} →
				</Button>
			</div>
		)
	}

	return (
		<div>
			<span style={{ fontSize: '1.5em', display: 'inline-block', verticalAlign: 'middle' }}>
				<LoadingSpinner visible={loading} />
			</span>{' '}
			<Button
				type="button"
				visuallydisabled={!isAdmin && disabled}
				variant="primary"
				outline={!paymentOptionId}
				round
				uppercase={false}
				onClick={() => {
					onSubmit?.()
					if (isAdmin || !disabled) {
						if (activePaymentOption?.id) {
							console.log(activePaymentOption.id)
							setPaymentOptionMutation.mutateAsync({ paymentOptionId: activePaymentOption.id }).then(() => {
								confirmOrderMutation.mutateAsync().then(data => {
									if (data?.confirmOrder?.waitingForPayment?.meta?.paymentUrl) {
										router.push(data.confirmOrder.waitingForPayment.meta.paymentUrl)
									} else if (data?.confirmOrder?.order?.id) {
										orderFinished(data.confirmOrder.order, false)
									}
								})
							})
						} else {
							alert('Vyberte prosím způsob platby')
						}
					}
				}}
			>
				Koupit →
			</Button>
		</div>
	)
}
