import { Thunder as AdminThunder } from '@goodlok/sdk/generated/content_role_admin'
import { fromThunder } from '@goodlok/sdk/zeus/fromThunder'

export const generateUploadUrl = async (file: File) => {
	const mutation = await fromThunder(AdminThunder, { url: `${process.env.BACKEND_URL}/content` })('mutation')({
		generateUploadUrl: [
			{ contentType: file.type },
			{
				method: true,
				headers: { key: true, value: true },
				url: true,
				publicUrl: true,
			},
		],
	})

	const { url, headers, method, publicUrl } = mutation.generateUploadUrl
	const response = await fetch(url, {
		method,
		headers: Object.fromEntries(headers.map(header => [header.key, header.value])),
		body: file,
	})

	if (!response.ok) {
		throw new Error('Failed to upload file.')
	}

	return publicUrl
}
