import { Input, LoadingSpinner, useMirrorLoading } from '@goodlok/ui'
import { FunctionComponent, useRef } from 'react'
import { Button } from '../../components'
import { GoodlokAuthStatus } from '../GoodlokAuth'
import { GoodlokFormError } from '../GoodlokFormError'
import { useGoodlokAuth } from '../hooks'
import { useGoodlokAuthMutation } from '../useGoodlokAuthMutation'
import styles from './SignIn.module.sass'
import { UsernameInput } from './SignInOrSignUp'

export interface SignInProps {
	goToSignInOrSignUp: () => void
	goToForgottenPassword: () => void
	username: string
	isResetPasswordLoading?: boolean
	onSuccess?: (customer: { id: string }) => void
}

export const SignIn: FunctionComponent<SignInProps> = ({
	goToSignInOrSignUp,
	goToForgottenPassword,
	username,
	isResetPasswordLoading,
	onSuccess,
}) => {
	const formRef = useRef<HTMLFormElement>(null)
	const { signIn } = useGoodlokAuthMutation()
	useMirrorLoading(signIn.mutation.isLoading)
	const { status } = useGoodlokAuth()
	const isDisabled = status === GoodlokAuthStatus.Loading

	return (
		<>
			<h2 className={styles.title}>Jaké je tvoje heslo?</h2>
			<div className={styles.text}>
				{username}{' '}
				<button className={styles.edit} type="button" onClick={() => goToSignInOrSignUp()}>
					Upravit
				</button>
			</div>

			<form
				ref={formRef}
				action=""
				method="POST"
				className={styles.form}
				onSubmit={e => {
					e.preventDefault()
					signIn.mutation.mutateAsync(new FormData(e.currentTarget)).then(data => {
						if (data.signIn.customerId) {
							onSuccess?.({ id: data.signIn.customerId })
						}
					})
				}}
			>
				<fieldset disabled={signIn.mutation.isLoading}>
					<GoodlokFormError error={signIn.mutation.error} />
					<UsernameInput value={username} isAutoFillHelper />
					<Input
						type="password"
						name="password"
						label="Heslo"
						labelPosition="inside"
						required
						autoFocus
						autoComplete="current-password"
						enterKeyHint="done"
						error={signIn.hasError('password')}
						minLength={6}
						readOnly={isDisabled}
					/>
				</fieldset>

				<button
					type="button"
					className={styles.forgottenPassword}
					onClick={() => {
						goToForgottenPassword()
					}}
					disabled={isDisabled}
				>
					Nepamatuješ si heslo? <LoadingSpinner visible={isResetPasswordLoading} />
					{/* @TODO: fix visible underlined gap after question mark */}
				</button>

				<div className={styles.button}>
					<Button
						round
						uppercase={false}
						bold={false}
						variant="dark"
						size="large"
						fullWidth
						type="submit"
						disabled={isDisabled}
					>
						Přihlásit se
					</Button>
				</div>
			</form>
		</>
	)
}
