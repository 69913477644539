import Image from 'next/image'
import { FunctionComponent, ReactNode } from 'react'
import { Icon } from '../icons/Icon'
import { Button } from './Button'
import { Checkbox } from './Checkbox'
import { FilterSection, SearchQueryCondition } from './FilterSelect'
import styles from './FilterSelectOptions.module.sass'
import { ScrollBox } from './ScrollBox'
import { SearchBar } from './SearchBar'

export const itemsPerPage = 50

export type FilterSelectOptionsProps = {
	showBackButton?: boolean
	activeSection: FilterSection | null
	setActiveSectionKey: (key: string | null) => void
	isChecked: (sectionKey: string, id: string) => boolean
	toggleItem: (sectionKey: string, id: string, checked: boolean) => void
	button?: ReactNode
} & SearchQueryCondition

export const FilterSelectOptions: FunctionComponent<FilterSelectOptionsProps> = ({
	onSearchQueryChange,
	searchQuery,
	showBackButton,
	activeSection,
	setActiveSectionKey,
	isChecked,
	toggleItem,
	button,
}) => {
	if (!activeSection) {
		return null
	}

	return (
		<div className={styles.itemWrapper}>
			{showBackButton && (
				<div className={styles.backSection}>
					<div>
						<Button
							type="button"
							variant="seamless"
							onClick={() => setActiveSectionKey(null)}
							size="small"
							startIcon={
								<div className={styles.backButtonIcon}>
									<Icon name="arrowDown" />
								</div>
							}
							disableGutters
						>
							<div className={styles.backButtonTitle}>Zpět</div>
						</Button>
					</div>
					<div className={styles.activeSectionTitle}>{activeSection.title}</div>
					<div className={styles.button}>{button}</div>
				</div>
			)}
			{onSearchQueryChange && (
				<div className={styles.searchBar}>
					<SearchBar
						label="Hledat"
						value={searchQuery[activeSection.key] ?? ''}
						onChange={value => {
							if (!activeSection) {
								throw new Error('filterSelect.activeSection is not defined')
							}
							if (value) {
								const newValue = { ...searchQuery, [activeSection.key]: value }
								onSearchQueryChange(newValue)
							} else {
								const newValue = { ...searchQuery }
								delete newValue[activeSection.key]
								onSearchQueryChange(newValue)
							}
						}}
					/>
				</div>
			)}
			<ScrollBox type="high">
				<div className={styles.options}>
					{'items' in activeSection
						? activeSection.items.map(item => {
								if (!activeSection) {
									throw new Error('filterSelect.activeSection is not defined')
								}
								const checked = isChecked(activeSection.key, item.value)
								return (
									<Checkbox
										key={item.value}
										label={
											<div className={styles.checkboxLabelWrapper}>
												{item.image ? (
													<div className={styles.image}>
														<Image src={item.image.url} alt={item.image.alt ?? ''} fill />
													</div>
												) : activeSection.iconPlaceholderOnItems ? (
													<div className={styles.placeholderIcon}>
														<Icon name={activeSection.iconPlaceholderOnItems} />
													</div>
												) : null}
												{item.label}
											</div>
										}
										onChange={checked => {
											if (!activeSection?.key) {
												throw new Error('filterSelect.activeSectionKey is not defined')
											}
											toggleItem(activeSection.key, item.value, checked)
										}}
										checkedValue={item.value}
										checked={checked}
									/>
								)
						  })
						: 'content' in activeSection
						  ? activeSection.content
						  : null}
				</div>
				{activeSection.searchableExtraItems && (
					<div className={styles.additionalNote}>Dalších {activeSection.totalCount - itemsPerPage} položek</div>
				)}
			</ScrollBox>
		</div>
	)
}
