import type { FunctionComponent, ReactNode } from 'react'
import { CloseButton } from './CloseButton'
import { Modal } from './Modal'
import styles from './ModalFormWrapper.module.sass'

export type ModalFormWrapperProps = {
	children: ReactNode
	title: string
	isOpen: boolean
	onClose: () => void
}

export const ModalFormWrapper: FunctionComponent<ModalFormWrapperProps> = ({ children, title, isOpen, onClose }) => {
	return (
		<Modal isOpen={isOpen} onRequestClose={onClose} desktopAlign="right">
			<div className={styles.wrapper}>
				<div className={styles.heading}>
					<h1 className={styles.title}>{title}</h1>
					<div className={styles.closeButton}>
						<CloseButton onClose={onClose} />
					</div>
				</div>
				{children}
			</div>
		</Modal>
	)
}
