import { ZodError } from 'zod'
import { InvalidPasswordError, UnknownEmailError } from './errors'
import { UlOrP } from './UlOrP'

export function GoodlokFormError(props: { name?: string; error?: unknown; className?: string }) {
	const { name, error, className } = props

	if (!error) {
		return null
	}

	if (name) {
		if (error instanceof ZodError) {
			const flatten = error.flatten()
			const fieldError = flatten.fieldErrors[name]
			if (fieldError) {
				return (
					<div className={className} role="alert">
						<UlOrP items={fieldError} />
					</div>
				)
			}
		}

		return null
	}

	if (error instanceof InvalidPasswordError) {
		return (
			<div className={className} role="alert">
				<p>{error.message}</p>
			</div>
		)
	}

	if (error instanceof UnknownEmailError) {
		return (
			<div className={className} role="alert">
				<p>{error.message}</p>
			</div>
		)
	}

	if (error instanceof ZodError) {
		const flatten = error.flatten()
		return (
			<div className={className} role="alert">
				<UlOrP items={flatten.formErrors} />
			</div>
		)
	}

	return null
}
