import { useContext, useMemo } from 'react'
import { GoodlokAuthContext } from './GoodlokAuth'
import type { WhitelistedRole } from './schema'

export function useGoodlokAuth() {
	const context = useContext(GoodlokAuthContext)

	if (!context) {
		throw new Error('Missing GoodlokAuthContext')
	}

	return context
}

export function useGoodlokStaffId() {
	return useGoodlokAuth().staffId
}

export function useGoodlokSignedIn() {
	return useGoodlokAuth().signedIn
}

export function useGoodlokHasRole(role: WhitelistedRole) {
	const { hasTenantRole } = useGoodlokAuth()
	return useMemo(() => hasTenantRole(role), [hasTenantRole, role])
}

export function useGoodlokHasAtLeastOneRole(roles: WhitelistedRole[]) {
	const { hasTenantRole } = useGoodlokAuth()
	return useMemo(() => roles.some(role => hasTenantRole(role)), [hasTenantRole, roles])
}

export function useGoodlokIsAdmin() {
	return useGoodlokHasRole('admin')
}

export function useGoodlokIsCustomer() {
	return useGoodlokHasRole('customer')
}

export function useGoodlokIsStaff() {
	return useGoodlokHasRole('staff')
}

export function useGoodlokIsShiftsManager() {
	return useGoodlokHasRole('shifts_manager')
}

export function useGoodlokIsShiftsWorker() {
	return useGoodlokHasRole('shifts_worker')
}

export function useGoodlokIsInternalBuyer() {
	return useGoodlokHasRole('internal_buyer')
}

export function useGoodlokIsSalesManager() {
	return useGoodlokHasRole('sales_manager')
}

export function useGoodlokIsStocksManager() {
	return useGoodlokHasRole('stocks_manager')
}

export function useGoodlokIsCashier() {
	return useGoodlokHasRole('cashier')
}

export function useGoodlokIsCashierManager() {
	return useGoodlokHasRole('cashier_manager')
}

export function useGoodlokIsCourier() {
	return useGoodlokHasRole('courier')
}

export function useGoodlokIsCourierManager() {
	return useGoodlokHasRole('courier_manager')
}

export function useGoodlokIsPicker() {
	return useGoodlokHasRole('picker')
}
