import { GraphQLTypes, InputType, Selector } from '@goodlok/sdk/generated/content_role_public'

export const ImageFragment = () =>
	Selector('Image')({
		id: true,
		url: true,
		alt: true,
		width: true,
		height: true,
		type: true,
	})

export type ImageResult = InputType<GraphQLTypes['Image'], ReturnType<typeof ImageFragment>>
