import { FormattedCents } from '@goodlok/ui'
import clsx from 'clsx'
import { useEffect, useReducer, type FunctionComponent } from 'react'
import styles from './PaymentStatus.module.sass'

export type PaymentStatusProps = {
	status: 'pending' | 'success' | 'failure'
	amountCents: number
}

export const PaymentStatus: FunctionComponent<PaymentStatusProps> = ({ status, amountCents }) => {
	const [tick, increment] = useReducer((tick: number) => tick + 1, 0)

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>
		const loop = () => {
			timer = setTimeout(() => {
				increment()
				loop()
			}, 200)
		}
		loop()
		return () => {
			clearTimeout(timer)
		}
	}, [])

	return (
		<div className={clsx(styles.wrapper, styles[`is_status_${status}`], styles[`is_tick_${tick % 5}`])}>
			<div className={styles.icon}>
				<div className={styles.part} />
				<div className={styles.part} />
				<div className={styles.part} />
			</div>
			<div className={styles.label}>
				{status === 'pending' ? 'Probíhá' : status === 'success' ? 'Zaplaceno' : 'Selhání'}
			</div>
			<div className={styles.amount}>
				<FormattedCents cents={amountCents} />
			</div>
		</div>
	)
}
