/* eslint-disable @typescript-eslint/no-explicit-any */

export type ValueFilter = (value: any, replacer?: ValueFilterReplacer) => unknown

export type ValueFilterReplacer = (value: React.ReactNode) => string

const getCircularReplacer = (valueFilter?: ValueFilter, replacer?: ValueFilterReplacer) => {
	const seen = new WeakSet()
	return (_: string | number, value: any) => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return '#CYCLIC_VALUE#'
			}
			seen.add(value)
		}
		if (valueFilter) {
			return valueFilter(value, replacer)
		}
		return value
	}
}

export function simplifyJson(json: string | undefined | null) {
	return (json ?? '').replace(/\n(\s+)"([^\s"-]+)"\:/gi, (a, b, c) => {
		return `\n${b}${c}:`
	})
}

export function dump(value: any, valueFilter?: ValueFilter, replacer?: ValueFilterReplacer) {
	return simplifyJson(JSON.stringify(value, getCircularReplacer(valueFilter, replacer), 2))
}

dump.log = (...args: any[]) => {
	if (args.length === 1) {
		return console.log(dump(args[0]))
	}
	return console.log(dump(args))
}
