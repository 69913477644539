import { workspaceConfig } from '@goodlok/meta/workspaceConfig'
import clsx from 'clsx'
import Head from 'next/head'
import { createContext, FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { z } from 'zod'
import { useGoodlokAuth } from '../auth'
import { useAppSwitcherPreset } from '../misc/useAppSwitcherPreset'
import { useCustomerInfo } from '../misc/useCustomerInfo'
import styles from './AppFrame.module.sass'
import type { AppSwitcherIconProps } from './AppSwitcherIcon'
import { AppSwitcherIconList, AppSwitcherIconListProps } from './AppSwitcherIconList'
import { Avatar } from './Avatar'
import { Logo } from './Logo'

export const AppShortcut = z.object({
	handle: z.string(),
	name: z.string(),
	short: z.string().optional().nullable(),
	url: z.string(),
	pathname: z.string().optional().nullable(),
	color: z.string().optional().nullable(),
})

export type AppShortcut = z.infer<typeof AppShortcut>

export type AppFrameProps = {
	children: React.ReactNode
	appHandle?: string
	apps?: AppShortcut[]
	supportNotch?: boolean
	onDemandOnly?: boolean
	sideMenu?: React.ReactNode
}

export const AppFrameContext = createContext<null | {
	isOpen: boolean
	open: () => void
	close: () => void
	toggle: (force?: boolean) => void
}>(null)

export function useAppFrameContext() {
	const context = useContext(AppFrameContext)

	return context
}

type AppFrameContext = NonNullable<ReturnType<typeof useAppFrameContext>>

export const AppFrame: FunctionComponent<AppFrameProps> = ({
	children,
	apps,
	appHandle,
	supportNotch = false,
	onDemandOnly = false,
	sideMenu,
}) => {
	const g = useGoodlokAuth()
	const preset = useAppSwitcherPreset()
	const [isOpen, setIsOpen] = useState(false)

	const toggle = useCallback((force?: boolean) => {
		setIsOpen(old => {
			if (typeof force === 'undefined') {
				return !old
			}
			return force
		})
	}, [])

	const open = useCallback(() => setIsOpen(true), [])
	const close = useCallback(() => setIsOpen(false), [])

	const context = useMemo<AppFrameContext>(() => {
		return {
			isOpen,
			toggle,
			open,
			close,
		}
	}, [close, isOpen, open, toggle])

	const [isMouseOverSideBar, setIsMouseOverSideBar] = useState(false)
	const [showPreviousNavigation, setShowPreviousNavigation] = useState(false)
	const [showNextNavigation, setShowNextNavigation] = useState(false)
	const sidebarContentRef = useRef<HTMLDivElement>(null)

	const setNavigationVisibility = useCallback(() => {
		const target = sidebarContentRef.current
		if (target) {
			setShowPreviousNavigation(target.scrollTop > 0)
			setShowNextNavigation(target.scrollTop < target.scrollHeight - target.offsetHeight)
		}
	}, [sidebarContentRef])
	useEffect(() => {
		setNavigationVisibility()
	}, [setNavigationVisibility])

	const { websiteUrl } = workspaceConfig

	const customerInfo = useCustomerInfo()
	const settings = preset.apps.find(({ handle }) => handle === 'panel')

	const bottomLinks = useMemo(() => {
		const links: AppSwitcherIconListProps['items'] = [
			{
				onClick: () => {
					g.actions.signOut()
				},
				children: customerInfo.data?.photo ? (
					<div className={styles.userPhoto}>
						<Avatar image={customerInfo.data.photo} alt={customerInfo.data.meta?.name ?? ''} />
					</div>
				) : undefined,
				title: 'Odhlásit se',
				iconName: customerInfo.data?.photo ? undefined : 'friendsOn',
			},
		]
		if (settings) {
			links.unshift({
				...settings,
				isActive: appHandle === 'panel',
				children: settings.short ?? settings.name,
				link: `${settings.url.replace(/\/$/, '')}${settings.pathname ?? ''}`,
				iconName: 'icon' in settings ? settings.icon : null,
				title: settings.name,
			})
		}

		return links
	}, [appHandle, customerInfo.data?.meta?.name, customerInfo.data?.photo, g.actions, settings])

	return (
		<AppFrameContext.Provider value={context}>
			{supportNotch && (
				<Head>
					<meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover, shrink-to-fit=no" />
				</Head>
			)}
			<div
				className={clsx(
					styles.wrapper,
					supportNotch && styles.supportNotch,
					isOpen && styles.is_open,
					onDemandOnly && styles.is_onDemandOnly,
				)}
				style={{
					'--global-colors-brand': workspaceConfig.themeColor,
				}}
			>
				<div
					className={clsx(
						styles.sideBar,
						isMouseOverSideBar && styles.is_mouseOverSideBar,
						showPreviousNavigation && styles.is_previousNavigationVisible,
						showNextNavigation && styles.is_nextNavigationVisible,
					)}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore Inert not yet supported by Typescript
					inert={isOpen || !onDemandOnly ? null : ''}
				>
					<div className={styles.sideBarOverflowGuard}>
						<div
							className={styles.sideBarStickyWrapper}
							onScroll={() => {
								setNavigationVisibility()
							}}
							ref={sidebarContentRef}
						>
							<div
								className={styles.sideBarContent}
								onMouseEnter={() => setIsMouseOverSideBar(true)}
								onMouseOver={() => setIsMouseOverSideBar(true)}
								onMouseLeave={() => setIsMouseOverSideBar(false)}
							>
								<div className={styles.switcher}>
									<div className={styles.lists}>
										<AppSwitcherIconList
											items={[
												{
													noHoverEffect: true,
													link: websiteUrl,
													children: (
														<div className={styles.logo}>
															<Logo variant="small" />
														</div>
													),
												},
												...((apps ?? preset.apps.filter(app => app.handle !== settings?.handle))?.map(
													(app): AppSwitcherIconProps => ({
														isActive: app.handle === appHandle,
														children: app.short ?? app.name,
														iconName: 'icon' in app ? app.icon : null,
														link: `${app.url.replace(/\/$/, '')}${app.pathname ?? ''}`,
														title: app.name,
													}),
												) ?? []),
											]}
										/>
										<AppSwitcherIconList items={bottomLinks} />
									</div>
								</div>
								{sideMenu && <div className={styles.sideMenu}>{sideMenu}</div>}
							</div>
						</div>
					</div>
				</div>
				<div
					className={styles.main}
					onClick={() => {
						if (isOpen) {
							close()
						}
					}}
				>
					<div
						className={styles.main_in}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore Inert not yet supported by Typescript
						inert={isOpen && onDemandOnly ? '' : null}
					>
						{children}
					</div>
				</div>
			</div>
		</AppFrameContext.Provider>
	)
}
