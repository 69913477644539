import clsx from 'clsx'
import { forwardRef } from 'react'
import style from './NativeSelect.module.sass'

export type NativeSelectProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

export const NativeSelect = forwardRef<HTMLSelectElement, NativeSelectProps>(function NativeSelect(props, ref) {
	return (
		<span className={style.container}>
			<select {...props} ref={ref} className={clsx(props.className, style.control)} />
		</span>
	)
})
