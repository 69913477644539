import { FunctionComponent } from 'react'
import { Icon } from '../icons/Icon'
import { Button } from './Button'
import styles from './FilterSelectInMobileButton.module.sass'

export type FilterSelectInMobileButtonProps = {
	onClick: () => void
}

export const FilterSelectInMobileButton: FunctionComponent<FilterSelectInMobileButtonProps> = ({ onClick }) => {
	return (
		<div className={styles.button}>
			<Button
				type="button"
				variant="seamless"
				onClick={onClick}
				startIcon={
					<div className={styles.buttonIcon}>
						<Icon name="checkMark" />
					</div>
				}
			/>
		</div>
	)
}
