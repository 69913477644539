import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent } from 'react'
import { Icon } from '../icons/Icon'
import styles from './ImageInput.module.sass'

type ImageInputProps = {
	name?: string
	imageUrl?: string
	onChange?: (value: File) => void
	onRemove?: () => void
	errorMessage?: string
}

export const ImageInput: FunctionComponent<ImageInputProps> = ({
	name,
	imageUrl,
	onChange,
	onRemove,
	errorMessage,
}) => {
	return (
		<div className={clsx(styles.wrapper, errorMessage && styles.is_error)}>
			<label className={styles.label}>
				<div className={styles.input}></div>
				<input
					className={styles.control}
					type="file"
					accept="image/*"
					name={name}
					onChange={event => {
						const file = event.target.files?.item(0)
						if (onChange && file) {
							onChange(file)
						}
					}}
				/>
				{imageUrl ? (
					<>
						<Image src={imageUrl} alt="" layout="fill" objectFit="cover" />
						{onRemove && (
							<button
								type="button"
								className={styles.remove}
								onClick={event => {
									event.preventDefault()
									event.stopPropagation()
									onRemove()
								}}
							>
								<Icon name="cross" />
							</button>
						)}
					</>
				) : (
					<div className={styles.addNew}>
						<Icon name="plus" />
					</div>
				)}
			</label>
			{errorMessage}
		</div>
	)
}
