import type { FunctionComponent } from 'react'
import { Icon, IconName } from '../icons/Icon'
import { Badge } from './Badge'
import styles from './ProductCount.module.sass'

export type ProductCountProps = {
	count: number
	productIcon?: IconName
}

export const ProductCount: FunctionComponent<ProductCountProps> = ({ count, productIcon = 'productsOff' }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.icon}>
				<Icon name={productIcon} />
			</div>
			<Badge count={count} />
		</div>
	)
}
