import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import styles from './ScrollBox.module.sass'

type ScrollBoxProps = {
	children: React.ReactNode
	type?: 'high' | 'normal'
}

export const ScrollBox: FunctionComponent<ScrollBoxProps> = ({ children, type = 'normal' }) => {
	return <div className={clsx(styles.wrapper, styles[`height_${type}`])}>{children}</div>
}
