import clsx from 'clsx'
import { ReactNode, createContext, useContext, useMemo, useState } from 'react'
import { ImageResult } from '../fragments/ImageFragment'
import { Icon, IconName } from '../icons/Icon'
import { useMediaQuery } from '../misc'
import { useFilterSelect } from '../misc/useFilterSelect'
import { Badge } from './Badge'
import { DropdownWrapper } from './DropdownWrapper'
import styles from './FilterSelect.module.sass'
import { FilterSelectInDesktop, FilterSelectInDesktopProps } from './FilterSelectInDesktop'
import { FilterSelectInMobile } from './FilterSelectInMobile'

export const desktopBreakpoint = JSON.parse(styles.desktopBreakpoint)

export type FilterSelectSearchValue = { [section: string]: string | undefined }

export type FilterSectionValue = { [section: string]: string[] | undefined }

export type FilterGroup = { title: string; sections: FilterSection[] }

export type FilterSection = {
	key: string
	title: string
	icon: IconName
	iconPlaceholderOnItems?: IconName
	selectionMode: 'multi' | 'single'
	storage?: 'localStorage'
} & ({ items: FilterSectionItem[] } | { content: ReactNode }) &
	({ searchableExtraItems: boolean; totalCount: number } | { searchableExtraItems?: undefined; totalCount?: undefined })

export type FilterSectionItem = {
	label: string
	value: string
	image?: ImageResult
}

export type SearchQueryCondition =
	| { searchQuery: FilterSelectSearchValue; onSearchQueryChange: (value: FilterSelectSearchValue) => void }
	| { searchQuery?: undefined; onSearchQueryChange?: undefined }

const FilterSelectContext = createContext<ReturnType<typeof useFilterSelect> | null>(null)

export type FilterSelectProviderPropsWithChildren<Groups extends FilterGroup[]> = {
	children: ReactNode
	filterSelect: ReturnType<typeof useFilterSelect<Groups>>
}

export const FilterSelectProvider = <Groups extends FilterGroup[]>({
	children,
	filterSelect,
}: FilterSelectProviderPropsWithChildren<Groups>) => {
	return <FilterSelectContext.Provider value={filterSelect}>{children}</FilterSelectContext.Provider>
}

export const useFilterSelectValues = () => {
	const context = useContext(FilterSelectContext)
	return context
}

export type FilterSelectProps<Groups extends FilterGroup[]> = {
	filterSelect: ReturnType<typeof useFilterSelect<Groups>>
} & SearchQueryCondition

export const FilterSelect = <Groups extends FilterGroup[]>({
	searchQuery,
	onSearchQueryChange,
	filterSelect,
}: FilterSelectProps<Groups>) => {
	const isDesktop = useMediaQuery(desktopBreakpoint)

	const [isOpen, setIsOpen] = useState(false)

	const filterSelectInProps = useMemo(() => {
		return {
			searchQueryProps: searchQuery ? { searchQuery, onSearchQueryChange } : {},
			filterSelect,
		} satisfies FilterSelectInDesktopProps & FilterSelectInDesktopProps
	}, [filterSelect, onSearchQueryChange, searchQuery])

	const selectedItemsLength = useMemo(
		() => Object.values(filterSelect.value).flatMap(section => section).length,
		[filterSelect.value],
	)

	return (
		<DropdownWrapper
			button={
				<div className={clsx(styles.button, isOpen && styles.is_active, selectedItemsLength > 0 && styles.is_selected)}>
					<div className={styles.buttonIcon}>
						<Icon name="filter" />
					</div>
					{selectedItemsLength > 0 && (
						<div className={styles.badge}>
							<Badge count={selectedItemsLength} variant="plain" parentShape="circular" inverted />
						</div>
					)}
				</div>
			}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
		>
			<div className={styles.content}>
				{isDesktop ? (
					<FilterSelectInDesktop {...filterSelectInProps} />
				) : (
					<FilterSelectInMobile onClose={() => setIsOpen(false)} {...filterSelectInProps} />
				)}
			</div>
		</DropdownWrapper>
	)
}
