import type { FunctionComponent } from 'react'
import { ImageResult } from '../fragments/ImageFragment'
import { BoxTitle } from './BoxTitle'
import styles from './PeopleListIn.module.sass'
import { PeopleListStaffPhoto } from './PeopleListStaffPhoto'

type PeopleListInProps = {
	title?: string
	description?: string
	images: (ImageResult | null)[]
	setIsEdited: () => void
}

export const PeopleListIn: FunctionComponent<PeopleListInProps> = ({ title, description, images, setIsEdited }) => {
	return (
		<div>
			{title && <BoxTitle title={title} description={description} />}
			<div className={styles.imageList}>
				{images?.map((item, index) => {
					return (
						<div key={index} className={styles.image}>
							<PeopleListStaffPhoto photo={item} />
						</div>
					)
				})}
				<button className={styles.addButton} type="button" onClick={setIsEdited} />
			</div>
		</div>
	)
}
