"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCookieDomain = exports.allowedDomains = void 0;
exports.allowedDomains = ['doubleshot.cz', 'sliceslicebaby.cz', 'kilomayo.lol', 'goodlok.cz', 'gdlk.cz'];
const getCookieDomain = (host) => {
    return exports.allowedDomains
        .reduce((host, allowedDomain) => host.replace(new RegExp(`.*\\.${allowedDomain.replaceAll('.', '\\.')}`, 'gim'), allowedDomain), host)
        .replace(/:\d+$/, '');
};
exports.getCookieDomain = getCookieDomain;
