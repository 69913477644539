import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import { Avatar } from './Avatar'
import styles from './UserNameTag.module.sass'

export type UserNameTagProps = {
	name: string | undefined
	note?: ReactNode
	photo:
		| {
				url: string
		  }
		| undefined
	compact?: boolean
}

export const UserNameTag: FunctionComponent<UserNameTagProps> = ({ name, note, photo, compact = false }) => {
	return (
		<div className={clsx(styles.wrapper, compact && styles.is_compact)}>
			<div className={styles.avatar}>
				<Avatar image={photo} alt={name ?? ''} />
			</div>
			<div className={styles.name}>{name || <i>Někdo</i>}</div>
			{note && <div className={styles.note}>{note}</div>}
		</div>
	)
}
