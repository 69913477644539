import type { FunctionComponent } from 'react'
import styles from './CloseButton.module.sass'

export type CloseButtonProps = {
	onClose: () => void
}

export const CloseButton: FunctionComponent<CloseButtonProps> = ({ onClose }) => {
	return <button className={styles.button} onClick={onClose} type="button" />
}
