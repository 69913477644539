import { useQuery } from '@tanstack/react-query'
import { useEffect, type FunctionComponent } from 'react'

export type ReloadWithNewVersionProps = {
	versionIdentifierCommitHash: string
	disabled?: boolean
}

export const ReloadWithNewVersion: FunctionComponent<ReloadWithNewVersionProps> = ({
	versionIdentifierCommitHash,
	disabled,
}) => {
	const { data: { isNewVersionAvailable } = { isNewVersionAvailable: false } } = useQuery(
		[],
		async () => {
			const response = await fetch('/api/health')
			const { commit: newCommitHash } = await response.json()

			const isNewVersionAvailable = newCommitHash !== versionIdentifierCommitHash
			return { isNewVersionAvailable }
		},
		{
			refetchInterval: 1000 * 60 * 5, // Five minutes
		},
	)
	useEffect(() => {
		if (isNewVersionAvailable && !disabled) {
			window.location.reload()
		}
	}, [isNewVersionAvailable, disabled])
	return null
}
