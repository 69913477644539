import { CalendarDate, getLocalTimeZone, today } from '@internationalized/date'
import clsx from 'clsx'
import { FunctionComponent, useMemo } from 'react'
import { Button, Calendar, CalendarCell, CalendarGrid, Heading, RangeCalendar } from 'react-aria-components'
import { Icon } from '../icons/Icon'
import styles from './DatePicker.module.sass'

const timeZone = getLocalTimeZone()

export type DateRangeValue = {
	start: Date
	end: Date
}

export type DatePickerCommon = {
	min?: Date
	max?: Date
}

export type DatePickerSingle = {
	mode: 'single'
	onChange: (value: Date | null) => void
	value: Date | null
}

export type DatePickerRange = {
	mode: 'range'
	onChange: (value: DateRangeValue | null) => void
	value: DateRangeValue | null
}

export type DatePickerProps = DatePickerCommon & (DatePickerSingle | DatePickerRange)

export const dateToCalendarDate = (date: Date) => {
	return new CalendarDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
}

export const DatePicker: FunctionComponent<DatePickerProps> = props => {
	const now = useMemo(() => today(timeZone), [])

	return (
		<div className={styles.wrapper}>
			{props.mode === 'range' ? (
				<RangeCalendar
					onChange={value =>
						props.onChange({
							start: value.start.toDate(timeZone),
							end: value.end.toDate(timeZone),
						})
					}
					value={
						props.value && {
							start: dateToCalendarDate(props.value.start),
							end: dateToCalendarDate(props.value.end),
						}
					}
					className={styles.range}
				>
					<CalendarHeadControls />
					<CalendarGrid>
						{date => {
							const isToday = date.compare(now) === 0
							return <CalendarCell date={date} className={clsx(styles.cell, isToday && styles.is_today)} />
						}}
					</CalendarGrid>
				</RangeCalendar>
			) : props.mode === 'single' ? (
				<Calendar
					className={styles.single}
					onChange={value => props.onChange(value.toDate(timeZone))}
					value={props.value && dateToCalendarDate(props.value)}
				>
					<CalendarHeadControls />
					<CalendarGrid>
						{date => {
							const isToday = date.compare(now) === 0
							return <CalendarCell date={date} className={clsx(styles.cell, isToday && styles.is_today)} />
						}}
					</CalendarGrid>
				</Calendar>
			) : null}
		</div>
	)
}

const CalendarHeadControls = () => {
	return (
		<div className={styles.headingWrapper}>
			<Button slot="previous" className={styles.previousButton}>
				<Icon name="arrowDown" />
			</Button>
			<Heading className={styles.currentMonth} />
			<Button slot="next" className={styles.nextButton}>
				<Icon name="arrowDown" />
			</Button>
		</div>
	)
}
