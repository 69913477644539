import clsx from 'clsx'
import Link from 'next/link'
import React, { Fragment } from 'react'
import type { UrlObject } from 'url'
import styles from './Pagination.module.sass'

const minimumNumberOfNeighbours = 2
const minimumNumberOfEdgeNeighbours = 5

export type PaginationProps = {
	pagesCount: number
	currentPage: number
	pageUrlGenerator: (page: number) => string | UrlObject
	isLoading?: boolean
	scroll?: boolean
}

export const Pagination: React.FunctionComponent<PaginationProps> = ({
	pagesCount,
	currentPage,
	pageUrlGenerator,
	isLoading = false,
	scroll,
}) => {
	const pages = React.useMemo(
		() =>
			new Array(pagesCount)
				.fill(null)
				.map((_, i) => {
					const page = i + 1
					return {
						page,
						isCurrent: page === currentPage,
					}
				})
				.filter(({ page, isCurrent }) => {
					const flipPage = (page: number) => pagesCount - page + 1

					return (
						isCurrent ||
						// Handle symmetry
						[
							{ page, currentPage },
							{ page: flipPage(page), currentPage: flipPage(currentPage) },
						].some(
							({ page, currentPage }) =>
								page === 1 ||
								(page <= minimumNumberOfEdgeNeighbours && currentPage < minimumNumberOfEdgeNeighbours) ||
								Math.abs(page - currentPage) <= minimumNumberOfNeighbours ||
								(page === 2 && page + minimumNumberOfEdgeNeighbours - 2 === currentPage),
						)
					)
				}),
		[currentPage, pagesCount],
	)

	if (pagesCount <= 1) {
		return null
	}

	return (
		<nav className={clsx(styles.wrapper, isLoading && styles.is_loading)}>
			{pages.map(({ page, isCurrent }, i) => (
				<Fragment key={page}>
					{i > 0 && pages[i - 1].page !== page - 1 && <span className={styles.gap}>…</span>}
					<Link href={pageUrlGenerator(page)} scroll={scroll} legacyBehavior>
						<a className={clsx(styles.page, isCurrent && styles.is_current)} data-page={page}>
							{page}
						</a>
					</Link>
				</Fragment>
			))}
		</nav>
	)
}
