import { getCookieDomain } from '@goodlok/meta'
import Cookies from 'js-cookie'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

const cookieName = 'deviceIdentifier'

// Generates same identifier for all domains
export const getDeviceIdentifier = () => {
	if ('location' in globalThis === false) {
		return null
	}
	const identifier = Cookies.get(cookieName) ?? uuidv4()
	Cookies.set(cookieName, identifier, {
		expires: 1000 /* virtually expire never */,
		domain: getCookieDomain(location.host),
	})

	return identifier
}

export const useDeviceIdentifier = () => {
	return useMemo(() => getDeviceIdentifier(), [])
}
