import { Dump, useGoodlokAuth } from '..'
import type { FunctionComponent } from 'react'
import { useQuery } from '@tanstack/react-query'

export const DumpCustomer: FunctionComponent<{ customerId: string }> = ({ customerId }) => {
	const g = useGoodlokAuth()
	const customer = useQuery(['DumpCustomer', customerId], async () => {
		return g.zeus.roleCustomer('query')({
			getCustomer: [
				{ by: { id: customerId } },
				{
					id: true,
					email: true,
					meta: [
						{},
						{
							fallbackName: true,
							isBusiness: true,
						},
					],
					business: [
						{},
						{
							id: true,
							name: true,
						},
					],
				},
			],
		})
	})
	return <Dump data={customer.data} />
}
