export const isDarkHexColor = (hexColor: string) => {
	if (!hexColor.startsWith('#') || hexColor.length !== 7) {
		throw new Error(`Invalid HEX color ${hexColor}.`)
	}
	hexColor = hexColor.slice(1)

	const red = parseInt(hexColor.slice(0, 2), 16),
		green = parseInt(hexColor.slice(2, 4), 16),
		blue = parseInt(hexColor.slice(4, 6), 16)

	return red * 0.299 + green * 0.587 + blue * 0.114 <= 186
}
