import { Icon } from '../icons/Icon'

export const WalletTypeIcon = ({ walletType }: { walletType: undefined | string | null }) => {
	if (!walletType) {
		return null
	}
	if (walletType.includes('apple')) {
		return (
			<span>
				<Icon name="applePayLogo" />
			</span>
		)
	}
	if (walletType.includes('google')) {
		return (
			<span>
				<Icon name="googlePayLogo" />
			</span>
		)
	}
	return <span>{walletType}</span>
}
