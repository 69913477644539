import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../fragments/ImageFragment'
import styles from './StaffBadge.module.sass'

export type StaffBadgeProps = {
	name: string
	title?: string
	image?: ImageResult
}

// @TODO add to styleguide
export const StaffBadge: FunctionComponent<StaffBadgeProps> = ({ name, title, image }) => {
	return (
		<div className={styles.wrapper} title={title}>
			{image && (
				<div className={styles.image}>
					<Image
						src={image.url}
						sizes="20px"
						alt="Fotka manažera zákazníka"
						width={image.width ?? 20}
						height={image.height ?? 20}
					/>
				</div>
			)}
			<span className={styles.name}>{name}</span>
		</div>
	)
}
