/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Json: "String",
	DateTime: "String",
	Query:{
		projectBySlug:{

		},
		projectMemberships:{

		},
		checkResetPasswordToken:{

		}
	},
	Mutation:{
		signUp:{

		},
		signIn:{

		},
		createSessionToken:{

		},
		signOut:{

		},
		changePassword:{

		},
		changeMyPassword:{

		},
		initSignInIDP:{

		},
		signInIDP:{
			idpResponse:"IDPResponseInput",
			sessionData:"Json"
		},
		addIDP:{
			configuration:"Json",
			options:"IDPOptions"
		},
		updateIDP:{
			configuration:"Json",
			options:"IDPOptions"
		},
		disableIDP:{

		},
		enableIDP:{

		},
		prepareOtp:{

		},
		confirmOtp:{

		},
		createResetPasswordRequest:{
			options:"CreateResetPasswordRequestOptions"
		},
		resetPassword:{

		},
		invite:{
			memberships:"MembershipInput",
			options:"InviteOptions"
		},
		unmanagedInvite:{
			memberships:"MembershipInput",
			options:"UnmanagedInviteOptions"
		},
		addProjectMember:{
			memberships:"MembershipInput"
		},
		removeProjectMember:{

		},
		updateProjectMember:{
			memberships:"MembershipInput"
		},
		createApiKey:{
			memberships:"MembershipInput"
		},
		createGlobalApiKey:{

		},
		disableApiKey:{

		},
		addMailTemplate:{
			template:"MailTemplate"
		},
		removeMailTemplate:{
			templateIdentifier:"MailTemplateIdentifier"
		},
		createProject:{
			config:"Json",
			secrets:"ProjectSecret",
			options:"CreateProjectOptions"
		},
		setProjectSecret:{

		},
		updateProject:{
			config:"Json"
		},
		addProjectMailTemplate:{
			template:"MailTemplate"
		},
		removeProjectMailTemplate:{
			templateIdentifier:"MailTemplateIdentifier"
		}
	},
	SignUpErrorCode: true,
	SignInErrorCode: true,
	CreateSessionTokenErrorCode: true,
	SignOutErrorCode: true,
	ChangePasswordErrorCode: true,
	ChangeMyPasswordErrorCode: true,
	InitSignInIDPErrorCode: true,
	IDPResponseInput:{

	},
	SignInIDPErrorCode: true,
	AddIDPErrorCode: true,
	UpdateIDPErrorCode: true,
	DisableIDPErrorCode: true,
	EnableIDPErrorCode: true,
	IDPOptions:{

	},
	InviteErrorCode: true,
	InviteMethod: true,
	InviteOptions:{
		method:"InviteMethod"
	},
	UnmanagedInviteOptions:{

	},
	AddProjectMemberErrorCode: true,
	UpdateProjectMemberErrorCode: true,
	RemoveProjectMemberErrorCode: true,
	CreateApiKeyErrorCode: true,
	DisableApiKeyErrorCode: true,
	VariableEntryInput:{

	},
	MembershipInput:{
		variables:"VariableEntryInput"
	},
	MembershipValidationErrorCode: true,
	Project:{
		members:{
			memberType:"MemberType"
		}
	},
	MemberType: true,
	ConfirmOtpErrorCode: true,
	DisableOtpErrorCode: true,
	MailTemplate:{
		type:"MailType"
	},
	MailType: true,
	MailTemplateIdentifier:{
		type:"MailType"
	},
	AddMailTemplateErrorCode: true,
	RemoveMailTemplateErrorCode: true,
	CheckResetPasswordTokenCode: true,
	CreatePasswordResetRequestErrorCode: true,
	ResetPasswordErrorCode: true,
	CreateResetPasswordRequestOptions:{

	},
	ProjectSecret:{

	},
	CreateProjectOptions:{

	},
	CreateProjectResponseErrorCode: true
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		me:"Identity",
		projects:"Project",
		projectBySlug:"Project",
		projectMemberships:"Membership",
		checkResetPasswordToken:"CheckResetPasswordTokenCode",
		identityProviders:"IdentityProvider"
	},
	Mutation:{
		signUp:"SignUpResponse",
		signIn:"SignInResponse",
		createSessionToken:"CreateSessionTokenResponse",
		signOut:"SignOutResponse",
		changePassword:"ChangePasswordResponse",
		changeMyPassword:"ChangeMyPasswordResponse",
		initSignInIDP:"InitSignInIDPResponse",
		signInIDP:"SignInIDPResponse",
		addIDP:"AddIDPResponse",
		updateIDP:"UpdateIDPResponse",
		disableIDP:"DisableIDPResponse",
		enableIDP:"EnableIDPResponse",
		prepareOtp:"PrepareOtpResponse",
		confirmOtp:"ConfirmOtpResponse",
		disableOtp:"DisableOtpResponse",
		createResetPasswordRequest:"CreatePasswordResetRequestResponse",
		resetPassword:"ResetPasswordResponse",
		invite:"InviteResponse",
		unmanagedInvite:"InviteResponse",
		addProjectMember:"AddProjectMemberResponse",
		removeProjectMember:"RemoveProjectMemberResponse",
		updateProjectMember:"UpdateProjectMemberResponse",
		createApiKey:"CreateApiKeyResponse",
		createGlobalApiKey:"CreateApiKeyResponse",
		disableApiKey:"DisableApiKeyResponse",
		addMailTemplate:"AddMailTemplateResponse",
		removeMailTemplate:"RemoveMailTemplateResponse",
		createProject:"CreateProjectResponse",
		setProjectSecret:"SetProjectSecretResponse",
		updateProject:"UpdateProjectResponse",
		addProjectMailTemplate:"AddMailTemplateResponse",
		removeProjectMailTemplate:"RemoveMailTemplateResponse"
	},
	SignUpResponse:{
		ok:"Boolean",
		errors:"SignUpError",
		error:"SignUpError",
		result:"SignUpResult"
	},
	SignUpError:{
		code:"SignUpErrorCode",
		developerMessage:"String",
		endPersonMessage:"String"
	},
	SignUpResult:{
		person:"Person"
	},
	CommonSignInResult:{
		"...on SignInResult": "SignInResult",
		"...on CreateSessionTokenResult": "CreateSessionTokenResult",
		"...on SignInIDPResult": "SignInIDPResult",
		token:"String",
		person:"Person"
	},
	SignInResponse:{
		ok:"Boolean",
		errors:"SignInError",
		error:"SignInError",
		result:"SignInResult"
	},
	SignInError:{
		code:"SignInErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	SignInResult:{
		token:"String",
		person:"Person"
	},
	CreateSessionTokenResponse:{
		ok:"Boolean",
		error:"CreateSessionTokenError",
		result:"CreateSessionTokenResult"
	},
	CreateSessionTokenError:{
		code:"CreateSessionTokenErrorCode",
		developerMessage:"String"
	},
	CreateSessionTokenResult:{
		token:"String",
		person:"Person"
	},
	SignOutResponse:{
		ok:"Boolean",
		errors:"SignOutError",
		error:"SignOutError"
	},
	SignOutError:{
		code:"SignOutErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	ChangePasswordResponse:{
		ok:"Boolean",
		errors:"ChangePasswordError",
		error:"ChangePasswordError"
	},
	ChangePasswordError:{
		code:"ChangePasswordErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	ChangeMyPasswordResponse:{
		ok:"Boolean",
		error:"ChangeMyPasswordError"
	},
	ChangeMyPasswordError:{
		code:"ChangeMyPasswordErrorCode",
		developerMessage:"String"
	},
	InitSignInIDPResponse:{
		ok:"Boolean",
		errors:"InitSignInIDPError",
		error:"InitSignInIDPError",
		result:"InitSignInIDPResult"
	},
	InitSignInIDPResult:{
		authUrl:"String",
		sessionData:"Json"
	},
	InitSignInIDPError:{
		code:"InitSignInIDPErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	SignInIDPResponse:{
		ok:"Boolean",
		errors:"SignInIDPError",
		error:"SignInIDPError",
		result:"SignInIDPResult"
	},
	SignInIDPError:{
		code:"SignInIDPErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	SignInIDPResult:{
		token:"String",
		person:"Person"
	},
	AddIDPResponse:{
		error:"AddIDPError",
		ok:"Boolean"
	},
	AddIDPError:{
		code:"AddIDPErrorCode",
		developerMessage:"String"
	},
	UpdateIDPResponse:{
		error:"UpdateIDPError",
		ok:"Boolean"
	},
	UpdateIDPError:{
		code:"UpdateIDPErrorCode",
		developerMessage:"String"
	},
	DisableIDPResponse:{
		error:"DisableIDPError",
		ok:"Boolean"
	},
	DisableIDPError:{
		code:"DisableIDPErrorCode",
		developerMessage:"String"
	},
	EnableIDPResponse:{
		error:"EnableIDPError",
		ok:"Boolean"
	},
	EnableIDPError:{
		code:"EnableIDPErrorCode",
		developerMessage:"String"
	},
	IdentityProvider:{
		slug:"String",
		type:"String",
		configuration:"Json",
		disabledAt:"DateTime",
		options:"IDPOptionsOutput"
	},
	IDPOptionsOutput:{
		autoSignUp:"Boolean",
		exclusive:"Boolean"
	},
	InviteResponse:{
		ok:"Boolean",
		errors:"InviteError",
		error:"InviteError",
		result:"InviteResult"
	},
	InviteError:{
		code:"InviteErrorCode",
		developerMessage:"String",
		membershipValidation:"MembershipValidationError",
		endUserMessage:"String"
	},
	InviteResult:{
		person:"Person",
		isNew:"Boolean"
	},
	AddProjectMemberResponse:{
		ok:"Boolean",
		errors:"AddProjectMemberError",
		error:"AddProjectMemberError"
	},
	AddProjectMemberError:{
		code:"AddProjectMemberErrorCode",
		membershipValidation:"MembershipValidationError",
		developerMessage:"String",
		endUserMessage:"String"
	},
	UpdateProjectMemberResponse:{
		ok:"Boolean",
		errors:"UpdateProjectMemberError",
		error:"UpdateProjectMemberError"
	},
	UpdateProjectMemberError:{
		code:"UpdateProjectMemberErrorCode",
		developerMessage:"String",
		membershipValidation:"MembershipValidationError",
		endUserMessage:"String"
	},
	RemoveProjectMemberResponse:{
		ok:"Boolean",
		errors:"RemoveProjectMemberError",
		error:"RemoveProjectMemberError"
	},
	RemoveProjectMemberError:{
		code:"RemoveProjectMemberErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	CreateApiKeyResponse:{
		ok:"Boolean",
		errors:"CreateApiKeyError",
		error:"CreateApiKeyError",
		result:"CreateApiKeyResult"
	},
	CreateApiKeyError:{
		code:"CreateApiKeyErrorCode",
		developerMessage:"String",
		membershipValidation:"MembershipValidationError",
		endUserMessage:"String"
	},
	CreateApiKeyResult:{
		apiKey:"ApiKeyWithToken"
	},
	DisableApiKeyResponse:{
		ok:"Boolean",
		errors:"DisableApiKeyError",
		error:"DisableApiKeyError"
	},
	DisableApiKeyError:{
		code:"DisableApiKeyErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	VariableEntry:{
		name:"String",
		values:"String"
	},
	Membership:{
		role:"String",
		variables:"VariableEntry"
	},
	MembershipValidationError:{
		code:"MembershipValidationErrorCode",
		role:"String",
		variable:"String"
	},
	Person:{
		id:"String",
		email:"String",
		name:"String",
		otpEnabled:"Boolean",
		identity:"Identity"
	},
	ApiKey:{
		id:"String",
		identity:"Identity"
	},
	ApiKeyWithToken:{
		id:"String",
		token:"String",
		identity:"Identity"
	},
	Identity:{
		id:"String",
		description:"String",
		person:"Person",
		apiKey:"ApiKey",
		projects:"IdentityProjectRelation",
		permissions:"IdentityGlobalPermissions",
		roles:"String"
	},
	IdentityGlobalPermissions:{
		canCreateProject:"Boolean"
	},
	IdentityProjectRelation:{
		project:"Project",
		memberships:"Membership"
	},
	Project:{
		id:"String",
		name:"String",
		slug:"String",
		config:"Json",
		roles:"RoleDefinition",
		members:"ProjectIdentityRelation"
	},
	ProjectIdentityRelation:{
		identity:"Identity",
		memberships:"Membership"
	},
	RoleDefinition:{
		name:"String",
		variables:"RoleVariableDefinition"
	},
	RoleVariableDefinition:{
		"...on RoleEntityVariableDefinition": "RoleEntityVariableDefinition",
		"...on RolePredefinedVariableDefinition": "RolePredefinedVariableDefinition",
		"...on RoleConditionVariableDefinition": "RoleConditionVariableDefinition",
		name:"String"
	},
	RoleEntityVariableDefinition:{
		name:"String",
		entityName:"String"
	},
	RolePredefinedVariableDefinition:{
		name:"String",
		value:"String"
	},
	RoleConditionVariableDefinition:{
		name:"String"
	},
	PrepareOtpResponse:{
		ok:"Boolean",
		result:"PrepareOtpResult"
	},
	PrepareOtpResult:{
		otpUri:"String",
		otpSecret:"String"
	},
	ConfirmOtpResponse:{
		ok:"Boolean",
		errors:"ConfirmOtpError",
		error:"ConfirmOtpError"
	},
	ConfirmOtpError:{
		code:"ConfirmOtpErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	DisableOtpResponse:{
		ok:"Boolean",
		errors:"DisableOtpError",
		error:"DisableOtpError"
	},
	DisableOtpError:{
		code:"DisableOtpErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	AddMailTemplateResponse:{
		ok:"Boolean",
		errors:"AddMailTemplateError",
		error:"AddMailTemplateError"
	},
	AddMailTemplateError:{
		code:"AddMailTemplateErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	RemoveMailTemplateResponse:{
		ok:"Boolean",
		errors:"RemoveMailTemplateError",
		error:"RemoveMailTemplateError"
	},
	RemoveMailTemplateError:{
		code:"RemoveMailTemplateErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	CheckResetPasswordTokenResult:{
		code:"CheckResetPasswordTokenCode"
	},
	CreatePasswordResetRequestResponse:{
		ok:"Boolean",
		errors:"CreatePasswordResetRequestError",
		error:"CreatePasswordResetRequestError"
	},
	CreatePasswordResetRequestError:{
		code:"CreatePasswordResetRequestErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	ResetPasswordResponse:{
		ok:"Boolean",
		errors:"ResetPasswordError",
		error:"ResetPasswordError"
	},
	ResetPasswordError:{
		code:"ResetPasswordErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	CreateProjectResponse:{
		ok:"Boolean",
		error:"CreateProjectResponseError",
		result:"CreateProjectResult"
	},
	CreateProjectResponseError:{
		code:"CreateProjectResponseErrorCode",
		developerMessage:"String"
	},
	CreateProjectResult:{
		deployerApiKey:"ApiKeyWithToken"
	},
	SetProjectSecretResponse:{
		ok:"Boolean"
	},
	UpdateProjectResponse:{
		ok:"Boolean"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}


export const HOST="Specify host"



const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, unknown> = {}) => {
  const fetchOptions = options[1] || {};
  if (fetchOptions.method && fetchOptions.method === 'GET') {
    return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  }
  return fetch(`${options[0]}`, {
    body: JSON.stringify({ query, variables }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...fetchOptions,
  })
    .then(handleFetchResponse)
    .then((response: GraphQLResponse) => {
      if (response.errors) {
        throw new GraphQLError(response);
      }
      return response.data;
    });
};




export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};






export const InternalsBuildQuery = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  options?: OperationOptions,
) => {
  const ibb = (k: string, o: InputValueType | VType, p = '', root = true): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt(props, returns, ops, options?.variables?.values)(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const hasVariables = root && options?.variables?.$params ? `(${options.variables?.$params})` : '';
    const keyForDirectives = o.__directives ?? '';
    return `${k} ${keyForDirectives}${hasOperationName}${hasVariables}{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false))
      .join('\n')}}`;
  };
  return ibb;
};










export const Thunder = (fn: FetchFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation)(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullSubscriptionConstruct(fn)(operation)(o as any, ops) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: OperationOptions,
) => InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, ops)(operation, o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();

export const Gql = Chain(HOST);






export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o), options?.variables?.values);
};






export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o));
};





export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | boolean
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string | undefined>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions<Z extends Record<string, unknown> = Record<string, unknown>> = {
  variables?: VariableInput<Z>;
  operationName?: string;
};

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;


export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');




const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (typeof propsP1 === 'boolean' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'object') {
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  variables?: Record<string, unknown>,
) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (a.startsWith('$') && variables?.[a.slice(1)]) {
        return a;
      }
      const checkType = ResolveFromPath(props, returns, ops)(p);
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};




export const resolverFor = <X, T extends keyof ValueTypes, Z extends keyof ValueTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;


export type SelectionFunction<V> = <T>(t: T | V) => T;
export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;




export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : Record<string, unknown>
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>[keyof MapType<SRC, R[P]>];
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};


export const useZeusVariables =
  <T extends Record<string, unknown>>(variables: T) =>
  <
    Z extends {
      [P in keyof T]: unknown;
    },
  >(
    values: Z,
  ) => {
    return {
      $params: Object.keys(variables)
        .map((k) => `$${k}: ${variables[k as keyof T]}`)
        .join(', '),
      $: <U extends keyof Z>(variable: U) => {
        return `$${String(variable)}` as unknown as Z[U];
      },
      values,
    };
  };

export type VariableInput<Z extends Record<string, unknown>> = {
  $params: ReturnType<ReturnType<typeof useZeusVariables>>['$params'];
  values: Z;
};


type ZEUS_INTERFACES = GraphQLTypes["CommonSignInResult"] | GraphQLTypes["RoleVariableDefinition"]
type ZEUS_UNIONS = never

export type ValueTypes = {
    /** Json custom scalar type */
["Json"]:unknown;
	/** DateTime custom scalar type */
["DateTime"]:string;
	["Query"]: AliasType<{
	me?:ValueTypes["Identity"],
	projects?:ValueTypes["Project"],
projectBySlug?: [{	slug: string},ValueTypes["Project"]],
projectMemberships?: [{	projectSlug: string,	identityId: string},ValueTypes["Membership"]],
checkResetPasswordToken?: [{	requestId: string,	token: string},boolean | `@${string}`],
	identityProviders?:ValueTypes["IdentityProvider"],
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
signUp?: [{	email: string,	password?: string | undefined | null,	passwordHash?: string | undefined | null,	roles?: Array<string> | undefined | null,	name?: string | undefined | null},ValueTypes["SignUpResponse"]],
signIn?: [{	email: string,	password: string,	expiration?: number | undefined | null,	otpToken?: string | undefined | null},ValueTypes["SignInResponse"]],
createSessionToken?: [{	email?: string | undefined | null,	personId?: string | undefined | null,	expiration?: number | undefined | null},ValueTypes["CreateSessionTokenResponse"]],
signOut?: [{	all?: boolean | undefined | null},ValueTypes["SignOutResponse"]],
changePassword?: [{	personId: string,	password: string},ValueTypes["ChangePasswordResponse"]],
changeMyPassword?: [{	currentPassword: string,	newPassword: string},ValueTypes["ChangeMyPasswordResponse"]],
initSignInIDP?: [{	identityProvider: string,	redirectUrl: string},ValueTypes["InitSignInIDPResponse"]],
signInIDP?: [{	identityProvider: string,	idpResponse: ValueTypes["IDPResponseInput"],	redirectUrl: string,	sessionData: ValueTypes["Json"],	expiration?: number | undefined | null},ValueTypes["SignInIDPResponse"]],
addIDP?: [{	identityProvider: string,	type: string,	configuration: ValueTypes["Json"],	options?: ValueTypes["IDPOptions"] | undefined | null},ValueTypes["AddIDPResponse"]],
updateIDP?: [{	identityProvider: string,	configuration?: ValueTypes["Json"] | undefined | null,	options?: ValueTypes["IDPOptions"] | undefined | null},ValueTypes["UpdateIDPResponse"]],
disableIDP?: [{	identityProvider: string},ValueTypes["DisableIDPResponse"]],
enableIDP?: [{	identityProvider: string},ValueTypes["EnableIDPResponse"]],
prepareOtp?: [{	label?: string | undefined | null},ValueTypes["PrepareOtpResponse"]],
confirmOtp?: [{	otpToken: string},ValueTypes["ConfirmOtpResponse"]],
	disableOtp?:ValueTypes["DisableOtpResponse"],
createResetPasswordRequest?: [{	email: string,	options?: ValueTypes["CreateResetPasswordRequestOptions"] | undefined | null},ValueTypes["CreatePasswordResetRequestResponse"]],
resetPassword?: [{	token: string,	password: string},ValueTypes["ResetPasswordResponse"]],
invite?: [{	email: string,	name?: string | undefined | null,	projectSlug: string,	memberships: Array<ValueTypes["MembershipInput"]>,	options?: ValueTypes["InviteOptions"] | undefined | null},ValueTypes["InviteResponse"]],
unmanagedInvite?: [{	email: string,	name?: string | undefined | null,	projectSlug: string,	memberships: Array<ValueTypes["MembershipInput"]>,	options?: ValueTypes["UnmanagedInviteOptions"] | undefined | null},ValueTypes["InviteResponse"]],
addProjectMember?: [{	projectSlug: string,	identityId: string,	memberships: Array<ValueTypes["MembershipInput"]>},ValueTypes["AddProjectMemberResponse"]],
removeProjectMember?: [{	projectSlug: string,	identityId: string},ValueTypes["RemoveProjectMemberResponse"]],
updateProjectMember?: [{	projectSlug: string,	identityId: string,	memberships: Array<ValueTypes["MembershipInput"]>},ValueTypes["UpdateProjectMemberResponse"]],
createApiKey?: [{	projectSlug: string,	memberships: Array<ValueTypes["MembershipInput"]>,	description: string,	tokenHash?: string | undefined | null},ValueTypes["CreateApiKeyResponse"]],
createGlobalApiKey?: [{	description: string,	roles?: Array<string> | undefined | null,	tokenHash?: string | undefined | null},ValueTypes["CreateApiKeyResponse"]],
disableApiKey?: [{	id: string},ValueTypes["DisableApiKeyResponse"]],
addMailTemplate?: [{	template: ValueTypes["MailTemplate"]},ValueTypes["AddMailTemplateResponse"]],
removeMailTemplate?: [{	templateIdentifier: ValueTypes["MailTemplateIdentifier"]},ValueTypes["RemoveMailTemplateResponse"]],
createProject?: [{	projectSlug: string,	name?: string | undefined | null,	config?: ValueTypes["Json"] | undefined | null,	secrets?: Array<ValueTypes["ProjectSecret"]> | undefined | null,	options?: ValueTypes["CreateProjectOptions"] | undefined | null},ValueTypes["CreateProjectResponse"]],
setProjectSecret?: [{	projectSlug: string,	key: string,	value: string},ValueTypes["SetProjectSecretResponse"]],
updateProject?: [{	projectSlug: string,	name?: string | undefined | null,	config?: ValueTypes["Json"] | undefined | null,	mergeConfig?: boolean | undefined | null},ValueTypes["UpdateProjectResponse"]],
addProjectMailTemplate?: [{	template: ValueTypes["MailTemplate"]},ValueTypes["AddMailTemplateResponse"]],
removeProjectMailTemplate?: [{	templateIdentifier: ValueTypes["MailTemplateIdentifier"]},ValueTypes["RemoveMailTemplateResponse"]],
		__typename?: boolean | `@${string}`
}>;
	["SignUpResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["SignUpError"],
	error?:ValueTypes["SignUpError"],
	result?:ValueTypes["SignUpResult"],
		__typename?: boolean | `@${string}`
}>;
	["SignUpError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endPersonMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignUpErrorCode"]:SignUpErrorCode;
	["SignUpResult"]: AliasType<{
	person?:ValueTypes["Person"],
		__typename?: boolean | `@${string}`
}>;
	["CommonSignInResult"]:AliasType<{
		token?:boolean | `@${string}`,
	person?:ValueTypes["Person"];
		['...on SignInResult']?: Omit<ValueTypes["SignInResult"],keyof ValueTypes["CommonSignInResult"]>;
		['...on CreateSessionTokenResult']?: Omit<ValueTypes["CreateSessionTokenResult"],keyof ValueTypes["CommonSignInResult"]>;
		['...on SignInIDPResult']?: Omit<ValueTypes["SignInIDPResult"],keyof ValueTypes["CommonSignInResult"]>;
		__typename?: boolean | `@${string}`
}>;
	["SignInResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["SignInError"],
	error?:ValueTypes["SignInError"],
	result?:ValueTypes["SignInResult"],
		__typename?: boolean | `@${string}`
}>;
	["SignInError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignInErrorCode"]:SignInErrorCode;
	["SignInResult"]: AliasType<{
	token?:boolean | `@${string}`,
	person?:ValueTypes["Person"],
		__typename?: boolean | `@${string}`
}>;
	["CreateSessionTokenResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	error?:ValueTypes["CreateSessionTokenError"],
	result?:ValueTypes["CreateSessionTokenResult"],
		__typename?: boolean | `@${string}`
}>;
	["CreateSessionTokenError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateSessionTokenErrorCode"]:CreateSessionTokenErrorCode;
	["CreateSessionTokenResult"]: AliasType<{
	token?:boolean | `@${string}`,
	person?:ValueTypes["Person"],
		__typename?: boolean | `@${string}`
}>;
	["SignOutResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["SignOutError"],
	error?:ValueTypes["SignOutError"],
		__typename?: boolean | `@${string}`
}>;
	["SignOutError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignOutErrorCode"]:SignOutErrorCode;
	["ChangePasswordResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["ChangePasswordError"],
	error?:ValueTypes["ChangePasswordError"],
		__typename?: boolean | `@${string}`
}>;
	["ChangePasswordError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChangePasswordErrorCode"]:ChangePasswordErrorCode;
	["ChangeMyPasswordResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	error?:ValueTypes["ChangeMyPasswordError"],
		__typename?: boolean | `@${string}`
}>;
	["ChangeMyPasswordError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChangeMyPasswordErrorCode"]:ChangeMyPasswordErrorCode;
	["InitSignInIDPResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["InitSignInIDPError"],
	error?:ValueTypes["InitSignInIDPError"],
	result?:ValueTypes["InitSignInIDPResult"],
		__typename?: boolean | `@${string}`
}>;
	["InitSignInIDPResult"]: AliasType<{
	authUrl?:boolean | `@${string}`,
	sessionData?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitSignInIDPError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitSignInIDPErrorCode"]:InitSignInIDPErrorCode;
	["IDPResponseInput"]: {
	url: string
};
	["SignInIDPResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["SignInIDPError"],
	error?:ValueTypes["SignInIDPError"],
	result?:ValueTypes["SignInIDPResult"],
		__typename?: boolean | `@${string}`
}>;
	["SignInIDPError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignInIDPErrorCode"]:SignInIDPErrorCode;
	["SignInIDPResult"]: AliasType<{
	token?:boolean | `@${string}`,
	person?:ValueTypes["Person"],
		__typename?: boolean | `@${string}`
}>;
	["AddIDPResponse"]: AliasType<{
	error?:ValueTypes["AddIDPError"],
	ok?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddIDPError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddIDPErrorCode"]:AddIDPErrorCode;
	["UpdateIDPResponse"]: AliasType<{
	error?:ValueTypes["UpdateIDPError"],
	ok?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateIDPError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateIDPErrorCode"]:UpdateIDPErrorCode;
	["DisableIDPResponse"]: AliasType<{
	error?:ValueTypes["DisableIDPError"],
	ok?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DisableIDPError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DisableIDPErrorCode"]:DisableIDPErrorCode;
	["EnableIDPResponse"]: AliasType<{
	error?:ValueTypes["EnableIDPError"],
	ok?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EnableIDPError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EnableIDPErrorCode"]:EnableIDPErrorCode;
	["IdentityProvider"]: AliasType<{
	slug?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	configuration?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	options?:ValueTypes["IDPOptionsOutput"],
		__typename?: boolean | `@${string}`
}>;
	["IDPOptionsOutput"]: AliasType<{
	autoSignUp?:boolean | `@${string}`,
	exclusive?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["IDPOptions"]: {
	autoSignUp?: boolean | undefined | null,
	exclusive?: boolean | undefined | null
};
	["InviteResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["InviteError"],
	error?:ValueTypes["InviteError"],
	result?:ValueTypes["InviteResult"],
		__typename?: boolean | `@${string}`
}>;
	["InviteError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	membershipValidation?:ValueTypes["MembershipValidationError"],
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InviteErrorCode"]:InviteErrorCode;
	["InviteResult"]: AliasType<{
	person?:ValueTypes["Person"],
	isNew?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InviteMethod"]:InviteMethod;
	["InviteOptions"]: {
	method?: ValueTypes["InviteMethod"] | undefined | null,
	mailVariant?: string | undefined | null
};
	["UnmanagedInviteOptions"]: {
	password?: string | undefined | null,
	resetTokenHash?: string | undefined | null
};
	["AddProjectMemberResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["AddProjectMemberError"],
	error?:ValueTypes["AddProjectMemberError"],
		__typename?: boolean | `@${string}`
}>;
	["AddProjectMemberError"]: AliasType<{
	code?:boolean | `@${string}`,
	membershipValidation?:ValueTypes["MembershipValidationError"],
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddProjectMemberErrorCode"]:AddProjectMemberErrorCode;
	["UpdateProjectMemberResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["UpdateProjectMemberError"],
	error?:ValueTypes["UpdateProjectMemberError"],
		__typename?: boolean | `@${string}`
}>;
	["UpdateProjectMemberError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	membershipValidation?:ValueTypes["MembershipValidationError"],
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateProjectMemberErrorCode"]:UpdateProjectMemberErrorCode;
	["RemoveProjectMemberResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["RemoveProjectMemberError"],
	error?:ValueTypes["RemoveProjectMemberError"],
		__typename?: boolean | `@${string}`
}>;
	["RemoveProjectMemberError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RemoveProjectMemberErrorCode"]:RemoveProjectMemberErrorCode;
	["CreateApiKeyResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["CreateApiKeyError"],
	error?:ValueTypes["CreateApiKeyError"],
	result?:ValueTypes["CreateApiKeyResult"],
		__typename?: boolean | `@${string}`
}>;
	["CreateApiKeyError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	membershipValidation?:ValueTypes["MembershipValidationError"],
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateApiKeyErrorCode"]:CreateApiKeyErrorCode;
	["CreateApiKeyResult"]: AliasType<{
	apiKey?:ValueTypes["ApiKeyWithToken"],
		__typename?: boolean | `@${string}`
}>;
	["DisableApiKeyResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["DisableApiKeyError"],
	error?:ValueTypes["DisableApiKeyError"],
		__typename?: boolean | `@${string}`
}>;
	["DisableApiKeyError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DisableApiKeyErrorCode"]:DisableApiKeyErrorCode;
	["VariableEntryInput"]: {
	name: string,
	values: Array<string>
};
	["VariableEntry"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MembershipInput"]: {
	role: string,
	variables: Array<ValueTypes["VariableEntryInput"]>
};
	["Membership"]: AliasType<{
	role?:boolean | `@${string}`,
	variables?:ValueTypes["VariableEntry"],
		__typename?: boolean | `@${string}`
}>;
	["MembershipValidationError"]: AliasType<{
	code?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	variable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MembershipValidationErrorCode"]:MembershipValidationErrorCode;
	["Person"]: AliasType<{
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	otpEnabled?:boolean | `@${string}`,
	identity?:ValueTypes["Identity"],
		__typename?: boolean | `@${string}`
}>;
	["ApiKey"]: AliasType<{
	id?:boolean | `@${string}`,
	identity?:ValueTypes["Identity"],
		__typename?: boolean | `@${string}`
}>;
	["ApiKeyWithToken"]: AliasType<{
	id?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
	identity?:ValueTypes["Identity"],
		__typename?: boolean | `@${string}`
}>;
	["Identity"]: AliasType<{
	id?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	person?:ValueTypes["Person"],
	apiKey?:ValueTypes["ApiKey"],
	projects?:ValueTypes["IdentityProjectRelation"],
	permissions?:ValueTypes["IdentityGlobalPermissions"],
	roles?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["IdentityGlobalPermissions"]: AliasType<{
	canCreateProject?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["IdentityProjectRelation"]: AliasType<{
	project?:ValueTypes["Project"],
	memberships?:ValueTypes["Membership"],
		__typename?: boolean | `@${string}`
}>;
	["Project"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	config?:boolean | `@${string}`,
	roles?:ValueTypes["RoleDefinition"],
members?: [{	memberType?: ValueTypes["MemberType"] | undefined | null},ValueTypes["ProjectIdentityRelation"]],
		__typename?: boolean | `@${string}`
}>;
	["MemberType"]:MemberType;
	["ProjectIdentityRelation"]: AliasType<{
	identity?:ValueTypes["Identity"],
	memberships?:ValueTypes["Membership"],
		__typename?: boolean | `@${string}`
}>;
	["RoleDefinition"]: AliasType<{
	name?:boolean | `@${string}`,
	variables?:ValueTypes["RoleVariableDefinition"],
		__typename?: boolean | `@${string}`
}>;
	["RoleVariableDefinition"]:AliasType<{
		name?:boolean | `@${string}`;
		['...on RoleEntityVariableDefinition']?: Omit<ValueTypes["RoleEntityVariableDefinition"],keyof ValueTypes["RoleVariableDefinition"]>;
		['...on RolePredefinedVariableDefinition']?: Omit<ValueTypes["RolePredefinedVariableDefinition"],keyof ValueTypes["RoleVariableDefinition"]>;
		['...on RoleConditionVariableDefinition']?: Omit<ValueTypes["RoleConditionVariableDefinition"],keyof ValueTypes["RoleVariableDefinition"]>;
		__typename?: boolean | `@${string}`
}>;
	["RoleEntityVariableDefinition"]: AliasType<{
	name?:boolean | `@${string}`,
	entityName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RolePredefinedVariableDefinition"]: AliasType<{
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RoleConditionVariableDefinition"]: AliasType<{
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrepareOtpResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	result?:ValueTypes["PrepareOtpResult"],
		__typename?: boolean | `@${string}`
}>;
	["PrepareOtpResult"]: AliasType<{
	otpUri?:boolean | `@${string}`,
	otpSecret?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ConfirmOtpResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["ConfirmOtpError"],
	error?:ValueTypes["ConfirmOtpError"],
		__typename?: boolean | `@${string}`
}>;
	["ConfirmOtpError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ConfirmOtpErrorCode"]:ConfirmOtpErrorCode;
	["DisableOtpResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["DisableOtpError"],
	error?:ValueTypes["DisableOtpError"],
		__typename?: boolean | `@${string}`
}>;
	["DisableOtpError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DisableOtpErrorCode"]:DisableOtpErrorCode;
	["MailTemplate"]: {
	projectSlug?: string | undefined | null,
	type: ValueTypes["MailType"],
	/** Custom mail variant identifier, e.g. a locale. */
	variant?: string | undefined | null,
	subject: string,
	content: string,
	useLayout?: boolean | undefined | null
};
	["MailType"]:MailType;
	["MailTemplateIdentifier"]: {
	projectSlug?: string | undefined | null,
	type: ValueTypes["MailType"],
	variant?: string | undefined | null
};
	["AddMailTemplateResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["AddMailTemplateError"],
	error?:ValueTypes["AddMailTemplateError"],
		__typename?: boolean | `@${string}`
}>;
	["AddMailTemplateError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddMailTemplateErrorCode"]:AddMailTemplateErrorCode;
	["RemoveMailTemplateResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["RemoveMailTemplateError"],
	error?:ValueTypes["RemoveMailTemplateError"],
		__typename?: boolean | `@${string}`
}>;
	["RemoveMailTemplateError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RemoveMailTemplateErrorCode"]:RemoveMailTemplateErrorCode;
	["CheckResetPasswordTokenResult"]: AliasType<{
	code?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckResetPasswordTokenCode"]:CheckResetPasswordTokenCode;
	["CreatePasswordResetRequestResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["CreatePasswordResetRequestError"],
	error?:ValueTypes["CreatePasswordResetRequestError"],
		__typename?: boolean | `@${string}`
}>;
	["CreatePasswordResetRequestError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreatePasswordResetRequestErrorCode"]:CreatePasswordResetRequestErrorCode;
	["ResetPasswordResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errors?:ValueTypes["ResetPasswordError"],
	error?:ValueTypes["ResetPasswordError"],
		__typename?: boolean | `@${string}`
}>;
	["ResetPasswordError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ResetPasswordErrorCode"]:ResetPasswordErrorCode;
	["CreateResetPasswordRequestOptions"]: {
	mailProject?: string | undefined | null,
	mailVariant?: string | undefined | null
};
	["ProjectSecret"]: {
	key: string,
	value: string
};
	["CreateProjectOptions"]: {
	deployTokenHash?: string | undefined | null,
	noDeployToken?: boolean | undefined | null
};
	["CreateProjectResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	error?:ValueTypes["CreateProjectResponseError"],
	result?:ValueTypes["CreateProjectResult"],
		__typename?: boolean | `@${string}`
}>;
	["CreateProjectResponseError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateProjectResult"]: AliasType<{
	deployerApiKey?:ValueTypes["ApiKeyWithToken"],
		__typename?: boolean | `@${string}`
}>;
	["CreateProjectResponseErrorCode"]:CreateProjectResponseErrorCode;
	["SetProjectSecretResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateProjectResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    /** Json custom scalar type */
["Json"]:any;
	/** DateTime custom scalar type */
["DateTime"]:string;
	["Query"]: {
		me: GraphQLTypes["Identity"],
	projects: Array<GraphQLTypes["Project"]>,
	projectBySlug?: GraphQLTypes["Project"] | undefined,
	projectMemberships: Array<GraphQLTypes["Membership"]>,
	checkResetPasswordToken: GraphQLTypes["CheckResetPasswordTokenCode"],
	identityProviders: Array<GraphQLTypes["IdentityProvider"]>
};
	["Mutation"]: {
		signUp?: GraphQLTypes["SignUpResponse"] | undefined,
	signIn?: GraphQLTypes["SignInResponse"] | undefined,
	createSessionToken?: GraphQLTypes["CreateSessionTokenResponse"] | undefined,
	signOut?: GraphQLTypes["SignOutResponse"] | undefined,
	changePassword?: GraphQLTypes["ChangePasswordResponse"] | undefined,
	changeMyPassword?: GraphQLTypes["ChangeMyPasswordResponse"] | undefined,
	initSignInIDP?: GraphQLTypes["InitSignInIDPResponse"] | undefined,
	signInIDP?: GraphQLTypes["SignInIDPResponse"] | undefined,
	addIDP?: GraphQLTypes["AddIDPResponse"] | undefined,
	updateIDP?: GraphQLTypes["UpdateIDPResponse"] | undefined,
	disableIDP?: GraphQLTypes["DisableIDPResponse"] | undefined,
	enableIDP?: GraphQLTypes["EnableIDPResponse"] | undefined,
	prepareOtp?: GraphQLTypes["PrepareOtpResponse"] | undefined,
	confirmOtp?: GraphQLTypes["ConfirmOtpResponse"] | undefined,
	disableOtp?: GraphQLTypes["DisableOtpResponse"] | undefined,
	createResetPasswordRequest?: GraphQLTypes["CreatePasswordResetRequestResponse"] | undefined,
	resetPassword?: GraphQLTypes["ResetPasswordResponse"] | undefined,
	invite?: GraphQLTypes["InviteResponse"] | undefined,
	unmanagedInvite?: GraphQLTypes["InviteResponse"] | undefined,
	addProjectMember?: GraphQLTypes["AddProjectMemberResponse"] | undefined,
	removeProjectMember?: GraphQLTypes["RemoveProjectMemberResponse"] | undefined,
	updateProjectMember?: GraphQLTypes["UpdateProjectMemberResponse"] | undefined,
	createApiKey?: GraphQLTypes["CreateApiKeyResponse"] | undefined,
	createGlobalApiKey?: GraphQLTypes["CreateApiKeyResponse"] | undefined,
	disableApiKey?: GraphQLTypes["DisableApiKeyResponse"] | undefined,
	addMailTemplate?: GraphQLTypes["AddMailTemplateResponse"] | undefined,
	removeMailTemplate?: GraphQLTypes["RemoveMailTemplateResponse"] | undefined,
	createProject?: GraphQLTypes["CreateProjectResponse"] | undefined,
	setProjectSecret?: GraphQLTypes["SetProjectSecretResponse"] | undefined,
	updateProject?: GraphQLTypes["UpdateProjectResponse"] | undefined,
	addProjectMailTemplate?: GraphQLTypes["AddMailTemplateResponse"] | undefined,
	removeProjectMailTemplate?: GraphQLTypes["RemoveMailTemplateResponse"] | undefined
};
	["SignUpResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["SignUpError"]>,
	error?: GraphQLTypes["SignUpError"] | undefined,
	result?: GraphQLTypes["SignUpResult"] | undefined
};
	["SignUpError"]: {
		code: GraphQLTypes["SignUpErrorCode"],
	developerMessage: string,
	endPersonMessage?: string | undefined
};
	["SignUpErrorCode"]: GraphQLTypes["SignUpErrorCode"];
	["SignUpResult"]: {
		person: GraphQLTypes["Person"]
};
	["CommonSignInResult"]: ModelTypes["SignInResult"] | ModelTypes["CreateSessionTokenResult"] | ModelTypes["SignInIDPResult"];
	["SignInResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["SignInError"]>,
	error?: GraphQLTypes["SignInError"] | undefined,
	result?: GraphQLTypes["SignInResult"] | undefined
};
	["SignInError"]: {
		code: GraphQLTypes["SignInErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["SignInErrorCode"]: GraphQLTypes["SignInErrorCode"];
	["SignInResult"]: {
		token: string,
	person: GraphQLTypes["Person"]
};
	["CreateSessionTokenResponse"]: {
		ok: boolean,
	error?: GraphQLTypes["CreateSessionTokenError"] | undefined,
	result?: GraphQLTypes["CreateSessionTokenResult"] | undefined
};
	["CreateSessionTokenError"]: {
		code: GraphQLTypes["CreateSessionTokenErrorCode"],
	developerMessage: string
};
	["CreateSessionTokenErrorCode"]: GraphQLTypes["CreateSessionTokenErrorCode"];
	["CreateSessionTokenResult"]: {
		token: string,
	person: GraphQLTypes["Person"]
};
	["SignOutResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["SignOutError"]>,
	error?: GraphQLTypes["SignOutError"] | undefined
};
	["SignOutError"]: {
		code: GraphQLTypes["SignOutErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["SignOutErrorCode"]: GraphQLTypes["SignOutErrorCode"];
	["ChangePasswordResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["ChangePasswordError"]>,
	error?: GraphQLTypes["ChangePasswordError"] | undefined
};
	["ChangePasswordError"]: {
		code: GraphQLTypes["ChangePasswordErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["ChangePasswordErrorCode"]: GraphQLTypes["ChangePasswordErrorCode"];
	["ChangeMyPasswordResponse"]: {
		ok: boolean,
	error?: GraphQLTypes["ChangeMyPasswordError"] | undefined
};
	["ChangeMyPasswordError"]: {
		code: GraphQLTypes["ChangeMyPasswordErrorCode"],
	developerMessage: string
};
	["ChangeMyPasswordErrorCode"]: GraphQLTypes["ChangeMyPasswordErrorCode"];
	["InitSignInIDPResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["InitSignInIDPError"]>,
	error?: GraphQLTypes["InitSignInIDPError"] | undefined,
	result?: GraphQLTypes["InitSignInIDPResult"] | undefined
};
	["InitSignInIDPResult"]: {
		authUrl: string,
	sessionData: GraphQLTypes["Json"]
};
	["InitSignInIDPError"]: {
		code: GraphQLTypes["InitSignInIDPErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["InitSignInIDPErrorCode"]: GraphQLTypes["InitSignInIDPErrorCode"];
	["IDPResponseInput"]: GraphQLTypes["IDPResponseInput"];
	["SignInIDPResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["SignInIDPError"]>,
	error?: GraphQLTypes["SignInIDPError"] | undefined,
	result?: GraphQLTypes["SignInIDPResult"] | undefined
};
	["SignInIDPError"]: {
		code: GraphQLTypes["SignInIDPErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["SignInIDPErrorCode"]: GraphQLTypes["SignInIDPErrorCode"];
	["SignInIDPResult"]: {
		token: string,
	person: GraphQLTypes["Person"]
};
	["AddIDPResponse"]: {
		error?: GraphQLTypes["AddIDPError"] | undefined,
	ok: boolean
};
	["AddIDPError"]: {
		code: GraphQLTypes["AddIDPErrorCode"],
	developerMessage: string
};
	["AddIDPErrorCode"]: GraphQLTypes["AddIDPErrorCode"];
	["UpdateIDPResponse"]: {
		error?: GraphQLTypes["UpdateIDPError"] | undefined,
	ok: boolean
};
	["UpdateIDPError"]: {
		code: GraphQLTypes["UpdateIDPErrorCode"],
	developerMessage: string
};
	["UpdateIDPErrorCode"]: GraphQLTypes["UpdateIDPErrorCode"];
	["DisableIDPResponse"]: {
		error?: GraphQLTypes["DisableIDPError"] | undefined,
	ok: boolean
};
	["DisableIDPError"]: {
		code: GraphQLTypes["DisableIDPErrorCode"],
	developerMessage: string
};
	["DisableIDPErrorCode"]: GraphQLTypes["DisableIDPErrorCode"];
	["EnableIDPResponse"]: {
		error?: GraphQLTypes["EnableIDPError"] | undefined,
	ok: boolean
};
	["EnableIDPError"]: {
		code: GraphQLTypes["EnableIDPErrorCode"],
	developerMessage: string
};
	["EnableIDPErrorCode"]: GraphQLTypes["EnableIDPErrorCode"];
	["IdentityProvider"]: {
		slug: string,
	type: string,
	configuration: GraphQLTypes["Json"],
	disabledAt: GraphQLTypes["DateTime"],
	options: GraphQLTypes["IDPOptionsOutput"]
};
	["IDPOptionsOutput"]: {
		autoSignUp: boolean,
	exclusive: boolean
};
	["IDPOptions"]: GraphQLTypes["IDPOptions"];
	["InviteResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["InviteError"]>,
	error?: GraphQLTypes["InviteError"] | undefined,
	result?: GraphQLTypes["InviteResult"] | undefined
};
	["InviteError"]: {
		code: GraphQLTypes["InviteErrorCode"],
	developerMessage: string,
	membershipValidation?: Array<GraphQLTypes["MembershipValidationError"]> | undefined,
	endUserMessage?: string | undefined
};
	["InviteErrorCode"]: GraphQLTypes["InviteErrorCode"];
	["InviteResult"]: {
		person: GraphQLTypes["Person"],
	isNew: boolean
};
	["InviteMethod"]: GraphQLTypes["InviteMethod"];
	["InviteOptions"]: GraphQLTypes["InviteOptions"];
	["UnmanagedInviteOptions"]: GraphQLTypes["UnmanagedInviteOptions"];
	["AddProjectMemberResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["AddProjectMemberError"]>,
	error?: GraphQLTypes["AddProjectMemberError"] | undefined
};
	["AddProjectMemberError"]: {
		code: GraphQLTypes["AddProjectMemberErrorCode"],
	membershipValidation?: Array<GraphQLTypes["MembershipValidationError"]> | undefined,
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["AddProjectMemberErrorCode"]: GraphQLTypes["AddProjectMemberErrorCode"];
	["UpdateProjectMemberResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["UpdateProjectMemberError"]>,
	error?: GraphQLTypes["UpdateProjectMemberError"] | undefined
};
	["UpdateProjectMemberError"]: {
		code: GraphQLTypes["UpdateProjectMemberErrorCode"],
	developerMessage: string,
	membershipValidation?: Array<GraphQLTypes["MembershipValidationError"]> | undefined,
	endUserMessage?: string | undefined
};
	["UpdateProjectMemberErrorCode"]: GraphQLTypes["UpdateProjectMemberErrorCode"];
	["RemoveProjectMemberResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["RemoveProjectMemberError"]>,
	error?: GraphQLTypes["RemoveProjectMemberError"] | undefined
};
	["RemoveProjectMemberError"]: {
		code: GraphQLTypes["RemoveProjectMemberErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["RemoveProjectMemberErrorCode"]: GraphQLTypes["RemoveProjectMemberErrorCode"];
	["CreateApiKeyResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["CreateApiKeyError"]>,
	error?: GraphQLTypes["CreateApiKeyError"] | undefined,
	result?: GraphQLTypes["CreateApiKeyResult"] | undefined
};
	["CreateApiKeyError"]: {
		code: GraphQLTypes["CreateApiKeyErrorCode"],
	developerMessage: string,
	membershipValidation?: Array<GraphQLTypes["MembershipValidationError"]> | undefined,
	endUserMessage?: string | undefined
};
	["CreateApiKeyErrorCode"]: GraphQLTypes["CreateApiKeyErrorCode"];
	["CreateApiKeyResult"]: {
		apiKey: GraphQLTypes["ApiKeyWithToken"]
};
	["DisableApiKeyResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["DisableApiKeyError"]>,
	error?: GraphQLTypes["DisableApiKeyError"] | undefined
};
	["DisableApiKeyError"]: {
		code: GraphQLTypes["DisableApiKeyErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["DisableApiKeyErrorCode"]: GraphQLTypes["DisableApiKeyErrorCode"];
	["VariableEntryInput"]: GraphQLTypes["VariableEntryInput"];
	["VariableEntry"]: {
		name: string,
	values: Array<string>
};
	["MembershipInput"]: GraphQLTypes["MembershipInput"];
	["Membership"]: {
		role: string,
	variables: Array<GraphQLTypes["VariableEntry"]>
};
	["MembershipValidationError"]: {
		code: GraphQLTypes["MembershipValidationErrorCode"],
	role: string,
	variable?: string | undefined
};
	["MembershipValidationErrorCode"]: GraphQLTypes["MembershipValidationErrorCode"];
	["Person"]: {
		id: string,
	email?: string | undefined,
	name?: string | undefined,
	otpEnabled: boolean,
	identity: GraphQLTypes["Identity"]
};
	["ApiKey"]: {
		id: string,
	identity: GraphQLTypes["Identity"]
};
	["ApiKeyWithToken"]: {
		id: string,
	token?: string | undefined,
	identity: GraphQLTypes["Identity"]
};
	["Identity"]: {
		id: string,
	description?: string | undefined,
	person?: GraphQLTypes["Person"] | undefined,
	apiKey?: GraphQLTypes["ApiKey"] | undefined,
	projects: Array<GraphQLTypes["IdentityProjectRelation"]>,
	permissions?: GraphQLTypes["IdentityGlobalPermissions"] | undefined,
	roles?: Array<string> | undefined
};
	["IdentityGlobalPermissions"]: {
		canCreateProject: boolean
};
	["IdentityProjectRelation"]: {
		project: GraphQLTypes["Project"],
	memberships: Array<GraphQLTypes["Membership"]>
};
	["Project"]: {
		id: string,
	name: string,
	slug: string,
	config: GraphQLTypes["Json"],
	roles: Array<GraphQLTypes["RoleDefinition"]>,
	members: Array<GraphQLTypes["ProjectIdentityRelation"]>
};
	["MemberType"]: GraphQLTypes["MemberType"];
	["ProjectIdentityRelation"]: {
		identity: GraphQLTypes["Identity"],
	memberships: Array<GraphQLTypes["Membership"]>
};
	["RoleDefinition"]: {
		name: string,
	variables: Array<GraphQLTypes["RoleVariableDefinition"]>
};
	["RoleVariableDefinition"]: ModelTypes["RoleEntityVariableDefinition"] | ModelTypes["RolePredefinedVariableDefinition"] | ModelTypes["RoleConditionVariableDefinition"];
	["RoleEntityVariableDefinition"]: {
		name: string,
	entityName: string
};
	["RolePredefinedVariableDefinition"]: {
		name: string,
	value: string
};
	["RoleConditionVariableDefinition"]: {
		name: string
};
	["PrepareOtpResponse"]: {
		ok: boolean,
	result?: GraphQLTypes["PrepareOtpResult"] | undefined
};
	["PrepareOtpResult"]: {
		otpUri: string,
	otpSecret: string
};
	["ConfirmOtpResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["ConfirmOtpError"]>,
	error?: GraphQLTypes["ConfirmOtpError"] | undefined
};
	["ConfirmOtpError"]: {
		code: GraphQLTypes["ConfirmOtpErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["ConfirmOtpErrorCode"]: GraphQLTypes["ConfirmOtpErrorCode"];
	["DisableOtpResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["DisableOtpError"]>,
	error?: GraphQLTypes["DisableOtpError"] | undefined
};
	["DisableOtpError"]: {
		code: GraphQLTypes["DisableOtpErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["DisableOtpErrorCode"]: GraphQLTypes["DisableOtpErrorCode"];
	["MailTemplate"]: GraphQLTypes["MailTemplate"];
	["MailType"]: GraphQLTypes["MailType"];
	["MailTemplateIdentifier"]: GraphQLTypes["MailTemplateIdentifier"];
	["AddMailTemplateResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["AddMailTemplateError"]>,
	error?: GraphQLTypes["AddMailTemplateError"] | undefined
};
	["AddMailTemplateError"]: {
		code: GraphQLTypes["AddMailTemplateErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["AddMailTemplateErrorCode"]: GraphQLTypes["AddMailTemplateErrorCode"];
	["RemoveMailTemplateResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["RemoveMailTemplateError"]>,
	error?: GraphQLTypes["RemoveMailTemplateError"] | undefined
};
	["RemoveMailTemplateError"]: {
		code: GraphQLTypes["RemoveMailTemplateErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["RemoveMailTemplateErrorCode"]: GraphQLTypes["RemoveMailTemplateErrorCode"];
	["CheckResetPasswordTokenResult"]: {
		code: GraphQLTypes["CheckResetPasswordTokenCode"]
};
	["CheckResetPasswordTokenCode"]: GraphQLTypes["CheckResetPasswordTokenCode"];
	["CreatePasswordResetRequestResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["CreatePasswordResetRequestError"]>,
	error?: GraphQLTypes["CreatePasswordResetRequestError"] | undefined
};
	["CreatePasswordResetRequestError"]: {
		code: GraphQLTypes["CreatePasswordResetRequestErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["CreatePasswordResetRequestErrorCode"]: GraphQLTypes["CreatePasswordResetRequestErrorCode"];
	["ResetPasswordResponse"]: {
		ok: boolean,
	errors: Array<GraphQLTypes["ResetPasswordError"]>,
	error?: GraphQLTypes["ResetPasswordError"] | undefined
};
	["ResetPasswordError"]: {
		code: GraphQLTypes["ResetPasswordErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["ResetPasswordErrorCode"]: GraphQLTypes["ResetPasswordErrorCode"];
	["CreateResetPasswordRequestOptions"]: GraphQLTypes["CreateResetPasswordRequestOptions"];
	["ProjectSecret"]: GraphQLTypes["ProjectSecret"];
	["CreateProjectOptions"]: GraphQLTypes["CreateProjectOptions"];
	["CreateProjectResponse"]: {
		ok: boolean,
	error?: GraphQLTypes["CreateProjectResponseError"] | undefined,
	result?: GraphQLTypes["CreateProjectResult"] | undefined
};
	["CreateProjectResponseError"]: {
		code: GraphQLTypes["CreateProjectResponseErrorCode"],
	developerMessage: string
};
	["CreateProjectResult"]: {
		deployerApiKey?: GraphQLTypes["ApiKeyWithToken"] | undefined
};
	["CreateProjectResponseErrorCode"]: GraphQLTypes["CreateProjectResponseErrorCode"];
	["SetProjectSecretResponse"]: {
		ok: boolean
};
	["UpdateProjectResponse"]: {
		ok: boolean
}
    }

export type GraphQLTypes = {
    /** Json custom scalar type */
["Json"]: any;
	/** DateTime custom scalar type */
["DateTime"]: string;
	["Query"]: {
	__typename: "Query",
	me: GraphQLTypes["Identity"],
	projects: Array<GraphQLTypes["Project"]>,
	projectBySlug?: GraphQLTypes["Project"] | undefined,
	projectMemberships: Array<GraphQLTypes["Membership"]>,
	checkResetPasswordToken: GraphQLTypes["CheckResetPasswordTokenCode"],
	identityProviders: Array<GraphQLTypes["IdentityProvider"]>
};
	["Mutation"]: {
	__typename: "Mutation",
	signUp?: GraphQLTypes["SignUpResponse"] | undefined,
	signIn?: GraphQLTypes["SignInResponse"] | undefined,
	createSessionToken?: GraphQLTypes["CreateSessionTokenResponse"] | undefined,
	signOut?: GraphQLTypes["SignOutResponse"] | undefined,
	changePassword?: GraphQLTypes["ChangePasswordResponse"] | undefined,
	changeMyPassword?: GraphQLTypes["ChangeMyPasswordResponse"] | undefined,
	initSignInIDP?: GraphQLTypes["InitSignInIDPResponse"] | undefined,
	signInIDP?: GraphQLTypes["SignInIDPResponse"] | undefined,
	addIDP?: GraphQLTypes["AddIDPResponse"] | undefined,
	updateIDP?: GraphQLTypes["UpdateIDPResponse"] | undefined,
	disableIDP?: GraphQLTypes["DisableIDPResponse"] | undefined,
	enableIDP?: GraphQLTypes["EnableIDPResponse"] | undefined,
	prepareOtp?: GraphQLTypes["PrepareOtpResponse"] | undefined,
	confirmOtp?: GraphQLTypes["ConfirmOtpResponse"] | undefined,
	disableOtp?: GraphQLTypes["DisableOtpResponse"] | undefined,
	createResetPasswordRequest?: GraphQLTypes["CreatePasswordResetRequestResponse"] | undefined,
	resetPassword?: GraphQLTypes["ResetPasswordResponse"] | undefined,
	invite?: GraphQLTypes["InviteResponse"] | undefined,
	unmanagedInvite?: GraphQLTypes["InviteResponse"] | undefined,
	addProjectMember?: GraphQLTypes["AddProjectMemberResponse"] | undefined,
	removeProjectMember?: GraphQLTypes["RemoveProjectMemberResponse"] | undefined,
	updateProjectMember?: GraphQLTypes["UpdateProjectMemberResponse"] | undefined,
	createApiKey?: GraphQLTypes["CreateApiKeyResponse"] | undefined,
	createGlobalApiKey?: GraphQLTypes["CreateApiKeyResponse"] | undefined,
	disableApiKey?: GraphQLTypes["DisableApiKeyResponse"] | undefined,
	addMailTemplate?: GraphQLTypes["AddMailTemplateResponse"] | undefined,
	removeMailTemplate?: GraphQLTypes["RemoveMailTemplateResponse"] | undefined,
	createProject?: GraphQLTypes["CreateProjectResponse"] | undefined,
	setProjectSecret?: GraphQLTypes["SetProjectSecretResponse"] | undefined,
	updateProject?: GraphQLTypes["UpdateProjectResponse"] | undefined,
	addProjectMailTemplate?: GraphQLTypes["AddMailTemplateResponse"] | undefined,
	removeProjectMailTemplate?: GraphQLTypes["RemoveMailTemplateResponse"] | undefined
};
	["SignUpResponse"]: {
	__typename: "SignUpResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["SignUpError"]>,
	error?: GraphQLTypes["SignUpError"] | undefined,
	result?: GraphQLTypes["SignUpResult"] | undefined
};
	["SignUpError"]: {
	__typename: "SignUpError",
	code: GraphQLTypes["SignUpErrorCode"],
	developerMessage: string,
	endPersonMessage?: string | undefined
};
	["SignUpErrorCode"]: SignUpErrorCode;
	["SignUpResult"]: {
	__typename: "SignUpResult",
	person: GraphQLTypes["Person"]
};
	["CommonSignInResult"]: {
	__typename:"SignInResult" | "CreateSessionTokenResult" | "SignInIDPResult",
	token: string,
	person: GraphQLTypes["Person"]
	['...on SignInResult']: '__union' & GraphQLTypes["SignInResult"];
	['...on CreateSessionTokenResult']: '__union' & GraphQLTypes["CreateSessionTokenResult"];
	['...on SignInIDPResult']: '__union' & GraphQLTypes["SignInIDPResult"];
};
	["SignInResponse"]: {
	__typename: "SignInResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["SignInError"]>,
	error?: GraphQLTypes["SignInError"] | undefined,
	result?: GraphQLTypes["SignInResult"] | undefined
};
	["SignInError"]: {
	__typename: "SignInError",
	code: GraphQLTypes["SignInErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["SignInErrorCode"]: SignInErrorCode;
	["SignInResult"]: {
	__typename: "SignInResult",
	token: string,
	person: GraphQLTypes["Person"]
};
	["CreateSessionTokenResponse"]: {
	__typename: "CreateSessionTokenResponse",
	ok: boolean,
	error?: GraphQLTypes["CreateSessionTokenError"] | undefined,
	result?: GraphQLTypes["CreateSessionTokenResult"] | undefined
};
	["CreateSessionTokenError"]: {
	__typename: "CreateSessionTokenError",
	code: GraphQLTypes["CreateSessionTokenErrorCode"],
	developerMessage: string
};
	["CreateSessionTokenErrorCode"]: CreateSessionTokenErrorCode;
	["CreateSessionTokenResult"]: {
	__typename: "CreateSessionTokenResult",
	token: string,
	person: GraphQLTypes["Person"]
};
	["SignOutResponse"]: {
	__typename: "SignOutResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["SignOutError"]>,
	error?: GraphQLTypes["SignOutError"] | undefined
};
	["SignOutError"]: {
	__typename: "SignOutError",
	code: GraphQLTypes["SignOutErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["SignOutErrorCode"]: SignOutErrorCode;
	["ChangePasswordResponse"]: {
	__typename: "ChangePasswordResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["ChangePasswordError"]>,
	error?: GraphQLTypes["ChangePasswordError"] | undefined
};
	["ChangePasswordError"]: {
	__typename: "ChangePasswordError",
	code: GraphQLTypes["ChangePasswordErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["ChangePasswordErrorCode"]: ChangePasswordErrorCode;
	["ChangeMyPasswordResponse"]: {
	__typename: "ChangeMyPasswordResponse",
	ok: boolean,
	error?: GraphQLTypes["ChangeMyPasswordError"] | undefined
};
	["ChangeMyPasswordError"]: {
	__typename: "ChangeMyPasswordError",
	code: GraphQLTypes["ChangeMyPasswordErrorCode"],
	developerMessage: string
};
	["ChangeMyPasswordErrorCode"]: ChangeMyPasswordErrorCode;
	["InitSignInIDPResponse"]: {
	__typename: "InitSignInIDPResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["InitSignInIDPError"]>,
	error?: GraphQLTypes["InitSignInIDPError"] | undefined,
	result?: GraphQLTypes["InitSignInIDPResult"] | undefined
};
	["InitSignInIDPResult"]: {
	__typename: "InitSignInIDPResult",
	authUrl: string,
	sessionData: GraphQLTypes["Json"]
};
	["InitSignInIDPError"]: {
	__typename: "InitSignInIDPError",
	code: GraphQLTypes["InitSignInIDPErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["InitSignInIDPErrorCode"]: InitSignInIDPErrorCode;
	["IDPResponseInput"]: {
		url: string
};
	["SignInIDPResponse"]: {
	__typename: "SignInIDPResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["SignInIDPError"]>,
	error?: GraphQLTypes["SignInIDPError"] | undefined,
	result?: GraphQLTypes["SignInIDPResult"] | undefined
};
	["SignInIDPError"]: {
	__typename: "SignInIDPError",
	code: GraphQLTypes["SignInIDPErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["SignInIDPErrorCode"]: SignInIDPErrorCode;
	["SignInIDPResult"]: {
	__typename: "SignInIDPResult",
	token: string,
	person: GraphQLTypes["Person"]
};
	["AddIDPResponse"]: {
	__typename: "AddIDPResponse",
	error?: GraphQLTypes["AddIDPError"] | undefined,
	ok: boolean
};
	["AddIDPError"]: {
	__typename: "AddIDPError",
	code: GraphQLTypes["AddIDPErrorCode"],
	developerMessage: string
};
	["AddIDPErrorCode"]: AddIDPErrorCode;
	["UpdateIDPResponse"]: {
	__typename: "UpdateIDPResponse",
	error?: GraphQLTypes["UpdateIDPError"] | undefined,
	ok: boolean
};
	["UpdateIDPError"]: {
	__typename: "UpdateIDPError",
	code: GraphQLTypes["UpdateIDPErrorCode"],
	developerMessage: string
};
	["UpdateIDPErrorCode"]: UpdateIDPErrorCode;
	["DisableIDPResponse"]: {
	__typename: "DisableIDPResponse",
	error?: GraphQLTypes["DisableIDPError"] | undefined,
	ok: boolean
};
	["DisableIDPError"]: {
	__typename: "DisableIDPError",
	code: GraphQLTypes["DisableIDPErrorCode"],
	developerMessage: string
};
	["DisableIDPErrorCode"]: DisableIDPErrorCode;
	["EnableIDPResponse"]: {
	__typename: "EnableIDPResponse",
	error?: GraphQLTypes["EnableIDPError"] | undefined,
	ok: boolean
};
	["EnableIDPError"]: {
	__typename: "EnableIDPError",
	code: GraphQLTypes["EnableIDPErrorCode"],
	developerMessage: string
};
	["EnableIDPErrorCode"]: EnableIDPErrorCode;
	["IdentityProvider"]: {
	__typename: "IdentityProvider",
	slug: string,
	type: string,
	configuration: GraphQLTypes["Json"],
	disabledAt: GraphQLTypes["DateTime"],
	options: GraphQLTypes["IDPOptionsOutput"]
};
	["IDPOptionsOutput"]: {
	__typename: "IDPOptionsOutput",
	autoSignUp: boolean,
	exclusive: boolean
};
	["IDPOptions"]: {
		autoSignUp?: boolean | undefined,
	exclusive?: boolean | undefined
};
	["InviteResponse"]: {
	__typename: "InviteResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["InviteError"]>,
	error?: GraphQLTypes["InviteError"] | undefined,
	result?: GraphQLTypes["InviteResult"] | undefined
};
	["InviteError"]: {
	__typename: "InviteError",
	code: GraphQLTypes["InviteErrorCode"],
	developerMessage: string,
	membershipValidation?: Array<GraphQLTypes["MembershipValidationError"]> | undefined,
	endUserMessage?: string | undefined
};
	["InviteErrorCode"]: InviteErrorCode;
	["InviteResult"]: {
	__typename: "InviteResult",
	person: GraphQLTypes["Person"],
	isNew: boolean
};
	["InviteMethod"]: InviteMethod;
	["InviteOptions"]: {
		method?: GraphQLTypes["InviteMethod"] | undefined,
	mailVariant?: string | undefined
};
	["UnmanagedInviteOptions"]: {
		password?: string | undefined,
	resetTokenHash?: string | undefined
};
	["AddProjectMemberResponse"]: {
	__typename: "AddProjectMemberResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["AddProjectMemberError"]>,
	error?: GraphQLTypes["AddProjectMemberError"] | undefined
};
	["AddProjectMemberError"]: {
	__typename: "AddProjectMemberError",
	code: GraphQLTypes["AddProjectMemberErrorCode"],
	membershipValidation?: Array<GraphQLTypes["MembershipValidationError"]> | undefined,
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["AddProjectMemberErrorCode"]: AddProjectMemberErrorCode;
	["UpdateProjectMemberResponse"]: {
	__typename: "UpdateProjectMemberResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["UpdateProjectMemberError"]>,
	error?: GraphQLTypes["UpdateProjectMemberError"] | undefined
};
	["UpdateProjectMemberError"]: {
	__typename: "UpdateProjectMemberError",
	code: GraphQLTypes["UpdateProjectMemberErrorCode"],
	developerMessage: string,
	membershipValidation?: Array<GraphQLTypes["MembershipValidationError"]> | undefined,
	endUserMessage?: string | undefined
};
	["UpdateProjectMemberErrorCode"]: UpdateProjectMemberErrorCode;
	["RemoveProjectMemberResponse"]: {
	__typename: "RemoveProjectMemberResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["RemoveProjectMemberError"]>,
	error?: GraphQLTypes["RemoveProjectMemberError"] | undefined
};
	["RemoveProjectMemberError"]: {
	__typename: "RemoveProjectMemberError",
	code: GraphQLTypes["RemoveProjectMemberErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["RemoveProjectMemberErrorCode"]: RemoveProjectMemberErrorCode;
	["CreateApiKeyResponse"]: {
	__typename: "CreateApiKeyResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["CreateApiKeyError"]>,
	error?: GraphQLTypes["CreateApiKeyError"] | undefined,
	result?: GraphQLTypes["CreateApiKeyResult"] | undefined
};
	["CreateApiKeyError"]: {
	__typename: "CreateApiKeyError",
	code: GraphQLTypes["CreateApiKeyErrorCode"],
	developerMessage: string,
	membershipValidation?: Array<GraphQLTypes["MembershipValidationError"]> | undefined,
	endUserMessage?: string | undefined
};
	["CreateApiKeyErrorCode"]: CreateApiKeyErrorCode;
	["CreateApiKeyResult"]: {
	__typename: "CreateApiKeyResult",
	apiKey: GraphQLTypes["ApiKeyWithToken"]
};
	["DisableApiKeyResponse"]: {
	__typename: "DisableApiKeyResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["DisableApiKeyError"]>,
	error?: GraphQLTypes["DisableApiKeyError"] | undefined
};
	["DisableApiKeyError"]: {
	__typename: "DisableApiKeyError",
	code: GraphQLTypes["DisableApiKeyErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["DisableApiKeyErrorCode"]: DisableApiKeyErrorCode;
	["VariableEntryInput"]: {
		name: string,
	values: Array<string>
};
	["VariableEntry"]: {
	__typename: "VariableEntry",
	name: string,
	values: Array<string>
};
	["MembershipInput"]: {
		role: string,
	variables: Array<GraphQLTypes["VariableEntryInput"]>
};
	["Membership"]: {
	__typename: "Membership",
	role: string,
	variables: Array<GraphQLTypes["VariableEntry"]>
};
	["MembershipValidationError"]: {
	__typename: "MembershipValidationError",
	code: GraphQLTypes["MembershipValidationErrorCode"],
	role: string,
	variable?: string | undefined
};
	["MembershipValidationErrorCode"]: MembershipValidationErrorCode;
	["Person"]: {
	__typename: "Person",
	id: string,
	email?: string | undefined,
	name?: string | undefined,
	otpEnabled: boolean,
	identity: GraphQLTypes["Identity"]
};
	["ApiKey"]: {
	__typename: "ApiKey",
	id: string,
	identity: GraphQLTypes["Identity"]
};
	["ApiKeyWithToken"]: {
	__typename: "ApiKeyWithToken",
	id: string,
	token?: string | undefined,
	identity: GraphQLTypes["Identity"]
};
	["Identity"]: {
	__typename: "Identity",
	id: string,
	description?: string | undefined,
	person?: GraphQLTypes["Person"] | undefined,
	apiKey?: GraphQLTypes["ApiKey"] | undefined,
	projects: Array<GraphQLTypes["IdentityProjectRelation"]>,
	permissions?: GraphQLTypes["IdentityGlobalPermissions"] | undefined,
	roles?: Array<string> | undefined
};
	["IdentityGlobalPermissions"]: {
	__typename: "IdentityGlobalPermissions",
	canCreateProject: boolean
};
	["IdentityProjectRelation"]: {
	__typename: "IdentityProjectRelation",
	project: GraphQLTypes["Project"],
	memberships: Array<GraphQLTypes["Membership"]>
};
	["Project"]: {
	__typename: "Project",
	id: string,
	name: string,
	slug: string,
	config: GraphQLTypes["Json"],
	roles: Array<GraphQLTypes["RoleDefinition"]>,
	members: Array<GraphQLTypes["ProjectIdentityRelation"]>
};
	["MemberType"]: MemberType;
	["ProjectIdentityRelation"]: {
	__typename: "ProjectIdentityRelation",
	identity: GraphQLTypes["Identity"],
	memberships: Array<GraphQLTypes["Membership"]>
};
	["RoleDefinition"]: {
	__typename: "RoleDefinition",
	name: string,
	variables: Array<GraphQLTypes["RoleVariableDefinition"]>
};
	["RoleVariableDefinition"]: {
	__typename:"RoleEntityVariableDefinition" | "RolePredefinedVariableDefinition" | "RoleConditionVariableDefinition",
	name: string
	['...on RoleEntityVariableDefinition']: '__union' & GraphQLTypes["RoleEntityVariableDefinition"];
	['...on RolePredefinedVariableDefinition']: '__union' & GraphQLTypes["RolePredefinedVariableDefinition"];
	['...on RoleConditionVariableDefinition']: '__union' & GraphQLTypes["RoleConditionVariableDefinition"];
};
	["RoleEntityVariableDefinition"]: {
	__typename: "RoleEntityVariableDefinition",
	name: string,
	entityName: string
};
	["RolePredefinedVariableDefinition"]: {
	__typename: "RolePredefinedVariableDefinition",
	name: string,
	value: string
};
	["RoleConditionVariableDefinition"]: {
	__typename: "RoleConditionVariableDefinition",
	name: string
};
	["PrepareOtpResponse"]: {
	__typename: "PrepareOtpResponse",
	ok: boolean,
	result?: GraphQLTypes["PrepareOtpResult"] | undefined
};
	["PrepareOtpResult"]: {
	__typename: "PrepareOtpResult",
	otpUri: string,
	otpSecret: string
};
	["ConfirmOtpResponse"]: {
	__typename: "ConfirmOtpResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["ConfirmOtpError"]>,
	error?: GraphQLTypes["ConfirmOtpError"] | undefined
};
	["ConfirmOtpError"]: {
	__typename: "ConfirmOtpError",
	code: GraphQLTypes["ConfirmOtpErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["ConfirmOtpErrorCode"]: ConfirmOtpErrorCode;
	["DisableOtpResponse"]: {
	__typename: "DisableOtpResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["DisableOtpError"]>,
	error?: GraphQLTypes["DisableOtpError"] | undefined
};
	["DisableOtpError"]: {
	__typename: "DisableOtpError",
	code: GraphQLTypes["DisableOtpErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["DisableOtpErrorCode"]: DisableOtpErrorCode;
	["MailTemplate"]: {
		projectSlug?: string | undefined,
	type: GraphQLTypes["MailType"],
	/** Custom mail variant identifier, e.g. a locale. */
	variant?: string | undefined,
	subject: string,
	content: string,
	useLayout?: boolean | undefined
};
	["MailType"]: MailType;
	["MailTemplateIdentifier"]: {
		projectSlug?: string | undefined,
	type: GraphQLTypes["MailType"],
	variant?: string | undefined
};
	["AddMailTemplateResponse"]: {
	__typename: "AddMailTemplateResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["AddMailTemplateError"]>,
	error?: GraphQLTypes["AddMailTemplateError"] | undefined
};
	["AddMailTemplateError"]: {
	__typename: "AddMailTemplateError",
	code: GraphQLTypes["AddMailTemplateErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["AddMailTemplateErrorCode"]: AddMailTemplateErrorCode;
	["RemoveMailTemplateResponse"]: {
	__typename: "RemoveMailTemplateResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["RemoveMailTemplateError"]>,
	error?: GraphQLTypes["RemoveMailTemplateError"] | undefined
};
	["RemoveMailTemplateError"]: {
	__typename: "RemoveMailTemplateError",
	code: GraphQLTypes["RemoveMailTemplateErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["RemoveMailTemplateErrorCode"]: RemoveMailTemplateErrorCode;
	["CheckResetPasswordTokenResult"]: {
	__typename: "CheckResetPasswordTokenResult",
	code: GraphQLTypes["CheckResetPasswordTokenCode"]
};
	["CheckResetPasswordTokenCode"]: CheckResetPasswordTokenCode;
	["CreatePasswordResetRequestResponse"]: {
	__typename: "CreatePasswordResetRequestResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["CreatePasswordResetRequestError"]>,
	error?: GraphQLTypes["CreatePasswordResetRequestError"] | undefined
};
	["CreatePasswordResetRequestError"]: {
	__typename: "CreatePasswordResetRequestError",
	code: GraphQLTypes["CreatePasswordResetRequestErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["CreatePasswordResetRequestErrorCode"]: CreatePasswordResetRequestErrorCode;
	["ResetPasswordResponse"]: {
	__typename: "ResetPasswordResponse",
	ok: boolean,
	errors: Array<GraphQLTypes["ResetPasswordError"]>,
	error?: GraphQLTypes["ResetPasswordError"] | undefined
};
	["ResetPasswordError"]: {
	__typename: "ResetPasswordError",
	code: GraphQLTypes["ResetPasswordErrorCode"],
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["ResetPasswordErrorCode"]: ResetPasswordErrorCode;
	["CreateResetPasswordRequestOptions"]: {
		mailProject?: string | undefined,
	mailVariant?: string | undefined
};
	["ProjectSecret"]: {
		key: string,
	value: string
};
	["CreateProjectOptions"]: {
		deployTokenHash?: string | undefined,
	noDeployToken?: boolean | undefined
};
	["CreateProjectResponse"]: {
	__typename: "CreateProjectResponse",
	ok: boolean,
	error?: GraphQLTypes["CreateProjectResponseError"] | undefined,
	result?: GraphQLTypes["CreateProjectResult"] | undefined
};
	["CreateProjectResponseError"]: {
	__typename: "CreateProjectResponseError",
	code: GraphQLTypes["CreateProjectResponseErrorCode"],
	developerMessage: string
};
	["CreateProjectResult"]: {
	__typename: "CreateProjectResult",
	deployerApiKey?: GraphQLTypes["ApiKeyWithToken"] | undefined
};
	["CreateProjectResponseErrorCode"]: CreateProjectResponseErrorCode;
	["SetProjectSecretResponse"]: {
	__typename: "SetProjectSecretResponse",
	ok: boolean
};
	["UpdateProjectResponse"]: {
	__typename: "UpdateProjectResponse",
	ok: boolean
}
    }
export type SignUpErrorCode = "EMAIL_ALREADY_EXISTS" | "TOO_WEAK";

export type SignInErrorCode = "UNKNOWN_EMAIL" | "INVALID_PASSWORD" | "NO_PASSWORD_SET" | "OTP_REQUIRED" | "INVALID_OTP_TOKEN";

export type CreateSessionTokenErrorCode = "UNKNOWN_EMAIL" | "UNKNOWN_PERSON_ID";

export type SignOutErrorCode = "NOT_A_PERSON";

export type ChangePasswordErrorCode = "PERSON_NOT_FOUND" | "TOO_WEAK";

export type ChangeMyPasswordErrorCode = "TOO_WEAK" | "NOT_A_PERSON" | "INVALID_PASSWORD" | "NO_PASSWORD_SET";

export type InitSignInIDPErrorCode = "PROVIDER_NOT_FOUND";

export type SignInIDPErrorCode = "INVALID_IDP_RESPONSE" | "IDP_VALIDATION_FAILED" | "PERSON_NOT_FOUND" | "PERSON_ALREADY_EXISTS";

export type AddIDPErrorCode = "ALREADY_EXISTS" | "UNKNOWN_TYPE" | "INVALID_CONFIGURATION";

export type UpdateIDPErrorCode = "NOT_FOUND" | "INVALID_CONFIGURATION";

export type DisableIDPErrorCode = "NOT_FOUND";

export type EnableIDPErrorCode = "NOT_FOUND";

export type InviteErrorCode = "PROJECT_NOT_FOUND" | "ALREADY_MEMBER" | "INVALID_MEMBERSHIP" | "ROLE_NOT_FOUND" | "VARIABLE_NOT_FOUND" | "VARIABLE_EMPTY";

export type InviteMethod = "CREATE_PASSWORD" | "RESET_PASSWORD";

export type AddProjectMemberErrorCode = "PROJECT_NOT_FOUND" | "IDENTITY_NOT_FOUND" | "ALREADY_MEMBER" | "INVALID_MEMBERSHIP" | "ROLE_NOT_FOUND" | "VARIABLE_EMPTY" | "VARIABLE_NOT_FOUND";

export type UpdateProjectMemberErrorCode = "PROJECT_NOT_FOUND" | "NOT_MEMBER" | "INVALID_MEMBERSHIP" | "ROLE_NOT_FOUND" | "VARIABLE_EMPTY" | "VARIABLE_NOT_FOUND";

export type RemoveProjectMemberErrorCode = "NOT_MEMBER" | "PROJECT_NOT_FOUND";

export type CreateApiKeyErrorCode = "PROJECT_NOT_FOUND" | "INVALID_MEMBERSHIP" | "VARIABLE_NOT_FOUND" | "ROLE_NOT_FOUND" | "VARIABLE_EMPTY";

export type DisableApiKeyErrorCode = "KEY_NOT_FOUND";

export type MembershipValidationErrorCode = "ROLE_NOT_FOUND" | "VARIABLE_NOT_FOUND" | "VARIABLE_EMPTY" | "VARIABLE_INVALID";

export type MemberType = "API_KEY" | "PERSON";

export type ConfirmOtpErrorCode = "INVALID_OTP_TOKEN" | "NOT_PREPARED";

export type DisableOtpErrorCode = "OTP_NOT_ACTIVE";

export type MailType = "EXISTING_USER_INVITED" | "NEW_USER_INVITED" | "RESET_PASSWORD_REQUEST";

export type AddMailTemplateErrorCode = "MISSING_VARIABLE" | "PROJECT_NOT_FOUND";

export type RemoveMailTemplateErrorCode = "PROJECT_NOT_FOUND" | "TEMPLATE_NOT_FOUND";

export type CheckResetPasswordTokenCode = "REQUEST_NOT_FOUND" | "TOKEN_NOT_FOUND" | "TOKEN_USED" | "TOKEN_EXPIRED";

export type CreatePasswordResetRequestErrorCode = "PERSON_NOT_FOUND";

export type ResetPasswordErrorCode = "TOKEN_NOT_FOUND" | "TOKEN_USED" | "TOKEN_EXPIRED" | "PASSWORD_TOO_WEAK";

export type CreateProjectResponseErrorCode = "ALREADY_EXISTS" | "INIT_ERROR";

export const createZeusVariables = useZeusVariables

export type FragmentResult<K extends keyof GraphQLTypes, Q extends unknown | ((...args: any[]) => unknown)> = InputType<
	GraphQLTypes[K],
	Q extends (...args: any[]) => unknown ? ReturnType<Q> : Q
>