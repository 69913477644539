import { FormattedCents } from '@goodlok/ui'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import React from 'react'
import { AddToCartButton, Button, CartResult, useGoodlokShopping, Warning } from '..'
import style from './CheckoutLines.module.sass'

export const CheckoutLines: FunctionComponent<{
	items: CartResult['items']
	deliveryPriceCents?: number
	totalPriceCents?: number
}> = ({ items, deliveryPriceCents, totalPriceCents }) => {
	const preferDecimals = items?.some(item => item.priceCents % 100 || item.unitPriceCents % 100)
	const shopping = useGoodlokShopping()

	return (
		<table style={{ width: '100%' }}>
			<tbody>
				{items?.map(item => (
					<React.Fragment key={item.itemId}>
						<tr className={style.line}>
							<td style={{ width: '3em' }} className={style.image}>
								<div style={{ width: '3em', height: '3em', position: 'relative' }}>
									{!!item.product?.image?.url && (
										<Image
											alt={item.productName}
											src={item.product?.image.url}
											width={item.product?.image.width}
											height={item.product?.image.height}
											sizes="3em"
											layout="fill"
											objectFit="contain"
										/>
									)}
								</div>
							</td>
							<td style={{ textAlign: 'left' }} className={style.description}>
								<div className={style.parts}>
									<div className={clsx(style.part, style.productName)}>{item.productName}</div>
									<div className={clsx(style.part, style.price)}>
										{item.quantity > 1 && <>{item.quantity} &times; </>}
										<FormattedCents cents={item.unitPriceCents} preferDecimals={preferDecimals} />
									</div>
								</div>
							</td>
							<td className={style.quantity}>
								<AddToCartButton productId={item.productId} />
							</td>
						</tr>
						{item.availableQuantity < item.quantity && (
							<tr>
								<td colSpan={3}>
									<div className={style.warning}>
										{item.availableQuantity === 0 ? (
											<Warning
												text="Produkt není dostupný."
												actions={
													<Button
														type="button"
														variant="dark"
														outline
														round
														size="small"
														uppercase={false}
														onClick={() => {
															shopping.setItemQuantity({ productId: item.productId, quantity: item.availableQuantity })
														}}
													>
														OK. Odebrat
													</Button>
												}
											/>
										) : (
											<Warning
												text={`Dostupné jsou jen ${item.availableQuantity}.`}
												actions={
													<Button
														type="button"
														variant="dark"
														outline
														round
														size="small"
														uppercase={false}
														onClick={() => {
															shopping.setItemQuantity({ productId: item.productId, quantity: item.availableQuantity })
														}}
													>
														OK. Snížit na {item.availableQuantity}
													</Button>
												}
											/>
										)}
									</div>
								</td>
							</tr>
						)}
					</React.Fragment>
				))}
				{!!deliveryPriceCents && (
					<tr>
						<td></td>
						<th style={{ textAlign: 'left' }}>Doprava</th>
						<td style={{ textAlign: 'right' }}>
							<FormattedCents cents={deliveryPriceCents} preferDecimals={preferDecimals} />
						</td>
						<td></td>
					</tr>
				)}
			</tbody>
			{totalPriceCents && (
				<tfoot>
					<tr>
						<td></td>
						<th style={{ textAlign: 'left' }}>Celkem</th>
						<td style={{ textAlign: 'right' }}>
							<strong>
								{' '}
								<FormattedCents cents={totalPriceCents} preferDecimals={preferDecimals} />
							</strong>
						</td>
						<td style={{ textAlign: 'right' }}></td>
					</tr>
				</tfoot>
			)}
		</table>
	)
}
