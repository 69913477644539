import { useQuery } from '@tanstack/react-query'
import { useGoodlokAuth } from '../auth'

export function useProductInfo(productId: string) {
	const g = useGoodlokAuth()

	const info = useQuery(['productInfo', productId] as const, async context => {
		return g.dataLoaders.productInfo.load(context.queryKey[1])
	})

	return info
}
