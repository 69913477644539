import type { FunctionComponent, ReactNode } from 'react'
import styles from './CardColumns.module.sass'

export type CardColumnsProps = {
	columns: {
		id: string
		title: string
		content: ReactNode
	}[]
}

export const CardColumns: FunctionComponent<CardColumnsProps> = ({ columns }) => {
	return (
		<div className={styles.wrapper}>
			{columns.map(column => (
				<div key={column.id}>
					<h3 className={styles.title}>{column.title}</h3>
					{column.content}
				</div>
			))}
		</div>
	)
}
