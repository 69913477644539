import { forwardRef } from 'react'
import ReactSelect, { MultiValue } from 'react-select'
import styles from './MultiSelect.module.sass'

type Option = {
	readonly value: string
	readonly label: string
}

type MultiSelectProps = {
	name?: string
	options: Option[]
	value: Array<string>
	placeholder?: string
	onChange: (value: MultiValue<Option>) => void
}

export const MultiSelect = forwardRef<HTMLLabelElement, MultiSelectProps>(
	({ name, options, value, placeholder, onChange }, ref) => {
		return (
			<label ref={ref} className={styles.wrapper}>
				<ReactSelect
					name={name}
					options={options}
					value={options.filter(option => value.includes(option.value))}
					onChange={newValue => onChange(newValue)}
					isMulti
					classNamePrefix="MultiSelect"
					noOptionsMessage={() => 'Žádné možnosti'}
					placeholder={placeholder || 'Vyberte…'}
				/>
			</label>
		)
	},
)
MultiSelect.displayName = 'MultiSelect'
