import { useEffect, useState } from 'react'
import { z } from 'zod'
import { PaymentService } from './const'

export const GoodlokAppSupport = z.object({
	serviceAuth: z
		.array(z.enum(['facebook', 'appleid']))
		.optional()
		.nullable(),
	paymentService: z.array(PaymentService).optional().nullable(),
})
export type GoodlokAppSupport = z.infer<typeof GoodlokAppSupport>

export function useGoodlokAppSupport() {
	const [support, setSupport] = useState<null | GoodlokAppSupport>(null)
	useEffect(() => {
		const cookies = (document.cookie ?? '')
			.split(';')
			.map(v => v.split('='))
			.reduce(
				(acc, v) => {
					acc[decodeURIComponent((v[0] ?? '').trim())] = decodeURIComponent((v[1] ?? '').trim())
					return acc
				},
				{} as Record<string, string>,
			)

		const serialized = cookies['GOODLOK_APP_SUPPORT']
		if (serialized) {
			try {
				const data = JSON.parse(serialized)
				const valid = GoodlokAppSupport.parse(data)
				setSupport(valid)
			} catch (e) {
				console.error('ignoring', e)
			}
		}
	}, [])

	return support
}
