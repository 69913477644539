import Image from 'next/image'
import React, { useState } from 'react'
import { ImageListResult } from '../fragments/ImageListFragment'
import { Icon } from '../icons/Icon'
import style from './CategoryTabs.module.sass'

export type CategoryTabsProps = {
	items: Array<{
		images?: ImageListResult
		label: string
		content: React.ReactNode
	}>
}

export const CategoryTabs: React.FunctionComponent<CategoryTabsProps> = ({ items }) => {
	const [activeItemIndex, setActiveItemIndex] = useState(0)

	return (
		<div className={style.wrapper}>
			<CategoryTabsHeader items={items} activeItemIndex={activeItemIndex} setActiveItemIndex={setActiveItemIndex} />
			<CategoryTabsContent items={items} activeItemIndex={activeItemIndex} setActiveItemIndex={setActiveItemIndex} />
		</div>
	)
}

export const CategoryTabsHeader: React.FunctionComponent<
	CategoryTabsProps & { activeItemIndex: number; setActiveItemIndex: (index: number) => void }
> = ({ items, activeItemIndex, setActiveItemIndex }) => {
	return (
		<div className={style.header}>
			{items.map((item, i) => (
				<button
					type="button"
					key={i}
					className={`${style.header_item} ${i === activeItemIndex ? style.is_active : ''}`}
					onClick={() => setActiveItemIndex(i)}
				>
					<div className={style.header_content}>{item.label}</div>
					<div className={style.header_images}>
						{item.images?.items.map(image => (
							<div key={image.id} className={style.header_imageWrapper}>
								{image.image && (
									<Image
										src={image.image?.url}
										alt={image.image?.alt ?? ''}
										width={image.image.width}
										height={image.image.height}
										className={style.header_image}
									/>
								)}
							</div>
						))}
					</div>
					<Icon name="arrowRight" />
				</button>
			))}
		</div>
	)
}

export const CategoryTabsContent: React.FunctionComponent<
	CategoryTabsProps & { activeItemIndex: number; setActiveItemIndex: (index: number) => void }
> = ({ items, activeItemIndex, setActiveItemIndex }) => {
	return (
		<>
			{items.map((item, i) => (
				<details
					key={i}
					className={style.content}
					open={i === activeItemIndex}
					onToggle={event => {
						const { target } = event
						if (!(target instanceof HTMLDetailsElement)) {
							return
						}
						event.preventDefault()
						if (target.open) {
							setActiveItemIndex(i)
						}
					}}
				>
					<summary className={style.content_summary}>{item.label}</summary>
					{item.content}
				</details>
			))}
		</>
	)
}
