import { FormattedCents } from '@goodlok/ui'
import type { FunctionComponent } from 'react'
import { useProductInfo } from './useProductInfo'

export type ProductPriceProps = {
	productId: string
	initialPriceCents?: number
}

export const ProductPrice: FunctionComponent<ProductPriceProps> = ({ productId, initialPriceCents }) => {
	const info = useProductInfo(productId)

	const visiblePrice = info.data?.priceCents ?? initialPriceCents ?? null

	return <>{typeof visiblePrice === 'number' ? <FormattedCents cents={visiblePrice} /> : <span>?</span>}</>
}
