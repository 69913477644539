import clsx from 'clsx'
import { Fragment, FunctionComponent, ReactNode, useMemo } from 'react'
import { Button as AriaButton, Separator } from 'react-aria-components'
import { Icon } from '../icons/Icon'
import { Button } from './Button'
import { FilterGroup, FilterSectionValue } from './FilterSelect'
import styles from './FilterSelectPanel.module.sass'

export type FilterSelectPanelProps = {
	groups: FilterGroup[]
	value: FilterSectionValue
	activeSectionKey: string | null
	setActiveSectionKey: (key: string | null) => void
	onResetClick: () => void
	button?: ReactNode
}

export const FilterSelectPanel: FunctionComponent<FilterSelectPanelProps> = ({
	groups,
	value,
	activeSectionKey,
	setActiveSectionKey,
	onResetClick,
	button,
}) => {
	const selectedItemsLength = useMemo(() => Object.values(value).flatMap(section => section).length, [value])

	return (
		<div>
			<div className={styles.button}>{button}</div>
			{groups.map((group, index) => (
				<Fragment key={index}>
					<div className={styles.section}>
						<div className={styles.title}>{group.title}</div>
						{group.sections.map(section => (
							<AriaButton
								key={section.key}
								className={clsx(styles.sectionButton, activeSectionKey === section.key && styles.is_active)}
								onPressChange={() => setActiveSectionKey(section.key)}
								id={section.key}
							>
								<div className={styles.sectionTitle}>
									<div className={styles.sectionTitleIcon}>
										<Icon name={section.icon} />
									</div>
									{section.title}
									<div className={clsx(styles.badge, (value[section.key]?.length ?? 0) > 0 && styles.is_active)}>
										{value[section.key]?.length}
									</div>
								</div>
								<div className={styles.sectionArrow}>
									<Icon name="arrowDown" />
								</div>
							</AriaButton>
						))}
					</div>
					<Separator className={styles.separator} />
				</Fragment>
			))}
			<div className={styles.resetButtonWrapper}>
				<Button
					type="button"
					variant="seamless"
					uppercase={false}
					onClick={onResetClick}
					disabled={selectedItemsLength === 0}
				>
					<div className={styles.resetButton}>Zrušit všechny filtry</div>
				</Button>
			</div>
		</div>
	)
}
