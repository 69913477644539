import type { FunctionComponent } from 'react'
import { useGoodlokHasRole } from '../auth'
import { Button } from '../components'
import styles from './AddToCartButton.module.sass'
import { useGoodlokShoppingCartItem } from './useGoodlokShopping'

export const AddToCartButton: FunctionComponent<{ productId: string }> = ({ productId }) => {
	const cartItem = useGoodlokShoppingCartItem(productId)

	const isAdmin = useGoodlokHasRole('admin')

	const isLast = cartItem.current?.quantity === 1
	return (
		<>
			{cartItem.current ? (
				<span className={styles.stepper}>
					<button
						className={styles.stepperButton}
						type="button"
						onClick={() => {
							if (isLast) {
								cartItem.remove()
							} else {
								cartItem.add(-1)
							}
						}}
					>
						<span className={styles.label}>
							<span className={styles.symbol}>-</span>
						</span>
					</button>
					<span
						className={styles.quantity}
						onClick={
							isAdmin
								? () => {
										const quantity = cartItem.current?.quantity ?? 1
										const newQuantityRaw = prompt('Kolik?', String(quantity))
										if (typeof newQuantityRaw === 'string') {
											const newQuantity = Number(newQuantityRaw)
											if (newQuantity >= 0) {
												if (quantity !== newQuantity) {
													cartItem.set(newQuantity)
												}
											}
										}
								  }
								: undefined
						}
					>
						{' '}
						{cartItem.current.quantity}{' '}
					</span>
					<button
						className={styles.stepperButton}
						type="button"
						onClick={() => {
							cartItem.add(1)
						}}
						disabled={cartItem.current.availableQuantity <= cartItem.current.quantity}
					>
						<span className={styles.label}>
							<span className={styles.symbol}>+</span>
						</span>
					</button>
				</span>
			) : (
				<span>
					<Button
						variant="dark"
						round
						outline
						size="small"
						type="button"
						uppercase={false}
						onClick={() => {
							cartItem.add()
						}}
					>
						Do košíku
					</Button>
				</span>
			)}
		</>
	)
}
