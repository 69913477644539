import { useEffect } from 'react'

export const useGlobalKeyDown = (handleEvent: (event: KeyboardEvent) => void, active = false) => {
	useEffect(() => {
		if (!active) {
			return
		}
		const callback = (event: KeyboardEvent) => {
			const isInteractive =
				event.target instanceof HTMLInputElement ||
				event.target instanceof HTMLTextAreaElement ||
				event.target instanceof HTMLButtonElement ||
				event.target instanceof HTMLAnchorElement ||
				event.target instanceof HTMLSelectElement
			if (isInteractive) {
				return
			}
			handleEvent(event)
		}
		window.addEventListener('keydown', callback)
		return () => {
			window.removeEventListener('keydown', callback)
		}
	}, [active, handleEvent])
}
