import Image from 'next/image'
import type { FunctionComponent } from 'react'
import styles from './Avatar.module.sass'

export type AvatarProps = {
	image:
		| {
				url: string
		  }
		| undefined
	alt: string
}

export const Avatar: FunctionComponent<AvatarProps> = ({ image, alt }) => {
	return (
		<div className={styles.wrapper}>
			{image && (
				<Image className={styles.image} src={image.url} alt={alt} fill sizes="64px" /* @TODO: respect parent size */ />
			)}
		</div>
	)
}
