import { FunctionComponent } from 'react'
import { Checkbox } from './Checkbox'
import styles from './InlineTagSelect.module.sass'

export type InlineTagSelectProps = {
	values: string[]
	onChange: (id: string[]) => void
	items: Array<{ id: string; title: string }>
	name: string
}

export const InlineTagSelect: FunctionComponent<InlineTagSelectProps> = ({ items, onChange, values, name }) => {
	return (
		<div className={styles.wrapper}>
			{items.map(item => (
				<Checkbox
					key={item.id}
					label={item.title}
					onChange={checked => {
						if (checked) {
							onChange([...values, item.id])
						} else {
							onChange(values.filter(tag => tag !== item.id))
						}
					}}
					name={name}
					checkedValue={item.id}
					checked={values.includes(item.id)}
					withOutline
				/>
			))}
		</div>
	)
}
