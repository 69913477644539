import clsx from 'clsx'
import { FunctionComponent, ReactNode } from 'react'
import { Ripple } from './Ripple'
import styles from './Tab.module.sass'

export type TabProps = {
	label: string
	icon?: ReactNode
	onClick: () => void
	active: boolean
}

export const Tab: FunctionComponent<TabProps> = ({ label, icon, onClick, active }) => {
	return (
		<button type="button" className={clsx(styles.tab, active && styles.is_active)} onClick={onClick}>
			<Ripple />
			{label}
			{icon && <span className={styles.icon}>{icon}</span>}
		</button>
	)
}
