import clsx from 'clsx'
import { FunctionComponent, ReactNode, useCallback } from 'react'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import styles from './DraggableGrid.module.sass'

export type DraggableGridColumn = {
	id: string
	heading?: ReactNode
	items: Array<{ id: string; content: ReactNode }>
	isSticky?: boolean
}

export type DraggableGridProps = {
	columns: Array<DraggableGridColumn>
	onDragEnd: (result: DropResult) => Promise<void>
	isLoading: boolean
}

export const DraggableGrid: FunctionComponent<DraggableGridProps> = ({ onDragEnd, columns, isLoading }) => {
	const onDragStart = useCallback(() => {
		if (window.navigator.vibrate) {
			window.navigator.vibrate(100)
		}
	}, [])
	return (
		<div className={styles.wrapper}>
			<div className={styles.in}>
				<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
					{columns.map(column => (
						<div key={column.id} className={clsx(styles.column, column.isSticky && styles.is_sticky)}>
							{column.heading}
							<Droppable droppableId={column.id}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
										className={clsx(styles.column_in, snapshot.isDraggingOver && styles.is_draggingOver)}
									>
										{column.items.map((item, i) => (
											<Draggable key={item.id} draggableId={item.id} index={i} isDragDisabled={isLoading}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														/* weird typescript problem with style */
														style={{ ...provided.draggableProps.style }}
														className={clsx(styles.item, isLoading && styles.is_disabled)}
													>
														<div
															className={clsx(
																styles.item_in,
																snapshot.isDragging && !snapshot.isDropAnimating && styles.is_dragging,
															)}
														>
															{item.content}
														</div>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</div>
					))}
				</DragDropContext>
			</div>
		</div>
	)
}
