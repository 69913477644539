import type { GoodlokRole } from '@goodlok/meta'
import { Thunder as AuthThunder } from '@goodlok/sdk/generated/auth'
import { Thunder as RoleCustomerThunder } from '@goodlok/sdk/generated/content_role_customer'
import { Thunder as RoleStaffThunder } from '@goodlok/sdk/generated/content_role_staff'
import { Thunder as OrdersThunder } from '@goodlok/sdk/generated/orders'
import { createZeusVariables, Thunder as TenantThunder } from '@goodlok/sdk/generated/tenant'
import { fromThunder } from '@goodlok/sdk/zeus/fromThunder'
import { useQueryClient } from '@tanstack/react-query'
import {
	createContext,
	FunctionComponent,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { useStorageBackedState } from 'use-storage-backed-state'
import { createDataLoader } from '../misc/createDataLoader'
import { SessionFragment, SessionResult } from './fragments/SessionFragment'
import { SignInFragment, SignInResult } from './fragments/SignInResultFragment'
import { GoodlokAuthIdentity, GoodlokAuthOptions, GoodlokCredentials, WhitelistedRole } from './schema'

export enum GoodlokAuthStatus {
	SignedIn = 'SignedIn',
	SignedOut = 'SignedOut',
	Loading = 'Loading',
}

const localStorageSessionKey = 'session'

export type GoodlokAuthSession =
	| {
			signedIn: null
			status: GoodlokAuthStatus.Loading
	  }
	| { signedIn: false; status: GoodlokAuthStatus.SignedOut }
	| { signedIn: true; status: GoodlokAuthStatus.SignedIn; identity: GoodlokAuthIdentity }

const sessionToIdentity = (session: SignInResult | SessionResult) => {
	return GoodlokAuthIdentity.parse({
		...session,
		roles: session.roles?.filter(role => WhitelistedRole.safeParse(role).success) ?? [],
	})
}

const useLastSessionRef = (session: GoodlokAuthSession) => {
	const [innerSession, setInnerSession] = useStorageBackedState<
		Exclude<GoodlokAuthSession, { status: GoodlokAuthStatus.Loading }>
	>({ status: GoodlokAuthStatus.SignedOut, signedIn: false }, localStorageSessionKey)
	useEffect(() => {
		if (session.status !== GoodlokAuthStatus.Loading) {
			setInnerSession(session)
		}
	}, [session, setInnerSession])
	const lastSessionRef = useRef(innerSession)
	lastSessionRef.current = innerSession

	return lastSessionRef
}

export function useZeusClients(backendUrl: string, token?: string | null, scope?: string | null) {
	return useMemo(
		() => ({
			auth: fromThunder(AuthThunder, { url: `${backendUrl}/auth`, token, scope }),
			roleCustomer: fromThunder(RoleCustomerThunder, { url: `${backendUrl}/content`, token, scope }),
			roleStaff: fromThunder(RoleStaffThunder, { url: `${backendUrl}/content`, token, scope }),
			tenant: fromThunder(TenantThunder, { url: `${backendUrl}/tenant`, token, scope }),
			orders: fromThunder(OrdersThunder, { url: `${backendUrl}/orders`, token, scope }),
		}),
		[backendUrl, token, scope],
	)
}

const storeCredentials = async (username: string, password: string, name?: string, iconUrl?: string) => {
	if ('credentials' in navigator && 'PasswordCredential' in window) {
		navigator.credentials.store(
			new PasswordCredential({
				id: username,
				password,
				name,
				iconUrl,
			}),
		)
	}
}

const preventSilentAccess = async () => {
	localStorage.removeItem(localStorageSessionKey)
	if (navigator.credentials && navigator.credentials.preventSilentAccess) {
		await navigator.credentials.preventSilentAccess().catch(error => {
			console.error(error)
		})
	}
}

function useGoodlokAuthContextRoot(options: GoodlokAuthOptions) {
	const o = useMemo(() => GoodlokAuthOptions.parse(options, { path: ['GoodlokAuthOptions'] }), [options])

	const { backendUrl } = o

	const [refreshCount, setRefreshCount] = useState(0)
	const [cartHandle, setCartHandle] = useLocalStorageState<null | string>('goodlokCartHandle')
	const [session, setSession] = useState<GoodlokAuthSession>({ status: GoodlokAuthStatus.Loading, signedIn: null })

	const refresh = useCallback(() => setRefreshCount(count => count + 1), [])

	// Synchronize auth state between tabs
	useEffect(() => {
		let debounceTimer: ReturnType<typeof setTimeout> // Debounce prevents visibilitychange and focus events triggering refresh at the same time
		const callback = () => {
			if (document.visibilityState === 'visible') {
				clearTimeout(debounceTimer)
				debounceTimer = setTimeout(() => {
					refresh()
				}, 30)
			}
		}
		window.addEventListener('visibilitychange', callback, false)
		window.addEventListener('focus', callback, false)
		window.addEventListener('online', callback, false)

		return () => {
			window.removeEventListener('visibilitychange', callback)
			window.removeEventListener('focus', callback)
			window.removeEventListener('online', callback)
		}
	}, [refresh])

	const zeus = useZeusClients(backendUrl, o.token)

	const actions = useMemo(() => {
		const startSession = async (credentials: { sessionKey: string | null }) => {
			const response = await zeus.auth('mutation')({
				startSession: [{ sessionKey: credentials.sessionKey }, SignInFragment()],
			})
			refresh()
			return response
		}
		const signIn = async (credentials: GoodlokCredentials) => {
			const variables = createZeusVariables({ username: 'String!', password: 'String!' })(credentials)

			const response = await zeus.auth('mutation')(
				{
					signIn: [{ username: variables.$('username'), password: variables.$('password') }, SignInFragment()],
				},
				{ variables },
			)
			if (response.signIn.ok) {
				await storeCredentials(credentials.username, credentials.password)
			}
			refresh()
			return response
		}
		const signInWithToken = async (token: string) => {
			const variables = createZeusVariables({ token: 'String!' })({ token })

			const response = await zeus.auth('mutation')(
				{
					signInWithToken: [{ token: variables.$('token') }, SignInFragment()],
				},
				{ variables },
			)
			refresh()
			return response
		}

		const signInWithCredentialManager = async () => {
			if ('credentials' in navigator && 'PasswordCredential' in window) {
				const credentials = await navigator.credentials.get({
					password: true,
					unmediated: true,
					mediation: 'optional',
				})
				if (credentials && credentials.type === 'password' && credentials.password) {
					const result = await signIn({
						username: credentials.id,
						password: credentials.password,
					})
					if (result.signIn.ok) {
						return result
					}
					await preventSilentAccess()
					return {
						signIn: {
							ok: false,
						},
					}
				}
			}
			return null
		}
		const signUp = async (credentials: GoodlokCredentials) => {
			const variables = createZeusVariables({ username: 'String!', password: 'String!', context: 'String!' })({
				...credentials,
				context: credentials.context ?? o.context ?? 'eshop',
			})
			const response = await zeus.auth('mutation')(
				{
					signUpWithPassword: [
						{ username: variables.$('username'), password: variables.$('password'), context: variables.$('context') },
						{
							ok: true,
							error: {
								code: true,
								developerMessage: true,
								endUserMessage: true,
							},
						},
					],
				},
				{ variables },
			)

			if (response.signUpWithPassword.ok) {
				await storeCredentials(credentials.username, credentials.password)
			}
			refresh()
			return response
		}
		const signOut = async () => {
			await preventSilentAccess()
			const result = await zeus.auth('mutation')({
				signOut: { ok: true },
			})

			setSession({ signedIn: false, status: GoodlokAuthStatus.SignedOut })
			return result
		}
		const session = () =>
			zeus.auth('query')({
				session: SessionFragment(),
			})
		return {
			startSession,
			signIn,
			signInWithToken,
			signInWithCredentialManager,
			signUp,
			signOut,
			session,
			refresh,
		}
	}, [o.context, refresh, zeus])

	const lastSessionRef = useLastSessionRef(session)

	useEffect(() => {
		let aborted = false
		;(async () => {
			try {
				const { session } = await actions.session()
				if (aborted) {
					return
				}
				if (session?.roles?.length) {
					setSession({
						signedIn: true,
						status: GoodlokAuthStatus.SignedIn,
						identity: GoodlokAuthIdentity.parse(sessionToIdentity(session)),
					})
				} else {
					setSession({ signedIn: false, status: GoodlokAuthStatus.SignedOut })
				}
			} catch (error) {
				if (aborted) {
					return
				}
				// Wait for read of lastSessionRef from localStorage
				setTimeout(() => {
					setSession(lastSessionRef.current) // Recover last known session state
				}, 0)
				console.error(error)
			}
		})()
		return () => {
			aborted = true
		}
	}, [actions, lastSessionRef, refreshCount])

	const email = session.signedIn ? session.identity.email : session.signedIn
	const userId = session.signedIn ? session.identity.userId : session.signedIn
	const personId = session.signedIn ? session.identity.personId : session.signedIn
	const customerId = session.signedIn ? session.identity.customerId : session.signedIn
	const staffId = session.signedIn ? session.identity.staffId : session.signedIn

	const roles = session.signedIn ? session.identity.roles : session.signedIn

	const rolesSnapshot = JSON.stringify(roles)

	const queryClient = useQueryClient()

	useEffect(() => {
		queryClient.invalidateQueries()
	}, [userId, customerId, queryClient, rolesSnapshot])

	// @TODO: invalidate dataloaders

	const dataLoaders = {
		productInfo: useMemo(() => {
			return createDataLoader(async keys => {
				if (!customerId) {
					return keys.map(() => null)
				}
				return zeus
					.roleCustomer('query')({
						listProduct: [
							{ filter: { id: { in: [...keys] } } },
							{
								id: true,
								code: true,
								name: true,
								image: [{}, { url: true, width: true, height: true }],
								customerPricesByCustomer: [{ by: { customer: { id: customerId } } }, { priceCents: true }],
								description: true,
								recipe: [
									{},
									{
										color: true,
										description: true,
										ingredients: [
											{ orderBy: [{ order: 'asc' }] },
											{
												ingredient: [
													{},
													{ localesByLocale: [{ by: { locale: { identifier: { code: 'cs' } } } }, { name: true }] },
												],
											},
										],
									},
								],
								packing: [
									{},
									{
										name: true,
										shortName: true,
										volumeMl: true,
									},
								],
							},
						],
					})
					.then(data => {
						return keys.map(key => {
							const found = data.listProduct.find(p => p.id === key)

							if (found) {
								return {
									...found,
									id: found.id,
									code: found.code,
									name: found.name,
									image: found.image,
									priceCents: found.customerPricesByCustomer?.priceCents ?? null,
								}
							}

							return null
						})
					})
			})
		}, [customerId, zeus]),
	}

	const identity = session.signedIn ? session.identity : session.signedIn

	const hasTenantRole = useCallback(
		(role: GoodlokRole) => {
			return roles ? roles.indexOf(role) > -1 : false
		},
		[roles],
	)

	return {
		options: o,
		zeus,
		dataLoaders,
		actions,
		...session,
		email,
		userId,
		personId,
		customerId,
		staffId,
		roles,
		identity,
		hasTenantRole,
		refresh,
		cart: {
			handle: cartHandle,
			setHandle: setCartHandle,
		} as const,
	}
}

export type GoodlokAuthContext = ReturnType<typeof useGoodlokAuthContextRoot>

export const GoodlokAuthContext = createContext<null | GoodlokAuthContext>(null)

export const GoodlokAuthRoot: FunctionComponent<PropsWithChildren<{ options: GoodlokAuthOptions }>> = ({
	options,
	children,
}) => {
	const value = useGoodlokAuthContextRoot(options)

	return <GoodlokAuthContext.Provider value={value}>{children}</GoodlokAuthContext.Provider>
}
