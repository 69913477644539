import { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react'
import { useGoodlokAuth } from './hooks'

export type GoodlokCustomerContext = null | { customerId: string }

export const GoodlokCustomerContext = createContext<GoodlokCustomerContext>(null)

export function useGoodlokCustomerId() {
	const custom = useContext(GoodlokCustomerContext)
	const g = useGoodlokAuth()

	return custom?.customerId ?? g.customerId
}

export const SetGoodlokCustomer: FunctionComponent<PropsWithChildren<{ value: GoodlokCustomerContext }>> = ({
	children,
	value,
}) => {
	return <GoodlokCustomerContext.Provider value={value}>{children}</GoodlokCustomerContext.Provider>
}
