import type { OrderPaymentState } from '@goodlok/sdk/generated/content_role_admin'
import type { FunctionComponent } from 'react'
import styles from './OrderPaymentInfo.module.sass'
import { WalletTypeIcon } from './WalletTypeIcon'

export const orderPaymentStatesWithLabels: Record<OrderPaymentState, string> = {
	created: '',
	approved: '✓',
	failed: '×',
}

export type OrderPaymentInfoProps = {
	payment: {
		state: OrderPaymentState
		invoice?: {
			createdAt: string
			fakturoidId?: string
			publicUrl?: string
			legacyUrl?: string
		}
		method?: {
			name: string
		}
		publicReceiptUrl?: string
		walletType?: string
	}
}

export const OrderPaymentInfo: FunctionComponent<OrderPaymentInfoProps> = ({ payment }) => {
	return (
		<div className={styles.wrapper}>
			<small>
				{orderPaymentStatesWithLabels[payment.state]}
				{payment.invoice ? (
					<a href={payment.invoice.publicUrl ?? payment.invoice.legacyUrl} target="_blank" rel="noreferrer">
						{payment.method?.name}{' '}
						<span style={{ whiteSpace: 'nowrap' }}>
							{String(payment.invoice.publicUrl ?? payment.invoice.legacyUrl)
								.split('/')
								.pop()}
						</span>
					</a>
				) : (
					payment.method?.name
				)}{' '}
				{payment.publicReceiptUrl ? (
					<a href={payment.publicReceiptUrl} target="_blank" rel="noreferrer">
						<WalletTypeIcon walletType={payment.walletType} />
					</a>
				) : (
					<WalletTypeIcon walletType={payment.walletType} />
				)}
			</small>
		</div>
	)
}
