import { FragmentResult, ValueTypes } from '@goodlok/sdk/generated/content_role_admin'
import { BillingSubjectFragment } from './BillingSubjectFragment'
import { BusinessServiceFragment } from './BusinessServiceFragment'
import { ImageFragment } from './ImageFragment'

export const BusinessServiceEventFragment = (locale?: string) =>
	({
		id: true,
		businessService: [{}, BusinessServiceFragment(locale)],
		createdAt: true,
		createdBy: [{}, { id: true, user: [{}, { email: true, name: true }], photo: [{}, ImageFragment()] }],
		fulfilledAt: true,
		filledContact: true,
		filledPhone: true,
		filledEmail: true,
		filledNumberOfPeople: true,
		filledSupervisor: [{}, { id: true, user: [{}, { name: true }] }],
		filledInvoiced: true,
		filledInvoiceIdentifier: true,
		filledEquipment: true,
		filledMillTamDem: true,
		filledSOEspresso: true,
		filledFilterCoffeeInMenu: true,
		filledWaterMeterStatus: true,
		filledGrindingStonesCondition: true,
		filledServiceAction: true,
		filledNote: true,
		businessCustomer: [
			{},
			{
				name: true,
				customer: [
					{},
					{
						id: true,
						meta: [{}, { name: true, fallbackName: true }],
						defaultBillingSubject: [{}, BillingSubjectFragment()],
						business: [{}, { id: true }],
					},
				],
			},
		],
	}) satisfies ValueTypes['BusinessServiceEvent']

export type BusienssServiceResult = FragmentResult<'BusinessServiceEvent', typeof BusinessServiceEventFragment>
