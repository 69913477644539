import { RadioMark } from '@goodlok/ui'
import clsx from 'clsx'
import { FunctionComponent } from 'react'
import styles from './TileSelectOption.module.sass'

export type TileSelectOptionProps = {
	label: string
	name: string
	value: string
	onChange: () => void
	checked?: boolean
}

export const TileSelectOption: FunctionComponent<TileSelectOptionProps> = ({
	label,
	name,
	onChange,
	value,
	checked,
}) => {
	return (
		<label className={clsx(styles.wrapper, checked && styles.is_checked)}>
			<input type="radio" name={name} value={value} onChange={onChange} className={styles.input} checked={checked} />
			<div className={styles.radioMark}>
				<RadioMark />
			</div>
			{label}
		</label>
	)
}
