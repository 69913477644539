import clsx from 'clsx'
import React, { createElement, DetailedHTMLProps, forwardRef, InputHTMLAttributes, ReactNode } from 'react'
import { isDarkHexColor } from '../utilities/isDarkHexColor'
import { ErrorMessage, ErrorMessageProps } from './ErrorMessage'
import style from './Input.module.sass'

export type InputProps = Pick<
	DetailedHTMLProps<
		InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
		HTMLInputElement | HTMLTextAreaElement
	>,
	| 'id'
	| 'type'
	| 'autoComplete'
	| 'autoFocus'
	| 'value'
	| 'checked'
	| 'name'
	| 'onChange'
	| 'onFocus'
	| 'onBlur'
	| 'onWheel'
	| 'disabled'
	| 'required'
	| 'pattern'
	| 'placeholder'
	| 'enterKeyHint'
	| 'readOnly'
	| 'tabIndex'
	| 'min'
	| 'max'
	| 'step'
	| 'minLength'
	| 'maxLength'
	| 'defaultValue'
> & {
	label?: string
	labelPosition?: 'above' | 'after' | 'inside'
	size?: 'normal' | 'compact'
	error?: ErrorMessageProps['error']
	note?: ReactNode
	icon?: ReactNode
	textarea?: boolean
	onIconClick?: () => void
	keepNoteSpace?: boolean
}

export const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>((props, ref) => {
	const {
		id,
		type,
		autoFocus,
		autoComplete,
		enterKeyHint,
		value,
		name,
		disabled,
		onChange,
		onFocus,
		onBlur,
		onWheel,
		label,
		labelPosition = 'above',
		pattern,
		placeholder = labelPosition === 'inside' ? label : undefined,
		size = 'normal',
		error,
		icon,
		onIconClick,
		readOnly,
		tabIndex,
		min,
		max,
		minLength,
		maxLength,
		step,
		textarea,
		note,
		keepNoteSpace,
		defaultValue,
		required,
	} = props

	const isInvalid = Boolean(error)

	const controlElement = createElement(textarea ? 'textarea' : 'input', {
		className: clsx(style.input, !props.value && style.input_empty),
		id,
		name,
		type,
		autoComplete,
		autoFocus,
		enterKeyHint,
		value,
		onChange,
		onFocus,
		onBlur,
		onWheel,
		disabled,
		pattern,
		placeholder,
		defaultValue,
		'aria-invalid': isInvalid,
		ref,
		readOnly,
		tabIndex,
		min,
		max,
		minLength,
		maxLength,
		step,
		required,
	})

	return (
		<label
			className={clsx(
				style.wrapper,
				style[`is_labelPosition_${labelPosition}`],
				style[`is_size_${size}`],
				textarea && style[`view_textarea`],
				isInvalid && style.view_invalid,
			)}
		>
			<div
				className={style.content}
				style={
					props.type === 'color' && typeof props.value === 'string' && props.value.length > 0
						? {
								'--Input-color': props.value,
								'--Input-color-border': isDarkHexColor(props.value) ? props.value : 'var(--global-colors-offWhite-700)',
						  }
						: undefined
				}
			>
				{controlElement}
				{label && <span className={style.label}>{label}</span>}
				{icon && (
					<div
						className={style.icon}
						onClick={() => {
							onIconClick?.()
						}}
					>
						{icon}
					</div>
				)}
			</div>
			{Boolean(error || note) && (
				<div className={clsx(keepNoteSpace && style.keepNoteSpace)}>
					{error && <ErrorMessage error={error} />}
					{note && <div className={style.note}>{note}</div>}
				</div>
			)}
		</label>
	)
})
Input.displayName = 'Input'
