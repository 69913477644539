import { GoodlokRoles } from '@goodlok/meta'
import { z } from 'zod'

export const WhitelistedRole = z.enum(GoodlokRoles)

export const Username = z.string().email()

export const Password = z.string().min(6)

export const GoodlokCredentials = z.object({
	username: Username,
	password: Password,
	context: z.string().nullable().optional(),
})

export const GoodlokAuthOptions = z.object({
	backendUrl: z.string(),
	context: z.string().optional().nullable(),
	token: z.string().optional().nullable(),
})

export const GoodlokAuthIdentity = z.object({
	type: z.string(),
	email: z.string().nullable().optional(),
	userId: z.string().uuid().nullable(),
	customerId: z.string().uuid().nullable(),
	staffId: z.string().uuid().nullable().optional(),
	personId: z.string().uuid().nullable().optional(),
	roles: z.array(WhitelistedRole),
})

export type GoodlokAuthOptions = z.infer<typeof GoodlokAuthOptions>

export type WhitelistedRole = z.infer<typeof WhitelistedRole>

export type Username = z.infer<typeof Username>
export type Password = z.infer<typeof Password>
export type GoodlokCredentials = z.infer<typeof GoodlokCredentials>

export type GoodlokAuthIdentity = z.infer<typeof GoodlokAuthIdentity>
