import type { FunctionComponent } from 'react'
import { Card, CardProps } from './Card'
import { Modal, ModalProps } from './Modal'

export const CardModal: FunctionComponent<Pick<ModalProps, 'isOpen' | 'onRequestClose'> & CardProps> = props => {
	const { isOpen, onRequestClose, ...rest } = props

	return (
		<Modal
			isOpen={props.isOpen}
			onRequestClose={props.onRequestClose}
			transparent={true}
			bordered={false}
			noPadding={true}
		>
			<Card {...rest} />
		</Modal>
	)
}
