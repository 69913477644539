import type { FunctionComponent, ReactNode } from 'react'
import styles from './TagWrapper.module.sass'

type TagWrapperProps = {
	children: ReactNode
	onClick?: () => void
}

export const TagWrapper: FunctionComponent<TagWrapperProps> = ({ children, onClick }) => {
	return (
		<div className={styles.tag}>
			{children}
			<button
				className={styles.closeButton}
				type="button"
				onClick={event => {
					event.stopPropagation()
					onClick?.()
				}}
			>
				<div className={styles.cross} />
			</button>
		</div>
	)
}
