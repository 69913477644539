import clsx from 'clsx'
import { Fragment, ReactNode } from 'react'
import styles from './Table.module.sass'

export type TableData<T extends string> = {
	columns: Array<{
		key: T
		header: ReactNode
		isHighlighted?: boolean
		withoutGapOnTheLeft?: boolean
	}>
	data: Array<{
		[key in T]: ReactNode
	}>
}

export type TableProps<T extends string> = {
	bordered?: boolean
	align?: 'left' | 'center' | 'right'
} & TableData<T>

export function Table<T extends string>(props: TableProps<T>) {
	const { columns, data, bordered, align } = props

	const gridColumns = columns
		.map((column, index) =>
			index === 0 || column.withoutGapOnTheLeft ? ' min-content' : ' minmax(0, 1fr) min-content',
		)
		.join(' ')

	return (
		<div
			className={clsx(styles.wrapper, bordered && styles.view_bordered, styles[`view_align_${align}`])}
			style={{ '--Table-column-count': columns.length, '--Table-grid-columns': gridColumns }}
		>
			<div className={clsx(styles.row, styles.is_header)}>
				{columns.map((column, index) => {
					const showSpacer = index > 0 && !column.withoutGapOnTheLeft
					return (
						<Fragment key={column.key}>
							{showSpacer && <div className={styles.spacer}></div>}
							<div className={clsx(styles.cell, column.isHighlighted && styles.is_highlighted)}>{column.header}</div>
						</Fragment>
					)
				})}
			</div>
			{data.map((row, index) => (
				<div className={styles.row} key={index}>
					{columns.map((column, index) => {
						const showSpacer = index > 0 && !column.withoutGapOnTheLeft
						return (
							<Fragment key={column.key}>
								{showSpacer && <div className={styles.spacer}></div>}
								<div className={clsx(styles.cell, column.isHighlighted && styles.is_highlighted)}>
									{row[column.key]}
								</div>
							</Fragment>
						)
					})}
				</div>
			))}
		</div>
	)
}
