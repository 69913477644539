import { z } from 'zod'

const LOG_LEVEL = Number(process.env.LOG_LEVEL)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ThunderBase<R> = (fn: (query: string, variables?: Record<string, unknown>) => Promise<any>) => R

export const thunderFetch = async (
	url: string,
	query: string,
	variables?: Record<string, unknown>,
	headers?: Record<string, string | undefined>,
	fetchOptions?: Partial<RequestInit>,
) => {
	headers = headers ?? {}
	headers['Content-Type'] = 'application/json'

	const fetch = global?.fetch ?? (typeof window === 'undefined' ? null : window)?.fetch

	const req = await fetch(url, {
		method: 'POST',
		headers: Object.entries(headers).map(([key, value]) => [key, value ?? ''] as [string, string]),
		body: JSON.stringify({ query, variables }),
		...fetchOptions,
	})

	const text = await req.text()

	if (LOG_LEVEL >= 4) {
		console.log({
			url,
			headers,
			variables,
		})
		console.log(query)
		console.log(text)
	}

	try {
		const data = JSON.parse(text)

		if ('errors' in data) {
			const errorsResponse = ContemberErrorResponse.parse(data)
			throw new ContemberError(errorsResponse.errors[0].code, errorsResponse.errors[0].message, {
				query,
				variables,
				result: data,
			})
		}

		if ('data' in data) {
			return data.data
		}

		throw new Error(JSON.stringify(data, null, 2))
	} catch (e) {
		if (e instanceof SyntaxError) {
			console.log(text)
			throw e
		}
		throw e
	}
}

export const fromThunderWithHeaders =
	<R>(thunder: ThunderBase<R>, options: { url: string }) =>
	(headers: Record<string, string | undefined>) =>
		thunder(async (query, variables) => thunderFetch(options.url, query, variables, headers))

export const fromThunderWithToken =
	<R>(thunder: ThunderBase<R>, options: { url: string }) =>
	(token: string) =>
		thunder(async (query, variables) =>
			thunderFetch(options.url, query, variables, { Authorization: `Bearer ${token}` }),
		)

export const fromThunder = <R>(
	thunder: ThunderBase<R>,
	options: { url: string; token?: string | null; scope?: string | null },
) =>
	thunder(async (query, variables) =>
		thunderFetch(
			options.url,
			query,
			variables,
			{
				...(options.token ? { Authorization: `Bearer ${options.token}` } : undefined),
				...(options.scope ? { ['X-Goodlok-Scope']: options.scope } : undefined),
			},
			{
				mode: 'cors',
				credentials: 'include',
			},
		),
	)

const ContemberErrorResponse = z.object({
	errors: z.array(
		z.object({
			code: z.number().optional(),
			message: z.string(),
			path: z.array(z.string()).optional(),
			extensions: z
				.object({
					code: z.string(),
					exception: z.unknown().optional(),
				})
				.optional(),
		}),
	),
})

export class ContemberError extends Error {
	constructor(
		public code: number | string | undefined,
		public errorMessage: string,
		public original: { query: string; variables?: Record<string, unknown>; result: unknown },
	) {
		super(errorMessage)
	}
}
