import { Thunder } from '@goodlok/sdk/generated/content_role_public'
import { fromThunderWithToken } from '@goodlok/sdk/zeus/fromThunder'
import { TranslationCatalogueFragment } from './fragments/TranslationCatalogueFragment'
import type { TranslationValueResult } from './fragments/TranslationValueFragment'

/**
 * @param domainIdentifier - if null, all translations are returned
 */
export async function getTranslations(
	domainIdentifier: string | null,
	locale: string,
): Promise<{ [key: string]: string }> {
	const content = fromThunderWithToken(Thunder, {
		url: `${process.env.BACKEND_URL}/content`,
	})(process.env.CONTEMBER_CONTENT_TOKEN as string)

	const translationsQuery = await content('query')({
		listTranslationCatalogue: [
			{
				filter: {
					identifier: {
						code: {
							eq: locale,
						},
					},
					domain:
						typeof domainIdentifier === 'string'
							? {
									identifier: {
										eq: domainIdentifier,
									},
							  }
							: undefined,
				},
			},
			TranslationCatalogueFragment(),
		],
	})
	const queryPreferredValues: TranslationValueResult[] = translationsQuery.listTranslationCatalogue.flatMap(
		catalogue => catalogue.values,
	)
	const queryFallbackValues: TranslationValueResult[] = translationsQuery.listTranslationCatalogue.flatMap(
		catalogue => catalogue.fallback?.values ?? [],
	)

	const mapValue = (raw: TranslationValueResult): [string, string] => [raw.key?.identifier ?? '', raw.value]
	const valuesToKeyValuePairs = (values: TranslationValueResult[]) => Object.fromEntries(values.map(mapValue))

	const preferredValues = valuesToKeyValuePairs(queryPreferredValues)
	const fallbackValues = valuesToKeyValuePairs(queryFallbackValues)

	const values = {
		...fallbackValues,
		...preferredValues,
	}

	return values
}
