import type { FunctionComponent } from 'react'
import styles from './BoxTitle.module.sass'

export type BoxTitleProps = {
	title: string
	description?: string
	button?: React.ReactNode
}

export const BoxTitle: FunctionComponent<BoxTitleProps> = ({ title, description, button }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<div className={styles.title}>{title}</div>
				{button}
			</div>
			{description && <div className={styles.description}>{description}</div>}
		</div>
	)
}
