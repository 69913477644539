import { useCallback, useRef, useState } from 'react'

export function useMaybeControlledWithLastEdited<Value>(params: {
	value?: Value
	onChange?: (value: Value) => void
	defaultValue?: Value
}) {
	const { defaultValue, onChange, value } = params

	const [state, originalSetState] = useState<Value | undefined>(defaultValue)
	const [lastEdited, setLastEdited] = useState(0)

	const isControlled = value !== undefined

	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange

	const setState = useCallback((value: Value) => {
		setLastEdited(Date.now())
		onChangeRef.current?.(value)
		return originalSetState(value)
	}, [])

	if (isControlled && typeof onChange === 'undefined') {
		throw new Error('onChange is required when value is provided')
	}

	return [isControlled ? value : state, setState, lastEdited, setLastEdited] as const
}
