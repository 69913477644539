import { createContext, FunctionComponent, ReactNode, useCallback, useContext } from 'react'
import type { AsyncReturnType } from 'type-fest'
import type { getTranslations } from './getTranslations'

export type Translations = AsyncReturnType<typeof getTranslations>

const Context = createContext<{
	translations: Translations
	locale: string
}>({
	translations: {},
	locale: '',
})

export const TranslationsProvider: FunctionComponent<{
	translations: Translations
	locale: string
	children: ReactNode
}> = ({ children, translations, locale }) => {
	return <Context.Provider value={{ translations, locale }}>{children}</Context.Provider>
}

export const useTranslate = () => {
	const context = useContext(Context)
	return useCallback((key: string) => context.translations[key] ?? key, [context.translations])
}

export const useTranslateWithoutFallback = () => {
	const context = useContext(Context)
	return useCallback((key: string) => context.translations[key] ?? null, [context.translations])
}

export const useCurrentLocale = () => {
	const context = useContext(Context)
	return context.locale
}
