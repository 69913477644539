import { Input } from '@goodlok/ui'
import { FunctionComponent, useRef } from 'react'
import { Button } from '../../components'
import { GoodlokAuthStatus } from '../GoodlokAuth'
import { GoodlokFormError } from '../GoodlokFormError'
import { useGoodlokAuth } from '../hooks'
import { useGoodlokAuthMutation } from '../useGoodlokAuthMutation'
import { UsernameInput } from './SignInOrSignUp'
import styles from './SignUp.module.sass'

export interface SignUpProps {
	goToSignInOrSignUp: () => void
	username: string
	onSuccess?: (customer: { id: string }) => void
}

export const SignUp: FunctionComponent<SignUpProps> = ({ goToSignInOrSignUp, username, onSuccess }) => {
	const formRef = useRef<HTMLFormElement>(null)
	const { signIn, signUp } = useGoodlokAuthMutation()
	const { status } = useGoodlokAuth()
	const isDisabled = status === GoodlokAuthStatus.Loading

	return (
		<>
			<h2 className={styles.title}>Registrace</h2>
			<div className={styles.text}>
				{username}{' '}
				<button className={styles.edit} type="button" onClick={() => goToSignInOrSignUp()}>
					Upravit
				</button>
			</div>

			<form
				ref={formRef}
				action=""
				method="POST"
				className={styles.form}
				onSubmit={e => {
					e.preventDefault()
					const input = new FormData(e.currentTarget)
					signUp.mutation.mutateAsync(input).then(data => {
						if (data.signUpWithPassword.ok) {
							signIn.mutation.mutateAsync(input).then(data => {
								if (data.signIn.customerId) {
									onSuccess?.({ id: data.signIn.customerId })
								}
							})
						}
					})
				}}
			>
				<fieldset disabled={signUp.mutation.isLoading}>
					<GoodlokFormError error={signUp.mutation.error} />
					<UsernameInput value={username} isAutoFillHelper />
					<Input
						type="password"
						name="password"
						label="Heslo"
						labelPosition="inside"
						required
						autoFocus
						autoComplete="new-password"
						enterKeyHint="done"
						error={signUp.hasError('password')}
						minLength={6}
						readOnly={isDisabled}
					/>
					<div className={styles.button}>
						<Button
							variant="dark"
							size="large"
							round
							uppercase={false}
							bold={false}
							fullWidth
							type="submit"
							disabled={isDisabled}
						>
							Pokračovat
						</Button>
					</div>
				</fieldset>
			</form>
		</>
	)
}
