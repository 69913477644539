import clsx from 'clsx'
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import styles from './Box.module.sass'

export type BoxProps = {
	title?: ReactNode
	actions?: ReactNode
	disableGutters?: boolean
}

export const Box: FunctionComponent<BoxProps & PropsWithChildren> = ({ title, actions, disableGutters, children }) => {
	return (
		<div className={clsx(styles.wrapper, disableGutters && styles.view_disabledGutters)}>
			{(title || actions) && (
				<div className={styles.header}>
					<h3 className={styles.title}>{title}</h3>
					<div>{actions}</div>
				</div>
			)}
			{children}
		</div>
	)
}
