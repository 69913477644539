import { LoadingSuspense, SharedLoadingIndicator, SharedLoadingIndicatorContextProvider } from '@goodlok/ui'
import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import { PropsWithChildren, ReactNode, useRef } from 'react'
import { useGlobalKeyDown } from '../misc'
import { HorizontallyScrollableContent } from './HorizontallyScrollableContent'
import { Tab } from './Tab'
import styles from './TabbedWithAside.module.sass'

export type TabbedWithAsideProps<Value extends string> = PropsWithChildren<{
	asideHeader?: ReactNode
	asideContent?: ReactNode
	asideFooter?: ReactNode
	asideOverlay?: ReactNode
	contentFooter?: ReactNode
	onRequestCloseOverlay?: () => void
	items: ReadonlyArray<{
		label: string
		icon?: ReactNode
		value: Value
	}>
	value: Value | null
	onChange: (value: Value) => void
	logo?: ReactNode
	disableContentAndHeader?: boolean
}>

export const TabbedWithAside = <Value extends string>({
	items,
	value,
	onChange,
	asideHeader,
	asideContent,
	asideFooter,
	asideOverlay,
	onRequestCloseOverlay: requestCloseOverlay,
	logo,
	disableContentAndHeader = false,
	children,
	contentFooter,
}: TabbedWithAsideProps<Value>) => {
	const showAsideOverlay = Boolean(asideOverlay)
	const asideOverlayNonNullable = useRef<ReactNode>(<></>)
	asideOverlayNonNullable.current = asideOverlay ?? asideOverlayNonNullable.current
	const nonOverlayAsideInertValue = showAsideOverlay ? '' : null
	const contentAndHeaderInertValue = showAsideOverlay || disableContentAndHeader ? '' : null

	const showContentFooter = Boolean(contentFooter)
	const contentFooterRef = useRef<ReactNode>(<></>)
	contentFooterRef.current = contentFooter ?? contentFooterRef.current
	const contentFooterInertValue = showContentFooter ? null : ''

	useGlobalKeyDown(
		event => {
			if (event.key === 'Escape') {
				requestCloseOverlay?.()
			}
		},
		Boolean(requestCloseOverlay && showAsideOverlay),
	)

	return (
		<div
			className={clsx(
				styles.wrapper,
				showAsideOverlay && styles.is_overlay_visible,
				disableContentAndHeader && styles.is_contentAndHeader_disabled,
			)}
		>
			{requestCloseOverlay ? (
				<button
					className={styles.overlayCloser}
					type="button"
					aria-label="zavřít"
					onClick={() => {
						requestCloseOverlay()
					}}
				/>
			) : (
				<div className={styles.overlayCloser} />
			)}
			<div
				className={styles.header}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore Inert not yet supported by Typescript
				inert={contentAndHeaderInertValue}
			>
				{logo && <div className={styles.logo}>{logo}</div>}
				<div className={styles.tabNavigation}>
					<HorizontallyScrollableContent>
						<div className={styles.tabNavigation_items}>
							{items.map(item => (
								<Tab
									key={item.value}
									label={item.label}
									icon={item.icon}
									onClick={() => {
										onChange(item.value)
									}}
									active={value === item.value}
								/>
							))}
						</div>
					</HorizontallyScrollableContent>
				</div>
			</div>
			<SharedLoadingIndicatorContextProvider ignorePageNavigation>
				<div
					className={styles.content}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore Inert not yet supported by Typescript
					inert={contentAndHeaderInertValue}
				>
					<SharedLoadingIndicator nested />
					<LoadingSuspense>
						<div className={styles.contentMain}>{children}</div>
						<div
							className={styles.contentFooter}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore Inert not yet supported by Typescript
							inert={contentFooterInertValue}
						>
							<Collapsible open={showContentFooter} revealType="topFirst">
								<div className={styles.contentFooter_in}>{contentFooterRef.current}</div>
							</Collapsible>
						</div>
					</LoadingSuspense>
				</div>
			</SharedLoadingIndicatorContextProvider>
			{(asideHeader || asideContent || asideFooter) && (
				<div
					className={styles.aside}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore Inert not yet supported by Typescript
					inert={nonOverlayAsideInertValue}
				>
					{asideHeader && (
						<div className={styles.asideHeader}>
							<LoadingSuspense>{asideHeader}</LoadingSuspense>
						</div>
					)}
					<SharedLoadingIndicatorContextProvider ignorePageNavigation>
						<div className={styles.asideContent}>
							<div className={styles.asideContent_main}>
								<SharedLoadingIndicator nested />
								<LoadingSuspense>{asideContent}</LoadingSuspense>
							</div>
							<div className={styles.asideContent_footer}>
								{asideFooter && <LoadingSuspense>{asideFooter}</LoadingSuspense>}
							</div>
						</div>
					</SharedLoadingIndicatorContextProvider>
				</div>
			)}
			<div className={styles.asideOverlay}>
				<div className={styles.asideOverlay_in}>{asideOverlayNonNullable.current}</div>
			</div>
		</div>
	)
}
