import { Fragment, FunctionComponent, ReactNode } from 'react'
import { useGoodlokAuth } from '.'

export interface NotSharedBetweenAuthUsersProps {
	children: ReactNode
}

export const NotSharedBetweenAuthUsers: FunctionComponent<NotSharedBetweenAuthUsersProps> = ({ children }) => {
	// @TODO: change customerId to something not dependent on role
	const { customerId } = useGoodlokAuth()

	return <Fragment key={customerId || undefined}>{children}</Fragment>
}
