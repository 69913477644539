import { ErrorMessage, useMirrorLoading } from '@goodlok/ui'
import type { FunctionComponent, PropsWithChildren } from 'react'
import { Button, Modal } from '../components'
import styles from './AuthenticationGate.module.sass'
import { GoodlokAuthStatus } from './GoodlokAuth'
import { GoodlokAuthForm } from './GoodlokAuthForm'
import { useGoodlokAuth, useGoodlokHasAtLeastOneRole } from './hooks'

export type AuthenticationGateProps = PropsWithChildren<{
	// @TODO: optional?: boolean
	allowedRoles?: Parameters<typeof useGoodlokHasAtLeastOneRole>[0]
}>

export const AuthenticationGate: FunctionComponent<AuthenticationGateProps> = ({ children, allowedRoles = [] }) => {
	const g = useGoodlokAuth()
	const hasAllowedRole = useGoodlokHasAtLeastOneRole(allowedRoles) || allowedRoles.length === 0
	useMirrorLoading(g.status === GoodlokAuthStatus.Loading)
	const isNotAllowed = g.status === GoodlokAuthStatus.SignedIn && hasAllowedRole === false

	return (
		<>
			<Modal
				isOpen={g.status !== GoodlokAuthStatus.Loading && (g.status !== GoodlokAuthStatus.SignedIn || isNotAllowed)}
			>
				{isNotAllowed ? (
					<div className={styles.error}>
						<ErrorMessage error="Chybí ti dostatečné oprávnění." />
						<Button type="button" variant="dark" onClick={() => g.actions.signOut()}>
							Odhlásit se
						</Button>
					</div>
				) : (
					<GoodlokAuthForm />
				)}
			</Modal>
			{g.status === GoodlokAuthStatus.SignedIn && hasAllowedRole && children}
		</>
	)
}
