import type { FunctionComponent } from 'react'
import type { CustomerOrderResult } from '../fragments/CustomerOrderFragment'
import styles from './Address.module.sass'
import { StateLabel } from './StateLabel'

export type AddressProps = {
	address: NonNullable<NonNullable<CustomerOrderResult['delivery']>['address']>
	isDefaultDeliveryAddress?: boolean
}

export const Address: FunctionComponent<AddressProps> = ({ address, isDefaultDeliveryAddress }) => {
	const { fullName, addressLine1, addressLine2, city, postalCode, phone } = address

	return (
		<address className={styles.wrapper}>
			{isDefaultDeliveryAddress && (
				<div className={styles.tags}>
					{isDefaultDeliveryAddress && <StateLabel variant="neutral">Výchozí</StateLabel>}
				</div>
			)}
			<div className={styles.content}>
				<div>{fullName}</div>
				<div>{addressLine1}</div>
				<div>{addressLine2}</div>
				<div>
					{city} {postalCode}
				</div>
				{phone && <div className={styles.phone}>{phone}</div>}
			</div>
		</address>
	)
}
