import type { GoodlokChannel } from '@goodlok/meta'
import type { ChannelCode } from '@goodlok/sdk/generated/content_role_customer'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { FragmentResult, Selector, ValueTypes } from '../../../sdk/generated/orders'
import { useGoodlokAuth, useGoodlokCustomerId } from '../auth'

const LATEST_ORDER_LOCAL_STORAGE_KEY = 'GOODLOK_LATEST_ORDER'

export const GoodlokShoppingContext = createContext<null | {
	channel: GoodlokChannel
}>(null)

export const GoodlokCartContext = createContext<null | {
	identifier: CartIdentifier
}>(null)

export const GoodlokShoppingRoot: FunctionComponent<{ channel: GoodlokChannel; children: React.ReactNode }> = ({
	channel,
	children,
}) => {
	return (
		<GoodlokShoppingContext.Provider value={useMemo(() => ({ channel }), [channel])}>
			{children}
		</GoodlokShoppingContext.Provider>
	)
}

export const GoodlokCartRoot: FunctionComponent<{ identifier: CartIdentifier; children: React.ReactNode }> = ({
	identifier,
	children,
}) => {
	return (
		<GoodlokCartContext.Provider value={useMemo(() => ({ identifier }), [identifier])}>
			{children}
		</GoodlokCartContext.Provider>
	)
}

export function useGoodlokShoppingContext() {
	const value = useContext(GoodlokShoppingContext)

	if (!value) {
		throw new Error('Missing GoodlokShoppingContext')
	}

	return value
}

export type CartIdentifier =
	| string
	| { cartId: string }
	| { customerId: string; channel: ChannelCode; handle?: string | null }
	| { channel: ChannelCode; handle?: string | null }

export function cartIdentifierToParams(identifier: CartIdentifier): NonNullable<ValueTypes['Query']['getCart']>[0] {
	if (typeof identifier === 'string') {
		return { cartId: identifier }
	}
	return identifier
}

export function useGoodlokCartIdentifier(identifier?: CartIdentifier | null): CartIdentifier | null {
	const { channel } = useContext(GoodlokShoppingContext) ?? {}
	const cartContext = useContext(GoodlokCartContext)

	const customerId = useGoodlokCustomerId()

	const contextIdentifier = useMemo(() => {
		if (cartContext) {
			return cartContext.identifier
		}

		if (customerId && channel) {
			return {
				customerId,
				channel,
			}
		}

		if (channel) {
			return {
				channel,
			}
		}
		return null
	}, [cartContext, channel, customerId])

	return identifier ?? contextIdentifier
}

export function useGoodlokCartQueryParams(identifier?: CartIdentifier | null) {
	const cartIdentifier = useGoodlokCartIdentifier(identifier)

	return useMemo(() => (cartIdentifier ? cartIdentifierToParams(cartIdentifier) : null), [cartIdentifier])
}

export const CartFragment = Selector('Cart')({
	id: true,
	customerId: true,
	channel: true,
	itemsCount: true,
	totalPriceCents: true,
	itemsPriceCents: true,
	deliveryPriceCents: true,
	checkoutUrl: true,
	order: {
		id: true,
	},
	items: {
		itemId: true,
		priceCents: true,
		productCode: true,
		productId: true,
		productName: true,
		availableQuantity: true,
		product: {
			image: [
				{},
				{
					id: true,
					url: true,
					width: true,
					height: true,
				},
			],
		},
		quantity: true,
		unitPriceCents: true,
		vatRate: {
			ratePermille: true,
		},
	},
})

export type CartResult = FragmentResult<'Cart', typeof CartFragment>

export function useGoodlokShopping(identifier?: CartIdentifier | null) {
	const queryClient = useQueryClient()
	const { channel } = useContext(GoodlokShoppingContext) ?? {}

	const g = useGoodlokAuth()

	const customerId = useGoodlokCustomerId()

	const cartIdentifierFromContext = useGoodlokCartIdentifier(identifier)

	const cartIdentifier = identifier ?? cartIdentifierFromContext

	const cartQueryParams = useGoodlokCartQueryParams(cartIdentifier)

	const cart = useQuery(
		['cart', cartIdentifier] as const,
		async () => {
			if (cartQueryParams) {
				return g.zeus
					.orders('query')({
						getCart: [cartQueryParams, CartFragment],
					})
					.then(data => data.getCart)
			}
			return null
		},
		{
			enabled: Boolean(cartIdentifier),
		},
	)

	const setItems = useCallback(
		async (params: { items: { productId: string; quantity: number }[] }) => {
			if (cartQueryParams) {
				const { setItems } = await g.zeus.orders('mutation')({
					setItems: [
						{
							...cartQueryParams,
							items: params.items,
						},
						{ ok: true, errorCode: true, cart: { id: true } },
					],
				})

				if (setItems.errorCode) {
					throw new Error(setItems.errorCode)
				}

				await queryClient.invalidateQueries(['cart'])

				return setItems.cart
			}
		},
		[cartQueryParams, g.zeus, queryClient],
	)

	const { refresh } = g

	const addToCart = useCallback(
		async (params: { productId: string; quantity: number }) => {
			const { addItemToCart } = await g.zeus.orders('mutation')({
				addItemToCart: [
					{
						...cartQueryParams,
						productId: params.productId,
						quantity: params.quantity,
					},
					{ ok: true, errorCode: true, cart: { id: true, handle: true } },
				],
			})

			if (addItemToCart.errorCode) {
				throw new Error(addItemToCart.errorCode)
			}

			await queryClient.invalidateQueries(['cart'])

			refresh()

			return addItemToCart.cart
		},
		[g.zeus, cartQueryParams, queryClient, refresh],
	)

	const setItemQuantity = useCallback(
		async (params: { productId: string; quantity: number }) => {
			if (cartQueryParams) {
				const { setItemQuantityInCart } = await g.zeus.orders('mutation')({
					setItemQuantityInCart: [
						{
							...cartQueryParams,
							productId: params.productId,
							quantity: params.quantity,
						},
						{ ok: true, errorCode: true, cart: { id: true, handle: true } },
					],
				})

				if (setItemQuantityInCart.errorCode) {
					throw new Error(setItemQuantityInCart.errorCode)
				}
				await queryClient.invalidateQueries(['cart'])

				return setItemQuantityInCart.cart
			}
		},
		[cartQueryParams, g.zeus, queryClient],
	)

	const copyCartItems = useCallback(
		async (params: { sourceCartId: string; customerId: string }) => {
			const { copyCartItems } = await g.zeus.orders('mutation')({
				copyCartItems: [
					{
						customerId: params.customerId,
						sourceCartId: params.sourceCartId,
					},
					{ ok: true, errorCode: true, cart: { id: true, handle: true, checkoutUrl: true } },
				],
			})

			if (copyCartItems.errorCode) {
				throw new Error(copyCartItems.errorCode)
			}

			await queryClient.invalidateQueries(['cart'])

			return copyCartItems.cart
		},
		[g.zeus, queryClient],
	)

	const confirm = useCallback(
		async (params?: { deliveryAddressId?: string | null; billingAddressId?: string | null }) => {
			if (cartQueryParams) {
				const { confirmOrder } = await g.zeus.orders('mutation')({
					confirmOrder: [
						{
							...cartQueryParams,
							billingAddressId: params?.billingAddressId,
							deliveryAddressId: params?.deliveryAddressId,
						},
						{ ok: true, errorCode: true, order: { id: true } },
					],
				})

				if (confirmOrder.errorCode) {
					throw new Error(confirmOrder.errorCode)
				}

				await queryClient.invalidateQueries(['cart'])

				return confirmOrder.order?.id
			}
		},
		[cartQueryParams, g.zeus, queryClient],
	)

	const resetCart = useCallback(
		async (callback?: () => void) => {
			if (cartQueryParams) {
				await g.zeus
					.orders('mutation')({
						deleteCart: [cartQueryParams, { ok: true, errorCode: true }],
					})
					.then(() => {
						queryClient.invalidateQueries(['cart'])
						callback?.()
					})
			}
		},
		[cartQueryParams, g.zeus, queryClient],
	)

	// @TODO: keep date
	const [latestOrder, setLatestOrder, latestOrderOptions] = useLocalStorageState<string>(
		`${LATEST_ORDER_LOCAL_STORAGE_KEY}/${JSON.stringify(cartIdentifier)}`,
	)

	const orderId = cart.data?.order?.id as null | string

	useEffect(() => {
		console.log({ orderId, latestOrder })
		if (orderId) {
			setLatestOrder(orderId)
			if (latestOrder !== orderId) {
				resetCart()
			}
		}
	}, [latestOrder, orderId, resetCart, setLatestOrder])

	const frozen = Boolean(orderId)

	const order = useQuery(
		['order', latestOrder] as const,
		async () => {
			if (latestOrder) {
				return g.zeus
					.roleCustomer('query')({
						getOrder: [
							{
								by: {
									id: latestOrder,
								},
							},
							{
								state: true,
								id: true,
								seq: true,
							},
						],
					})
					.then(data => data.getOrder)
			}
			return null
		},
		{
			enabled: Boolean(latestOrder),
		},
	)

	const resetOrder = useCallback(() => {
		latestOrderOptions.removeItem()
		queryClient.invalidateQueries(['cart'])
	}, [latestOrderOptions, queryClient])

	return {
		frozen,
		cart,
		order,
		confirm,
		resetCart,
		resetOrder,
		customerId,
		channel,
		addToCart,
		setItemQuantity,
		setItems,
		copyCartItems,
		loading: cart.isLoading,
	}
}

export function useGoodlokShoppingCartItem(productId: string) {
	const cart = useGoodlokShopping()

	const frozen = cart.frozen

	const current = cart.cart.data?.items.find(item => item.productId === productId || item.productCode === productId)

	const add = useCallback((quantity = 1) => cart.addToCart({ productId, quantity }), [cart, productId])
	const set = useCallback((quantity = 0) => cart.setItemQuantity({ productId, quantity }), [cart, productId])
	const remove = useCallback(() => set(0), [set])

	return { current, frozen, cart, add, set, remove }
}
