import type { GoodlokOrderStates } from '@goodlok/meta'
import type { FunctionComponent } from 'react'
import type { CustomerOrderResult } from '../fragments/CustomerOrderFragment'
import styles from './OrderTitle.module.sass'
import { ProductCount } from './ProductCount'
import { StateLabel } from './StateLabel'

const orderStatesWithLabels: {
	[key in (typeof GoodlokOrderStates)[number]]: string
} = {
	created: 'Vytvořeno',
	waitingForPayment: 'Čeká na platbu',
	processing: 'Připravováno',
	dispatched: 'Vysláno',
	fulfilled: 'Dokončeno',
	canceled: 'Zrušeno',
}

export type OrderTitleProps = {
	productsCount?: number
	date: string
	state: CustomerOrderResult['state']
}

export const OrderTitle: FunctionComponent<OrderTitleProps> = ({ productsCount, date, state }) => {
	return (
		<div className={styles.title}>
			<div className={styles.productCount}>
				{productsCount ? <ProductCount count={productsCount} productIcon="bottles" /> : null}
			</div>

			<div className={styles.date}>
				{new Intl.DateTimeFormat('cs', {
					day: 'numeric',
					month: 'long',
					year: 'numeric',
				}).format(new Date(date))}
			</div>
			<StateLabel
				variant={
					state === 'fulfilled' ? 'success' : ['canceled', 'waitingForPayment'].includes(state) ? 'error' : 'neutral'
				}
			>
				{orderStatesWithLabels[state]}
			</StateLabel>
		</div>
	)
}
