import type { PropsWithChildren } from 'react'
import { createContext, FunctionComponent, useCallback, useContext } from 'react'
import type { ZodError } from 'zod'

const ZodErrorContext = createContext<{ zodError: ZodError; errorTimestamp: number } | null>(null)

export const ZodErrorProvider: FunctionComponent<
	PropsWithChildren<{ zodError: ZodError | null; errorTimestamp?: number }>
> = ({ zodError, errorTimestamp, children }) => {
	return (
		<ZodErrorContext.Provider value={zodError ? { zodError, errorTimestamp: errorTimestamp ?? 0 } : null}>
			{children}
		</ZodErrorContext.Provider>
	)
}

export const useZodFieldErrors = () => {
	const context = useContext(ZodErrorContext)
	const contextErrorTimestamp = context?.errorTimestamp
	const fieldErrors = context?.zodError.formErrors.fieldErrors
	return useCallback(
		(name: string, errorTimestamp?: number) => {
			if ((errorTimestamp ?? 0) < (contextErrorTimestamp ?? 0)) {
				return fieldErrors?.[name] ?? null
			}
		},
		[contextErrorTimestamp, fieldErrors],
	)
}
