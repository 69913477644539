import { useCallback, useMemo, useState } from 'react'
import { useStorageBackedState } from 'use-storage-backed-state'
import { FilterGroup } from '../components'

type SectionKeyFromFilterGroups<Groups extends FilterGroup[]> = Groups[number]['sections'][number]['key']

export type FilterSelectParams<Groups extends FilterGroup[]> = {
	groups: Groups
	options?: {
		localStorageKey?: string
		isAutoselectOn?: boolean
	}
}

export const useFilterSelect = <Groups extends FilterGroup[]>(params: FilterSelectParams<Groups>) => {
	type SectionKey = SectionKeyFromFilterGroups<Groups>

	const { groups, options } = params

	const [value, setValue] = useStorageBackedState<Partial<Record<SectionKey, string[]>>>(
		{},
		options?.localStorageKey ?? null,
	)
	const [activeSectionKey, setActiveSectionKey] = useState<SectionKey | null>(null)

	const newActiveSectionKey = useMemo(
		() =>
			options?.isAutoselectOn
				? groups.flatMap(group => group.sections).find(section => section.key === activeSectionKey)?.key ??
				  groups.at(0)?.sections.at(0)?.key ??
				  null
				: activeSectionKey,
		[activeSectionKey, groups, options?.isAutoselectOn],
	)

	const activeSection = useMemo(
		() => groups.flatMap(group => group.sections).find(section => section.key === newActiveSectionKey) ?? null,
		[groups, newActiveSectionKey],
	)

	const resetFilter = useCallback(() => {
		setValue({})
	}, [setValue])

	const toggleItem = useCallback(
		(sectionKey: SectionKey, id: string, checked: boolean) => {
			const activeSection = groups.flatMap(group => group.sections).find(section => section.key === sectionKey)

			setValue(previous => {
				const valueSectionItems = previous[sectionKey] ?? []

				if (!activeSection) {
					return previous
				}

				if (checked) {
					return {
						...previous,
						[sectionKey]:
							activeSection.selectionMode === 'multi' ? Array.from(new Set([...valueSectionItems, id]).values()) : [id],
					}
				}
				return {
					...previous,
					[sectionKey]: valueSectionItems.filter(itemId => itemId !== id),
				}
			})
		},
		[groups, setValue],
	)

	const isChecked = useCallback(
		(sectionKey: SectionKey, id: string) => {
			return value[sectionKey]?.includes(id) ?? false
		},
		[value],
	)

	return useMemo(
		() => ({
			groups,
			value,
			setActiveSectionKey,
			activeSection,
			resetFilter,
			toggleItem,
			isChecked,
		}),
		[activeSection, groups, isChecked, resetFilter, toggleItem, value],
	)
}
