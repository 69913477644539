import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import React, { forwardRef, FunctionComponent, useEffect, useState } from 'react'
import { Icon } from '../icons/Icon'
import styles from './DetailedList.module.sass'

export type DetailedListItem = {
	readonly uniqueIdentifier: string
	readonly image?: React.ReactNode
	readonly title: React.ReactNode
	readonly description?: React.ReactNode
	readonly secondaryContent?: React.ReactNode
	readonly collapsibleContent?: React.ReactNode
	readonly isCollapsibleContentOpen?: boolean
}

export type DetailedListProps = {
	items: DetailedListItem[]
	itemsLayout?: 'default' | 'shortTitle'
	showImagesColumn?: boolean
	nested?: boolean
	hasNestedDetailedList?: boolean
}

export const DetailedList = forwardRef<HTMLUListElement, DetailedListProps>(
	({ items, itemsLayout, showImagesColumn = true, nested = false, hasNestedDetailedList = false }, ref) => {
		const atLeastOneItemHasCollapsibleContent = Boolean(items.find(item => item.collapsibleContent !== undefined))
		return (
			<ul
				ref={ref}
				className={clsx(
					styles.list,
					showImagesColumn && styles.is_withImages,
					nested && styles.is_nested,
					hasNestedDetailedList && styles.is_withNestedDetailedList,
					atLeastOneItemHasCollapsibleContent && styles.is_listWithCollapsibleItems,
				)}
			>
				{items.map(item => (
					<React.Fragment key={`${item.uniqueIdentifier}`}>
						<DetailedListItem item={item} itemsLayout={itemsLayout} />
					</React.Fragment>
				))}
			</ul>
		)
	},
)
DetailedList.displayName = 'DetailedList'

const DetailedListItem: FunctionComponent<{
	item: DetailedListProps['items'][number]
	itemsLayout?: string
}> = ({ item, itemsLayout }) => {
	const [isCollapsibleContentOpen, setIsCollapsibleContentOpen] = useState(false)
	useEffect(() => {
		setIsCollapsibleContentOpen(item.isCollapsibleContentOpen ?? false)
	}, [item.isCollapsibleContentOpen])
	return (
		<div
			onClick={() => {
				if (item.collapsibleContent) {
					setIsCollapsibleContentOpen(!isCollapsibleContentOpen)
				}
			}}
			className={clsx(
				styles.item,
				itemsLayout && styles[`view-layout-${itemsLayout}`],
				item.collapsibleContent && styles.is_highlightedOnHover,
				isCollapsibleContentOpen && styles.is_collapsibleContentOpen,
			)}
		>
			<div className={styles.image}>{item.image}</div>
			<div className={styles.info}>
				<h3 className={styles.title}>{item.title}</h3>
				{item.description && <div className={styles.description}>{item.description}</div>}
			</div>
			<div className={styles.secondaryContent}>{item.secondaryContent}</div>
			{item.collapsibleContent && (
				<>
					<div className={styles.arrow}>
						<Icon name="arrowDown" />
					</div>
					<div className={styles.collapsibleContent}>
						<Collapsible open={isCollapsibleContentOpen}>{item.collapsibleContent}</Collapsible>
					</div>
				</>
			)}
		</div>
	)
}
