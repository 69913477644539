import { useMutation } from '@tanstack/react-query'
import { ZodError } from 'zod'
import { InvalidPasswordError, UnknownEmailError } from './errors'
import { useGoodlokAuth } from './hooks'
import { GoodlokCredentials } from './schema'

export function useGoodlokAuthMutation() {
	const g = useGoodlokAuth()

	const startSessionMutation = useMutation(async () => {
		return g.actions.startSession({ sessionKey: null }).then(data => {
			if (data.startSession.error) {
				throw new Error('Cannot start session')
			}
			return data
		})
	})

	const signInMutation = useMutation(async (formData: FormData) => {
		const data = GoodlokCredentials.parse(Object.fromEntries(formData.entries()))
		return g.actions.signIn(data).then(data => {
			if (data.signIn.error) {
				switch (data.signIn.error.code) {
					case 'INVALID_PASSWORD':
						throw new InvalidPasswordError(data.signIn.error.developerMessage)
					case 'UNKNOWN_EMAIL':
						throw new UnknownEmailError(data.signIn.error.developerMessage)
				}
			}
			return data
		})
	})

	const signUpMutation = useMutation(async (formData: FormData) => {
		const data = GoodlokCredentials.parse(Object.fromEntries(formData.entries()))
		return g.actions.signUp(data).then(data => {
			if (data.signUpWithPassword.error) {
				switch (data.signUpWithPassword.error.code) {
					case 'INVALID_PASSWORD':
						throw new InvalidPasswordError(data.signUpWithPassword.error.developerMessage)
					case 'UNKNOWN_EMAIL':
						throw new UnknownEmailError(data.signUpWithPassword.error.developerMessage)
				}
			}
			return data
		})
	})

	const signOutMutation = useMutation(async () => {
		return g.actions.signOut()
	})

	const signStatus = useMutation(async (username: string) => {
		return g.zeus
			.auth('query')({
				signStatus: [{ username }, { hasCustomerProfile: true, signedUp: true, verified: true }],
			})
			.then(data => data.signStatus)
	})

	return {
		startSession: {
			mutation: startSessionMutation,
		},
		signIn: {
			mutation: signInMutation,
			hasError: (name?: string): undefined | string | string[] => {
				if (name) {
					if (signInMutation.error instanceof ZodError) {
						const flatten = signInMutation.error.flatten()
						if (flatten.fieldErrors[name]) {
							return flatten.fieldErrors[name]
						}
					}
					return
				}

				if (signInMutation.error) {
					if (signInMutation.error instanceof Error) {
						return signInMutation.error.message
					}

					return `Error`
				}

				return
			},
		},
		signOut: {
			mutation: signOutMutation,
		},
		signUp: {
			mutation: signUpMutation,
			hasError: (name?: string): undefined | string | string[] => {
				if (name) {
					if (signUpMutation.error instanceof ZodError) {
						const flatten = signUpMutation.error.flatten()
						if (flatten.fieldErrors[name]) {
							return flatten.fieldErrors[name]
						}
					}
					return
				}

				if (signUpMutation.error) {
					if (signUpMutation.error instanceof Error) {
						return signUpMutation.error.message
					}

					return `Error`
				}

				return
			},
		},
		signStatus: {
			mutation: signStatus,
		},
	}
}
