import { useCallback, useState } from 'react'

export function useStateWithLastEdited<Value>(value: Value) {
	const [state, originalSetState] = useState(value)
	const [lastEdited, setLastEdited] = useState(0)

	const setState: typeof originalSetState = useCallback(value => {
		setLastEdited(Date.now())
		return originalSetState(value)
	}, [])

	return [state, setState, lastEdited, setLastEdited] as const
}
