import { FunctionComponent, useMemo } from 'react'
import { Icon } from '../icons/Icon'
import { Button } from './Button'
import styles from './MonthSwitcher.module.sass'

export type MonthSwitcherProps = {
	activeMonthIndex: number
	onPreviousClick: () => void
	onNextClick: () => void
	onReset: () => void
	showTodayButton?: boolean
}

export const MonthSwitcher: FunctionComponent<MonthSwitcherProps> = ({
	activeMonthIndex,
	onPreviousClick,
	onNextClick,
	onReset,
	showTodayButton = false,
}) => {
	const activeMonthName = useMemo(() => {
		const date = new Date()
		date.setDate(15) // workaround for edge days in month
		date.setMonth(activeMonthIndex)
		return new Intl.DateTimeFormat('cs-CZ', { month: 'long' }).format(date) // @TODO locale as prop
	}, [activeMonthIndex])

	return (
		<div className={styles.wrapper}>
			<button type="button" className={styles.previous} onClick={onPreviousClick}>
				<Icon name="arrowRight" />
			</button>
			<div className={styles.month}>{activeMonthName}</div>
			<button type="button" className={styles.next} onClick={onNextClick}>
				<Icon name="arrowRight" />
			</button>
			{showTodayButton && (
				<div className={styles.today}>
					<Button type="button" variant="dark" outline size="small" onClick={onReset}>
						Dnes
					</Button>
				</div>
			)}
		</div>
	)
}
