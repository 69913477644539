import { FormattedCents } from '@goodlok/ui'
import type { FunctionComponent } from 'react'
import styles from './OrderPriceLines.module.sass'

export type OrderPriceLinesProps = {
	lines: Array<{
		id: string
		orderItem?: {
			product?: {
				localesByLocale?: {
					name: string
				}
			}
		}
		unitPriceCents: number
		quantity: number
	}>
	totalPriceCents?: number
}

export const OrderPriceLines: FunctionComponent<OrderPriceLinesProps> = ({ lines, totalPriceCents }) => {
	return (
		<div className={styles.wrapper}>
			<ul className={styles.list}>
				{lines.map(line => (
					<li key={line.id} className={styles.item}>
						<span className={styles.quantity}>{line.quantity}×</span>{' '}
						<span className={styles.name}>{line.orderItem?.product?.localesByLocale?.name}</span>{' '}
						<span className={styles.price}>
							{line.quantity > 1 && (
								<>
									<span className={styles.price_single}>
										<FormattedCents cents={line.unitPriceCents} />
									</span>{' '}
								</>
							)}
							<span className={styles.price_sum}>
								<FormattedCents cents={line.quantity * line.unitPriceCents} />
							</span>
						</span>
					</li>
				))}
			</ul>
			{totalPriceCents && (
				<div className={styles.total}>
					<FormattedCents cents={totalPriceCents} />
				</div>
			)}
		</div>
	)
}
