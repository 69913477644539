import { FragmentResult, ValueTypes, ZeusSelect } from '@goodlok/sdk/generated/auth'

export function SessionFragment() {
	return ZeusSelect<ValueTypes['SessionResponse']>()({
		type: true,
		credentialsNote: true,
		customerId: true,
		staffId: true,
		personId: true,
		userId: true,
		email: true,
		roles: true,
	})
}

export type SessionResult = FragmentResult<'SessionResponse', typeof SessionFragment>
