import { RadioMark } from '@goodlok/ui'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { CommonSingleChoiceSelectProps } from './SingleChoiceSelectEdit'
import styles from './SingleChoiceSelectOption.module.sass'

export type SharedRadioSelectOptionProps = {
	className?: string
	error?: boolean
}

export type RadioSingleChoiceSelectOptionProps = {
	type: 'radio'
	isChecked: boolean
	option: CommonSingleChoiceSelectProps['options'][number]
	onChange: () => void
	value: string
	name: string
	uncheckable?: boolean
}

export type ButtonSingleChoiceSelectOptionProps = {
	type: 'button'
	placeholder?: string
	checkedInputLabel?: string
	setOpen?: () => void
	open?: boolean
}

export type SingleChoiceSelectOptionProps = SharedRadioSelectOptionProps &
	(RadioSingleChoiceSelectOptionProps | ButtonSingleChoiceSelectOptionProps)

export const SingleChoiceSelectOption: FunctionComponent<SingleChoiceSelectOptionProps> = ({
	className,
	...otherProps
}) => {
	switch (otherProps.type) {
		case 'radio':
			return (
				<label
					className={clsx(
						styles.wrapper,
						otherProps.error && styles.is_error,
						otherProps.isChecked && styles.is_checked,
						className,
					)}
					onClick={
						otherProps.uncheckable
							? e => {
									if (otherProps.isChecked && otherProps.onChange) {
										otherProps.onChange()
										e.preventDefault()
									}
							  }
							: undefined
					}
				>
					<RadioMark forceChecked={otherProps.isChecked} className={styles.customRadio} />
					<input
						type="radio"
						name={otherProps.name}
						className={styles.input}
						onChange={otherProps.onChange}
						value={otherProps.value}
						checked={otherProps.isChecked}
					/>
					<div className={styles.title}>{otherProps.option.label}</div>
				</label>
			)
		case 'button':
			return (
				<button
					type="button"
					onClick={otherProps.setOpen}
					className={clsx(
						styles.wrapper,
						otherProps.error && styles.is_error,
						styles.is_checked,
						styles.is_collapsibleButton,
					)}
				>
					{otherProps.checkedInputLabel ? (
						<>
							<RadioMark forceChecked={true} className={styles.customRadio} />
							<div className={clsx(styles.title, styles.withChevron)}>{otherProps.checkedInputLabel}</div>
						</>
					) : (
						<div className={styles.placeholder}>{otherProps.placeholder}</div>
					)}
					<span className={clsx(styles.chevron, otherProps.open && styles.is_open)} />
				</button>
			)
	}
}
