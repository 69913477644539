/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { dump, ValueFilter, ValueFilterReplacer } from '../utils/dump'
import s from './Dump.module.sass'

const negate = (val: boolean) => !val

export type DumpProps = {
	data?: any
	max?: boolean
	valueFilter?: ValueFilter
	maxHeight?: string | number
}

export const Dump = React.memo(function Dump({ data, max, valueFilter, maxHeight }: DumpProps) {
	const [maximized, setMaximized] = React.useState(max ?? false)

	const handleClick = React.useCallback(() => {
		setMaximized(negate)
	}, [])

	const replacements: React.ReactNode[] = []
	let i = 0
	const replacer: ValueFilterReplacer = value => {
		i++
		const rep = `#rep(${i})`

		replacements.push(value)

		return rep
	}

	const dumped = dump(data, valueFilter, replacer)

	const replaced = dumped.split(/"#rep\(\d+\)"/gim).flatMap((s, i) => {
		return [s, replacements[i] ?? null]
	})

	const content = (
		<pre className={s.Pre}>
			<span>{replaced}</span>
			<span className={s.Corner}>
				<button type="button" className={s.MaximizeButton} onClick={handleClick}>
					{maximized ? '-' : '+'}
				</button>
			</span>
		</pre>
	)

	return (
		<>
			<div
				style={typeof maxHeight !== 'undefined' ? { '--Dump-maxHeight': maxHeight } : {}}
				className={[s.Dump, maximized && s.faded].join(' ')}
			>
				{content}
			</div>
			{maximized && <div className={[s.Dump, s.maximized].join(' ')}>{content}</div>}
		</>
	)
})
