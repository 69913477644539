import clsx from 'clsx'
import { FunctionComponent, useCallback, useMemo, useRef } from 'react'
import { useMoreThanClick } from 'use-more-than-click'
import styles from './ArmoredButton.module.sass'
import { Button, ButtonProps } from './Button'

export type ArmoredButtonProps = {
	onConfirm: () => void
} & Pick<
	ButtonProps & {
		type: 'button'
	},
	'children' | 'variant' | 'round' | 'startIcon' | 'endIcon' | 'bold' | 'fullWidth' | 'uppercase' | 'disabled' | 'size'
>

export const ArmoredButton: FunctionComponent<ArmoredButtonProps> = ({
	onConfirm,
	children,
	variant,
	round,
	startIcon,
	endIcon,
	bold,
	fullWidth,
	uppercase,
	disabled,
	size,
}) => {
	const commonProps = useMemo(
		() => ({ children, variant, round, startIcon, endIcon, bold, fullWidth, uppercase, disabled, size }),
		[children, variant, round, startIcon, endIcon, bold, fullWidth, uppercase, disabled, size],
	)
	const ref = useRef<HTMLButtonElement>(null)
	const callback = useCallback(() => {
		onConfirm()
	}, [onConfirm])

	const { progress } = useMoreThanClick(ref, callback)

	return (
		<div
			className={clsx(
				styles.wrapper,
				commonProps.fullWidth && styles.is_fullWidth,
				progress === 1 && styles.is_pressurized,
			)}
		>
			<Button {...commonProps} ref={ref} type="button" outline={progress} />
		</div>
	)
}
