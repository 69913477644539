import type { FunctionComponent } from 'react'
import { Breaklines, Note, shapeNotesEmpty } from '..'

export interface MultilineShapedNotesProps {
	notes: ReturnType<typeof shapeNotesEmpty>
}

export const MultilineShapedNotes: FunctionComponent<MultilineShapedNotesProps> = ({ notes }) => {
	return (
		<>
			{notes.map(({ title, text }, i) => (
				<Note key={i} title={title}>
					<Breaklines element="br" text={text} />
				</Note>
			))}
		</>
	)
}
