import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import styles from './Squircle.module.sass'

export type SquircleProps = {
	className?: string
	children: React.ReactNode
}

export const Squircle: FunctionComponent<SquircleProps> = ({ children, className }) => {
	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.content, className)}>{children}</div>
		</div>
	)
}
