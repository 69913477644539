import clsx from 'clsx'
import { FunctionComponent, useMemo } from 'react'
import { useFilterSelect } from '../misc/useFilterSelect'
import { SearchQueryCondition } from './FilterSelect'
import styles from './FilterSelectInMobile.module.sass'
import { FilterSelectInMobileButton } from './FilterSelectInMobileButton'
import { FilterSelectOptions } from './FilterSelectOptions'
import { FilterSelectPanel } from './FilterSelectPanel'

export type FilterSelectInMobileProps = {
	searchQueryProps: SearchQueryCondition
	filterSelect: ReturnType<typeof useFilterSelect>
	onClose: () => void
}

export const FilterSelectInMobile: FunctionComponent<FilterSelectInMobileProps> = ({
	searchQueryProps,
	filterSelect,
	onClose,
}) => {
	const { resetFilter, groups, value, setActiveSectionKey, activeSection, isChecked, toggleItem } = filterSelect

	const isVisible = useMemo(() => {
		const valueLength = Object.values(value).flatMap(section => section).length
		return valueLength > 0
	}, [value])

	return (
		<div>
			{activeSection ? (
				<FilterSelectOptions
					isChecked={isChecked}
					activeSection={activeSection}
					setActiveSectionKey={setActiveSectionKey}
					toggleItem={toggleItem}
					showBackButton
					button={<Button onClose={onClose} isVisible={isVisible} />}
					{...searchQueryProps}
				/>
			) : (
				<FilterSelectPanel
					onResetClick={resetFilter}
					groups={groups}
					value={value}
					activeSectionKey={null}
					setActiveSectionKey={setActiveSectionKey}
					button={<Button onClose={onClose} isVisible={isVisible} />}
				/>
			)}
		</div>
	)
}

const Button: FunctionComponent<{ onClose: () => void; isVisible: boolean }> = ({ onClose, isVisible }) => {
	return (
		<div className={clsx(styles.button, isVisible && styles.is_visible)}>
			<FilterSelectInMobileButton onClick={onClose} />
		</div>
	)
}
