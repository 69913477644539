import { Authorization, AuthorizationProps } from '../auth'
import type { GoodlokNextPage } from './GoodlokNextPage'

export function renderGoodlokNextPage<P extends { children?: React.ReactNode } = Record<string, unknown>, IP = P>(
	Component: GoodlokNextPage<P, IP>,
	props: P,
	authorization?: Omit<AuthorizationProps, 'children'>,
) {
	const auth = Component.authorization ?? authorization
	const wrapper = (children: React.ReactNode) =>
		auth && !auth.unauthenticated ? (
			<Authorization {...auth} {...Component.authorization}>
				{children}
			</Authorization>
		) : (
			children
		)

	if (Component.render) {
		return wrapper(Component.render(Component, props))
	}
	if (Component.Layout) {
		const { Layout } = Component
		return wrapper(
			<Layout pageProps={props}>
				<Component {...props} />
			</Layout>,
		)
	}
	if (Component.wrapper) {
		return wrapper(Component.wrapper(<Component {...props} />, props))
	}
	return wrapper(<Component {...props} />)
}
