import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import ReactModal from 'react-modal'
import { Backdrop } from './Backdrop'
import style from './Modal.module.sass'

export type ModalProps = Partial<ReactModal.Props> & {
	isOpen: boolean
	title?: ReactNode
	children: ReactNode
	desktopAlign?: 'center' | 'bottom' | 'right'
	mobileAlign?: 'center' | 'bottom' | 'top'
	bordered?: boolean
	noPadding?: boolean
	transparent?: boolean
}

export const Modal: FunctionComponent<ModalProps> = props => {
	const desktopAlign = props.desktopAlign ?? 'center'
	const mobileAlign = props.mobileAlign ?? 'center'

	return (
		<ReactModal
			overlayElement={(props, contentElement) => (
				<div {...props}>
					<Backdrop />
					{contentElement}
				</div>
			)}
			isOpen={props.isOpen}
			ariaHideApp={false}
			overlayClassName={clsx(
				style.overlay,
				style[`view_align_desktop_${desktopAlign}`],
				style[`view_align_mobile_${mobileAlign}`],
				{
					[style.view_noPadding]: props.noPadding,
					[style.view_bordered]: props.bordered,
					[style.view_transparent]: props.transparent,
				},
			)}
			className={style.content}
			onRequestClose={props.onRequestClose}
			closeTimeoutMS={300}
		>
			{props.title}
			{props.children}
		</ReactModal>
	)
}
