import React, { useState } from 'react'
import style from './Tabs.module.sass'

export type TabsProps = {
	items: Array<{
		label: string
		content: React.ReactNode
	}>
}

export const Tabs: React.FunctionComponent<TabsProps> = ({ items }) => {
	const [activeItemIndex, setActiveItemIndex] = useState(0)

	return (
		<div className={style.wrapper}>
			<TabsHeader items={items} activeItemIndex={activeItemIndex} setActiveItemIndex={setActiveItemIndex} />
			<TabsContent items={items} activeItemIndex={activeItemIndex} setActiveItemIndex={setActiveItemIndex} />
		</div>
	)
}

export const TabsHeader: React.FunctionComponent<
	TabsProps & { activeItemIndex: number; setActiveItemIndex: (index: number) => void }
> = ({ items, activeItemIndex, setActiveItemIndex }) => {
	return (
		<div className={style.header}>
			{items.map((item, i) => (
				<button
					type="button"
					key={i}
					className={`${style.header_item} ${i === activeItemIndex ? style.is_active : ''}`}
					onClick={() => setActiveItemIndex(i)}
				>
					<div className={style.header_content}>{item.label}</div>
				</button>
			))}
		</div>
	)
}

export const TabsContent: React.FunctionComponent<
	TabsProps & { activeItemIndex: number; setActiveItemIndex: (index: number) => void }
> = ({ items, activeItemIndex, setActiveItemIndex }) => {
	return (
		<>
			{items.map((item, i) => (
				<details
					key={i}
					className={style.content}
					open={i === activeItemIndex}
					onToggle={event => {
						const { target } = event
						if (!(target instanceof HTMLDetailsElement)) {
							return
						}
						event.preventDefault()
						if (target.open) {
							setActiveItemIndex(i)
						}
					}}
				>
					<summary className={style.content_summary}>{item.label}</summary>
					{item.content}
				</details>
			))}
		</>
	)
}
