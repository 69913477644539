import { Input } from '@goodlok/ui'
import type { FunctionComponent } from 'react'

export type SearchBarProps = {
	label?: string
	onChange: (value: string) => void
	value?: string
}

export const SearchBar: FunctionComponent<SearchBarProps> = ({ label, onChange, value }) => {
	return (
		<Input
			size="compact"
			type="text"
			label={label}
			labelPosition="inside"
			onChange={event => {
				onChange(event.currentTarget.value)
			}}
			value={value}
		/>
	)
}
