import type { FunctionComponent } from 'react'
import styles from './AddButton.module.sass'

export type AddButtonProps = {
	onClick: () => void
}

export const AddButton: FunctionComponent<AddButtonProps> = ({ onClick }) => {
	return (
		<button onClick={onClick} className={styles.button} type="button">
			<div className={styles.cross} />
		</button>
	)
}
