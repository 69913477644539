import clsx from 'clsx'
import { Fragment, FunctionComponent, useMemo, useState } from 'react'
import { ImageResult } from '../fragments/ImageFragment'
import { BoxTitle } from './BoxTitle'
import styles from './PeopleListEdit.module.sass'
import { PeopleListStaffPhoto } from './PeopleListStaffPhoto'
import { ScrollBox } from './ScrollBox'
import { SearchBar } from './SearchBar'

export type PeopleList = {
	id: string
	person: { name: string; photo?: ImageResult }
}

export type PeopleListEditProps = {
	title?: string
	peopleList?: PeopleList[]
	searchPlaceholder?: string
	button?: React.ReactNode
	name: string
	selectedId?: string
	setSelectedId: (id: string) => void
}

export const PeopleListEdit: FunctionComponent<PeopleListEditProps> = ({
	title,
	peopleList,
	searchPlaceholder,
	button,
	name,
	selectedId,
	setSelectedId,
}) => {
	const [searchValue, setSearchValue] = useState<string | undefined>(undefined)

	const filteredPeopleList = useMemo(() => {
		if (searchValue) {
			return peopleList?.filter(peopleListItem =>
				peopleListItem.person.name.toLowerCase().includes(searchValue.toLowerCase()),
			)
		}
		return peopleList
	}, [searchValue, peopleList])

	return (
		<div className={styles.wrapper}>
			{title && <BoxTitle title={title} button={button} />}
			<div className={styles.searchBar}>
				<SearchBar label={searchPlaceholder} onChange={setSearchValue} value={searchValue} />
			</div>
			<ScrollBox>
				<div className={styles.staffList}>
					{filteredPeopleList?.map((item, index) => {
						const isSelected = selectedId === item.id
						return index < 50 ? (
							<Fragment key={item.id}>
								<label className={clsx(styles.staff, isSelected && styles.is_selected)}>
									<input
										type="radio"
										className={styles.input}
										onChange={() => setSelectedId(item.id)}
										name={name}
										value={item.id}
									/>
									<div className={styles.staffPhoto}>
										<PeopleListStaffPhoto photo={item.person.photo ?? null} isSelected={isSelected} />
									</div>
									{item.person.name}
								</label>
							</Fragment>
						) : null
					})}
				</div>
			</ScrollBox>
		</div>
	)
}
