import React from 'react'
import style from './ErrorMessage.module.sass'

export type ErrorMessageProps = {
	error?: React.ReactNode | React.ReactNode[] | undefined
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({ error }) => {
	if (error) {
		let message = <p>{error}</p>
		if (Array.isArray(error)) {
			message = (
				<ul>
					{error.map((e, i) => (
						<li key={i}>{e}</li>
					))}
				</ul>
			)
		}
		return <div className={style.wrapper}>{message}</div>
	}
	return null
}

ErrorMessage.displayName = 'ErrorMessage'
