import { FunctionComponent } from 'react'
import { Dialog } from 'react-aria-components'
import { useFilterSelect } from '../misc/useFilterSelect'
import { SearchQueryCondition } from './FilterSelect'
import styles from './FilterSelectInDesktop.module.sass'
import { FilterSelectOptions } from './FilterSelectOptions'
import { FilterSelectPanel } from './FilterSelectPanel'

export type FilterSelectInDesktopProps = {
	searchQueryProps: SearchQueryCondition
	filterSelect: ReturnType<typeof useFilterSelect>
}

export const FilterSelectInDesktop: FunctionComponent<FilterSelectInDesktopProps> = ({
	searchQueryProps,
	filterSelect,
}) => {
	const { resetFilter, groups, value, setActiveSectionKey, activeSection, isChecked, toggleItem } = filterSelect
	return (
		<Dialog className={styles.groups}>
			<FilterSelectPanel
				onResetClick={resetFilter}
				groups={groups}
				value={value}
				activeSectionKey={activeSection?.key ?? null}
				setActiveSectionKey={setActiveSectionKey}
			/>
			<FilterSelectOptions
				activeSection={activeSection}
				setActiveSectionKey={setActiveSectionKey}
				toggleItem={toggleItem}
				isChecked={isChecked}
				{...searchQueryProps}
			/>
		</Dialog>
	)
}
