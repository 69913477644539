import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'

export function useIsDebug() {
	const debug = typeof useRouter().query.debug !== 'undefined'

	const query = useQuery(['useIsDebug', `debug:${debug}`], async () => {
		return debug
	})

	return query.data
}
