import { notify, useLocalLoading } from '@goodlok/ui'
import { useEffect, useRef } from 'react'
import { GoodlokAuthStatus } from './GoodlokAuth'
import { useGoodlokAuth } from './hooks'

let hasAttemptedPerSession = false

export const useAttemptSignInWithCredentialManager = (
	onSuccessfulSignIn?: (customer: { id: string }) => void,
	onlyOncePerSession = true,
) => {
	const hasAttempted = useRef(false)
	const [isLoading, setIsLoading] = useLocalLoading()
	const {
		actions: { signInWithCredentialManager },
		status,
	} = useGoodlokAuth()

	useEffect(() => {
		if (status === GoodlokAuthStatus.Loading) {
			return
		}
		if (hasAttempted.current) {
			return
		}
		if (hasAttemptedPerSession && onlyOncePerSession) {
			return
		}
		hasAttempted.current = true
		hasAttemptedPerSession = true
		if (status === GoodlokAuthStatus.SignedIn) {
			return
		}
		setIsLoading(true)
		signInWithCredentialManager().then(response => {
			setIsLoading(false)
			if (response === null) {
				return
			}
			if (response.signIn.ok && response.signIn.customerId) {
				onSuccessfulSignIn?.({
					id: response.signIn.customerId,
				})
				return
			}
			notify('Automatické přihlášení se nezdařilo.', 'error')
		})
	}, [onSuccessfulSignIn, onlyOncePerSession, setIsLoading, signInWithCredentialManager, status])

	return {
		isLoading,
	}
}
