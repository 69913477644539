import type { FunctionComponent, ReactNode } from 'react'
import styles from './Warning.module.sass'

export type WarningProps = {
	text: string
	actions?: ReactNode
}

export const Warning: FunctionComponent<WarningProps> = ({ text, actions }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.note}>
				<p>⚠️ {text}</p>
			</div>
			<div className={styles.actions}>{actions}</div>
		</div>
	)
}
