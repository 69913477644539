import { useMedia } from 'react-use'
import { FilterGroup, desktopBreakpoint } from '../components'
import { FilterSelectParams, useFilterSelect } from './useFilterSelect'

export const useResponsiveFilterSelect = <Groups extends FilterGroup[]>(parameters: {
	groups: Groups
	options?: Omit<NonNullable<FilterSelectParams<Groups>['options']>, 'isAutoselectOn'>
}) => {
	const { groups, options } = parameters
	const isDesktop = useMedia(desktopBreakpoint)

	const filterSelect = useFilterSelect({ groups, options: { isAutoselectOn: isDesktop, ...options } })

	return filterSelect
}
