import { AppDownloadButtons } from './AppDownloadButtons'
import styles from './SuccessCard.module.sass'
import { SuccessCard } from './SuccessCard'

export type OrderSuccessCardProps = { email: string }

export function OrderSuccessCard(props: OrderSuccessCardProps) {
	return (
		<SuccessCard
			title={<h1>Zvládli jste nakoupit Goodlok produkty. Blahopřejeme!</h1>}
			notes={
				<p>
					Chcete sledovat stav objednávky? A příště nakupovat pohodlněji? Stáhněte si mobilní appku a zaregistrujte se
					pod e-mailem <strong>{props.email}</strong>.
				</p>
			}
			actions={
				<div className={styles.downloadApps}>
					<AppDownloadButtons />
				</div>
			}
		/>
	)
}
