import { FragmentResult, ValueTypes, ZeusSelect } from '@goodlok/sdk/generated/content_role_public'

export function TranslationValueFragment() {
	return ZeusSelect<ValueTypes['TranslationValue']>()({
		key: [{}, { identifier: true }],
		value: true,
	})
}

export type TranslationValueResult = FragmentResult<'TranslationValue', typeof TranslationValueFragment>
