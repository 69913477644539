import clsx from 'clsx'
import { FunctionComponent, ReactNode, useState } from 'react'
import styles from './CollapsibleGroupsList.module.sass'

export type CollapsibleGroupsListProps = {
	groups?: Array<{ head: ReactNode; body?: ReactNode }>
	onOpen?: (index: number | null) => void
}

export const CollapsibleGroupsList: FunctionComponent<CollapsibleGroupsListProps> = ({ groups, onOpen }) => {
	const [openGroupIndex, setOpenGroupIndex] = useState<number | null>(null)
	return (
		<div className={styles.wrapper}>
			{groups?.map(
				(group, index) =>
					group.head && (
						<CollapsibleGroup
							key={index}
							open={index === openGroupIndex}
							toggleOpen={() => {
								setOpenGroupIndex(index === openGroupIndex ? null : index)
								onOpen?.(index === openGroupIndex ? null : index)
							}}
							head={group.head}
							body={group.body}
						/>
					),
			)}
		</div>
	)
}

const CollapsibleGroup: FunctionComponent<
	NonNullable<CollapsibleGroupsListProps['groups']>[number] & { open: boolean; toggleOpen: () => void }
> = ({ open, toggleOpen, head, body }) => {
	const canBeOpened = Boolean(body)
	const isOpen = canBeOpened && open

	return (
		<div className={clsx(styles.item, isOpen && styles.is_open, !canBeOpened && styles.is_unopenable)}>
			<div className={styles.head}>
				<button type="button" className={styles.headOpener} onClick={toggleOpen} aria-label="toggle" />
				{head}
			</div>
			<div className={styles.body}>
				<div className={styles.body_in}>{body}</div>
			</div>
		</div>
	)
}
