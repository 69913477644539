import { useEffect, useState } from 'react'

export function useDebouncedValue<Value>(value: Value, ms = 300) {
	const [debounced, setDebounced] = useState(value)

	useEffect(() => {
		const t = setTimeout(() => {
			setDebounced(value)
		}, ms)

		return () => {
			clearTimeout(t)
		}
	}, [ms, value])

	return debounced
}
