import type { FunctionComponent } from 'react'
import { BoxTitle } from './BoxTitle'
import { CommonSingleChoiceSelectProps } from './SingleChoiceSelectEdit'
import styles from './SingleChoiceSelectIn.module.sass'
import { SingleChoiceSelectOption } from './SingleChoiceSelectOption'

export type SingleChoiceSelectInProps = CommonSingleChoiceSelectProps & {
	rightTopSlot?: React.ReactNode
	setIsEdited: () => void
	checkedInputLabel: string | undefined
	name: string
	error?: boolean
}

export const singleChoiceSelectCompactViewCountLimit = 5

export const SingleChoiceSelectIn: FunctionComponent<SingleChoiceSelectInProps> = ({
	title,
	description,
	options,
	placeholder,
	topRightSlot,
	value,
	checkedInputLabel,
	setIsEdited,
	onChange,
	name,
	uncheckable = true,
	error,
}) => {
	return (
		<div className={styles.wrapper}>
			{title && <BoxTitle title={title} description={description} button={topRightSlot} />}
			{options.length <= singleChoiceSelectCompactViewCountLimit ? (
				<div className={styles.limited_options}>
					{options.map(option => {
						const isChecked = value === option.id
						return (
							<SingleChoiceSelectOption
								type="radio"
								key={option.id}
								isChecked={isChecked}
								option={option}
								uncheckable={uncheckable}
								onChange={() => (isChecked ? onChange?.(null) : onChange?.(option.id))}
								value={option.id}
								name={name}
								error={error}
							/>
						)
					})}
				</div>
			) : (
				<div className={styles.optionsWrapper}>
					<SingleChoiceSelectOption
						type="button"
						placeholder={placeholder}
						checkedInputLabel={checkedInputLabel}
						setOpen={setIsEdited}
						error={error}
					/>
				</div>
			)}
		</div>
	)
}
