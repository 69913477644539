const getWorkspaceName = () => {
	const workspaceName = process.env.WORKSPACE_NAME || process.env.NEXT_PUBLIC_WORKSPACE_NAME
	if (workspaceName === 'goodlok') {
		return 'goodlok'
	}
	if (workspaceName === 'doubleshot') {
		return 'doubleshot'
	}
	if (workspaceName === 'sliceslicebaby') {
		return 'sliceslicebaby'
	}
	if (workspaceName === 'kilomayo') {
		return 'kilomayo'
	}
	return 'goodlok' // @TODO: remove this line when master .env.local is implemented
	throw new Error('Unknown project name ' + workspaceName)
}

export type WorkspaceName = ReturnType<typeof getWorkspaceName>

export const workspaceName = getWorkspaceName()
