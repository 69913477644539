import clsx from 'clsx'
import React from 'react'
import { Input, InputProps } from './Input'
import { RadioMark } from './RadioMark'
import styles from './Switch.module.sass'

export type SwitchProps = {
	name: string
	options: Array<{
		value: string
		label: React.ReactNode
	}>
	selectedValue?: string | null
	onChange?: (value: string | null) => void
	onRequestToAddNew?: () => void
	customValueInput?: {
		labelBefore?: string
		labelAfter?: string
		type?: InputProps['type']
	}
	normalCasing?: boolean
	disabled?: boolean
}

export const Switch: React.FunctionComponent<SwitchProps> = ({
	name,
	options,
	onChange,
	selectedValue,
	onRequestToAddNew,
	normalCasing = false,
	disabled = false,
	...otherProps
}) => {
	const [localSelectedValue, setLocalSelectedValue] = React.useState(selectedValue ?? null)

	React.useEffect(() => {
		if (localSelectedValue !== selectedValue && selectedValue !== undefined) {
			setLocalSelectedValue(selectedValue)
		}
	}, [selectedValue, localSelectedValue])

	const customValueInput = React.useMemo(
		() =>
			otherProps.customValueInput && {
				...otherProps.customValueInput,
				type: otherProps.customValueInput?.type ?? 'text',
			},
		[otherProps.customValueInput],
	)

	const handleLocalChange = React.useCallback(
		(value: string | null) => {
			setLocalSelectedValue(value)
			onChange?.(value)
		},
		[onChange],
	)

	const [isCustomValueInputFocused, setIsCustomValueInputFocused] = React.useState(false)

	const isCustomValueInputChecked = React.useMemo(
		() =>
			localSelectedValue !== null &&
			(isCustomValueInputFocused || options.every(option => option.value !== localSelectedValue)),
		[isCustomValueInputFocused, localSelectedValue, options],
	)

	return (
		<ul className={clsx(styles.wrapper, normalCasing && styles.view_normalCasing)}>
			{options.map(option => (
				<li key={option.value} className={styles.item}>
					<label className={styles.label}>
						<input
							type="radio"
							name={name}
							className={styles.input}
							value={option.value}
							disabled={disabled}
							checked={localSelectedValue === option.value && isCustomValueInputFocused === false}
							onChange={event => {
								if (event.target.checked) {
									handleLocalChange(option.value)
								}
							}}
						/>
						<div className={styles.content}>
							<RadioMark />
							<span className={styles.text}>{option.label}</span>
						</div>
					</label>
				</li>
			))}
			{customValueInput && (
				<li className={clsx(styles.item, isCustomValueInputChecked && styles.view_forceChecked)}>
					<label className={styles.label}>
						<div className={styles.content}>
							{/* @TODO: add input with name and value if custom specified */}
							<RadioMark forceChecked={isCustomValueInputChecked} />
							<div className={styles.customInput}>
								{customValueInput.labelBefore && <span className={styles.text}>{customValueInput.labelBefore}</span>}
								<Input
									type={customValueInput.type}
									size="compact"
									onFocus={event => {
										setIsCustomValueInputFocused(true)
										handleLocalChange(event.currentTarget.value)
									}}
									onBlur={() => {
										setIsCustomValueInputFocused(false)
									}}
									onChange={event => {
										handleLocalChange(event.currentTarget.value)
									}}
								/>
								{customValueInput.labelAfter && <span className={styles.text}>{customValueInput.labelAfter}</span>}
							</div>
						</div>
					</label>
				</li>
			)}
			{onRequestToAddNew && (
				<li className={styles.item}>
					<button type="button" onClick={() => onRequestToAddNew()} className={styles.otherAction}>
						<div className={clsx(styles.content, styles.is_narrow)}>
							<div className={styles.plus} />
						</div>
					</button>
				</li>
			)}
		</ul>
	)
}
