import type { FunctionComponent, PropsWithChildren } from 'react'
import { Icon } from '../icons/Icon'
import styles from './AddNewButton.module.sass'
import { Button } from './Button'

export type AddNewButtonProps = {
	onClick: () => void
}

export const AddNewButton: FunctionComponent<PropsWithChildren<AddNewButtonProps>> = ({ onClick, children }) => {
	return (
		<div className={styles.wrapper}>
			<Button
				type="button"
				variant="seamless"
				startIcon={<Icon name="plus" />}
				uppercase={false}
				disableGutters
				onClick={onClick}
			>
				{children}
			</Button>
		</div>
	)
}
