import type { FunctionComponent } from 'react'
import { Icon } from '../icons/Icon'
import styles from './DateTime.module.sass'

const dateIntl = new Intl.DateTimeFormat('cs-CZ', {
	day: 'numeric',
	month: 'numeric',
	year: 'numeric',
})
const timeIntl = new Intl.DateTimeFormat('cs-CZ', {
	timeStyle: 'short',
})

export type DateTimeProps = {
	date: string | Date
}

export const DateTime: FunctionComponent<DateTimeProps> = ({ date }) => {
	const input = new Date(date)

	const formattedDate = dateIntl.format(new Date(input))
	const formattedTime = timeIntl.format(new Date(input))

	return (
		<div className={styles.wrapper}>
			{formattedDate}{' '}
			<div className={styles.icon}>
				<Icon name="clock" />
			</div>{' '}
			{formattedTime}
		</div>
	)
}
