import type { NextApiRequest, NextApiResponse } from 'next'

export const apiHealthHandler = (request: NextApiRequest, response: NextApiResponse) => {
	response.status(200).json({
		status: 'ok',
		time: new Date().toISOString(),
		commit: process.env.VERCEL_GIT_COMMIT_SHA ?? 'unknown',
		stage: process.env.NEXT_PUBLIC_STAGE ?? 'unknown',
	})
}
