import { useRouter } from 'next/router'
import { FunctionComponent, PropsWithChildren, createContext, useContext, useMemo } from 'react'

/* @TODO find a better name for this component. Something like UrlBackedDateRange? */
/* @TODO currently supports only moth and year. Add day if needed */

export type MonthAndYear = {
	month: number
	year: number
}

const defaultDate = {
	year: new Date().getUTCFullYear(),
	month: new Date().getUTCMonth(),
}

const activeDateContext = createContext<{
	activeDate: MonthAndYear
	setActiveDate: (date: MonthAndYear) => void
	resetActiveDate: () => void
}>({
	activeDate: {
		month: new Date().getUTCMonth(),
		year: new Date().getUTCFullYear(),
	},
	setActiveDate: () => {
		return
	},
	resetActiveDate: () => {
		return
	},
})

export const ActiveDateProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
	const { query, replace } = useRouter()

	const date = useMemo(() => {
		if (typeof query.date !== 'string') {
			return defaultDate
		}
		const dateParts = query.date.split('-')
		const year = parseInt(dateParts[0])
		const month = parseInt(dateParts[1]) - 1

		if (isNaN(year) || isNaN(month) || month < 0 || month > 11) {
			return defaultDate
		}

		return {
			year: parseInt(dateParts[0]),
			month: parseInt(dateParts[1]) - 1,
		}
	}, [query.date])

	const value = useMemo(
		() => ({
			activeDate: date,
			setActiveDate: (date: MonthAndYear) => {
				replace({ query: { date: `${date.year}-${(date.month + 1).toString().padStart(2, '0')}` } })
			},
			resetActiveDate: () => {
				replace({ query: {} })
			},
		}),
		[date, replace],
	)

	return <activeDateContext.Provider value={value}>{children}</activeDateContext.Provider>
}

export const useActiveDate = () => useContext(activeDateContext)
