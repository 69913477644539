import type { FunctionComponent } from 'react'
import { useMemo, useState } from 'react'
import { ImageResult } from '../fragments/ImageFragment'
import { BoxTitle } from './BoxTitle'
import { Checkbox } from './Checkbox'
import { ScrollBox } from './ScrollBox'
import { SearchBar } from './SearchBar'
import styles from './TagSelectEdit.module.sass'

export type TagSelectEditProps = {
	title?: string
	button?: React.ReactNode
	items: Array<{ id: string; title: string }>
	name: string
	onChange: (items: string[]) => void
	value: string[]
	image?: ImageResult
	showSearchBar?: boolean
	searchBarPlaceholder?: string
}

export const TagSelectEdit: FunctionComponent<TagSelectEditProps> = ({
	title,
	button,
	items,
	name,
	onChange,
	value,
	showSearchBar = true,
	searchBarPlaceholder,
}) => {
	const [searchValue, setSearchValue] = useState<string | undefined>(undefined)

	const filteredTagList = useMemo(() => {
		if (searchValue) {
			return items.filter(tag => tag.title.toLowerCase().includes(searchValue.toLowerCase()))
		}
		return items
	}, [searchValue, items])

	return (
		<div className={styles.wrapper}>
			{title && <BoxTitle title={title} button={button} />}
			{showSearchBar && (
				<div className={styles.searchBar}>
					<SearchBar label={searchBarPlaceholder} onChange={setSearchValue} value={searchValue} />
				</div>
			)}
			<ScrollBox>
				<div className={styles.tagList}>
					{filteredTagList.map(tag => {
						return (
							<TagSelectItem
								label={tag.title}
								checkboxName={name}
								key={tag.id}
								value={tag.id}
								setActiveTagIds={onChange}
								activeTagIds={value}
							/>
						)
					})}
				</div>
			</ScrollBox>
		</div>
	)
}

export const TagSelectItem: FunctionComponent<{
	label: string
	value: string
	checkboxName: string
	setActiveTagIds: (tags: string[]) => void
	activeTagIds: string[]
}> = ({ label, checkboxName, value, setActiveTagIds, activeTagIds }) => {
	return (
		<Checkbox
			label={label}
			withOutline
			onChange={checked => {
				if (checked) {
					setActiveTagIds([...activeTagIds, value])
				} else {
					setActiveTagIds(activeTagIds.filter(tag => tag !== value))
				}
			}}
			name={checkboxName}
			checkedValue={value}
			checked={activeTagIds.includes(value)}
		/>
	)
}
