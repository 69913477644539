import { assertNever } from '@goodlok/ui'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import type { OnSuccessAuthAction } from '../../src'
import { Logo } from '../components/Logo'
import { OfflineIndicator } from '../components/OfflineIndicator'
import style from './GoodlokAuthForm.module.sass'
import { AlreadySignedIn } from './components/AlreadySignedIn'
import { ForgottenPassword } from './components/ForgottenPassword'
import { SignIn } from './components/SignIn'
import { SignInOrSignUp } from './components/SignInOrSignUp'
import { SignUp } from './components/SignUp'
import { useGoodlokAuth } from './hooks'

export enum GoodlokAuthFormView {
	SignInOrSignUp = 'SignInOrSignUp',
	SignIn = 'SignIn',
	SignUp = 'SignUp',
	ForgottenPassword = 'ForgottenPassword',
	AlreadySignedIn = 'AlreadySignedIn',
}

export type GoodlokAuthFormProps = {
	successUrl?: string
	variant?: 'default' | 'webview'
	minimal?: boolean
	initialView?: GoodlokAuthFormView
	onSuccess?: (customer: { id: string }) => void
	onSuccessAction?: OnSuccessAuthAction
}

export function GoodlokAuthForm({
	successUrl,
	variant,
	minimal,
	initialView,
	onSuccess,
	onSuccessAction,
}: GoodlokAuthFormProps) {
	const [view, setView] = useState(initialView ?? GoodlokAuthFormView.SignInOrSignUp)
	const [username, setUsername] = useState<string>('')
	const [protectedActionPublicKey, setprotectedActionPublicKey] = useState<string | undefined>(undefined)

	const goToSignInOrSignUp = useCallback(() => {
		setView(GoodlokAuthFormView.SignInOrSignUp)
	}, [])
	const goToSignIn = useCallback(() => {
		setView(GoodlokAuthFormView.SignIn)
	}, [])
	const goToSignUp = useCallback(() => {
		setView(GoodlokAuthFormView.SignUp)
	}, [])

	const auth = useGoodlokAuth()

	const resetPassword = useMutation(
		async () => {
			if (username) {
				return auth.zeus
					.auth('mutation')({
						initResetPassword: [{ email: username }, { ok: true, publicKey: true }],
					})
					.then(data => data.initResetPassword.publicKey)
			}
		},
		{
			onSuccess(publicKey) {
				if (publicKey) {
					setprotectedActionPublicKey(publicKey)
					setView(GoodlokAuthFormView.ForgottenPassword)
				}
			},
		},
	)

	const goToForgottenPassword = resetPassword.mutateAsync

	return (
		<GoodlokAuthFormWrapper minimal={minimal} variant={variant}>
			<OfflineIndicator />
			{view === GoodlokAuthFormView.SignInOrSignUp ? (
				<SignInOrSignUp
					goToSignIn={goToSignIn}
					goToSignUp={goToSignUp}
					username={username}
					setUsername={setUsername}
					successUrl={successUrl}
					onSuccess={onSuccess}
					onSuccessAction={onSuccessAction}
				/>
			) : view === GoodlokAuthFormView.SignIn ? (
				<SignIn
					goToSignInOrSignUp={goToSignInOrSignUp}
					goToForgottenPassword={goToForgottenPassword}
					isResetPasswordLoading={resetPassword.isLoading}
					username={username}
					onSuccess={onSuccess}
				/>
			) : view === GoodlokAuthFormView.SignUp ? (
				<SignUp goToSignInOrSignUp={goToSignInOrSignUp} username={username} onSuccess={onSuccess} />
			) : view === GoodlokAuthFormView.ForgottenPassword ? (
				<ForgottenPassword
					username={username}
					protectedActionPublicKey={protectedActionPublicKey}
					onSuccess={password => {
						auth.actions.signIn({ username, password }).then(data => {
							if (data.signIn.customerId) {
								onSuccess?.({ id: data.signIn.customerId })
							}
						})
					}}
				/>
			) : view === GoodlokAuthFormView.AlreadySignedIn ? (
				<AlreadySignedIn goToSignInOrSignUp={goToSignInOrSignUp} username={username} />
			) : (
				assertNever(view)
			)}
			{/* <button type="button" onClick={() => setView(GoodlokAuthFormView.SignInOrSignUp)}>
				Přihlášení/Registrace
			</button>
			<button type="button" onClick={() => setView(GoodlokAuthFormView.ForgottenPassword)}>
				Zapomenuté heslo
			</button>
			<button type="button" onClick={() => setView(GoodlokAuthFormView.SignIn)}>
				Přihlášení
			</button>
			<button type="button" onClick={() => setView(GoodlokAuthFormView.SignUp)}>
				Registrace
			</button>
			<button type="button" onClick={() => setView(GoodlokAuthFormView.AlreadySignedIn)}>
				Již přihlášen
			</button> */}
		</GoodlokAuthFormWrapper>
	)
}

export type GoodlokAuthFormWrapperProps = {
	children: React.ReactNode
	variant?: 'default' | 'webview'
	minimal?: boolean
}

export function GoodlokAuthFormWrapper({ minimal, variant, children }: GoodlokAuthFormWrapperProps) {
	return (
		<div className={clsx(style.wrapper, style[`view_${variant}`])}>
			{!minimal && (
				<div className={style.logo}>
					<Logo variant="large" />
				</div>
			)}
			{children}
		</div>
	)
}
