import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import { FunctionComponent, useEffect, useState } from 'react'
import { Icon } from '../icons/Icon'
import styles from './CollapsibleCard.module.sass'

export type CollapsibleCardProps = {
	title?: React.ReactNode
	label?: React.ReactNode
	children?: React.ReactNode
	headerAdditionalInfo?: React.ReactChild
	isOpen?: boolean
	setIsOpen?: (value: boolean) => void
}

export const CollapsibleCard: FunctionComponent<CollapsibleCardProps> = ({
	title,
	label,
	children,
	headerAdditionalInfo,
	isOpen: isOpenFromProps = true,
	setIsOpen: setIsOpenFromProps,
}) => {
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		setIsOpen(isOpenFromProps)
	}, [isOpenFromProps])

	return (
		<div className={styles.wrapper}>
			<button
				className={styles.header}
				type="button"
				onClick={() => {
					setIsOpen(!isOpen)
					if (setIsOpenFromProps) {
						setIsOpenFromProps(!isOpen)
					}
				}}
			>
				<h3 className={styles.title}>{title}</h3>
				{label && <div className={styles.headerLabel}>{label}</div>}
				<div className={styles.right}>
					{headerAdditionalInfo}
					{children && (
						<div className={styles.toggle}>
							<div className={clsx(styles.toggleIcon, isOpen ? styles.is_open : styles.is_closed)}>
								<Icon name="arrowDown" />
							</div>
						</div>
					)}
				</div>
			</button>
			{children && (
				<Collapsible open={isOpen}>
					<div className={styles.content}>{children}</div>
				</Collapsible>
			)}
		</div>
	)
}
