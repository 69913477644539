import type { GoodlokUserRole } from '@goodlok/meta'
import { FunctionComponent, useEffect, useState } from 'react'
import { GoodlokAuthContext, GoodlokAuthForm, GoodlokAuthStatus, useGoodlokAuth } from '.'
import { Button } from '../components'

export type AuthorizationRules = {
	role?: GoodlokUserRole | GoodlokUserRole[]
	rule?: (auth: GoodlokAuthContext) => boolean
}

export type AuthorizationProps = {
	wrapper?: (children: React.ReactNode) => React.ReactNode
	children: React.ReactNode
	skip?: boolean
	loading?: React.ReactNode
	unauthenticated?: React.ReactNode
	unauthorized?: React.ReactNode
} & AuthorizationRules

export function useCheckAuthorization(rules: AuthorizationRules) {
	const g = useGoodlokAuth()

	if (typeof window === 'undefined') {
		return false
	}

	if (rules.rule) {
		const result = rules.rule(g)
		if (!result) {
			console.log('Authorization failed', 'rule', rules.rule, g.userId)
			return false
		}
	}

	if (rules.role) {
		const roles = Array.isArray(rules.role) ? rules.role : [rules.role]
		const result = roles.some(role => g.hasTenantRole(role))
		if (!result) {
			console.log('Authorization failed', 'role', rules.role, g.userId)
			return false
		}
	}

	return true
}

export const DefaultWhenLoading: FunctionComponent = () => {
	return <div></div>
}

export const DefaultWhenUnauthorized: FunctionComponent = () => {
	const g = useGoodlokAuth()
	return (
		<div>
			<p>
				<strong title={Array.isArray(g.roles) ? g.roles.join(', ') : ''}>{g.email}</strong> sem nemá přístup{' '}
				<Button
					type="button"
					variant="dark"
					round
					outline
					uppercase={false}
					onClick={() => {
						g.actions.signOut()
					}}
				>
					Odhlásit se
				</Button>
			</p>
		</div>
	)
}

export const DefaultWhenUnauthenticated: FunctionComponent = () => {
	const [successUrl, setSuccessUrl] = useState<null | string>(null)

	useEffect(() => {
		setSuccessUrl(String(window.location.href))
	}, [])

	return (
		<div>
			<GoodlokAuthForm successUrl={successUrl ?? undefined} />
		</div>
	)
}

const defaultWrapper: AuthorizationProps['wrapper'] = children => <>{children}</>

export const Authorization: FunctionComponent<AuthorizationProps> = props => {
	const { children, skip, wrapper, ...rules } = props

	const usedWrapper = wrapper ?? defaultWrapper

	const g = useGoodlokAuth()
	const check = useCheckAuthorization(rules)

	if (!skip && g.status === GoodlokAuthStatus.Loading) {
		return <>{usedWrapper(<>{props.loading ?? <DefaultWhenLoading />}</>)}</>
	}

	if (!skip && g.status === GoodlokAuthStatus.SignedOut) {
		return <>{usedWrapper(<>{props.unauthenticated ?? <DefaultWhenUnauthenticated />}</>)}</>
	}

	if (!skip && !check) {
		return <>{usedWrapper(<>{props.unauthorized ?? <DefaultWhenUnauthorized />}</>)}</>
	}

	return <>{children}</>
}
